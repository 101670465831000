import { ReactChild, RefObject } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { IContainerConfig } from '../model/configuration';
import dayjs from 'dayjs';
import {
  faBriefcase,
  faBuilding,
  faFile,
  faFileAudio,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faHospitalAlt,
  faMicroscope,
  faUserSecret
} from '@fortawesome/pro-light-svg-icons';
import { faMask } from '@fortawesome/pro-solid-svg-icons';
import { IImage } from '../model/data/Detail';
import { IUserProfilePlayer } from '../model/profile/player';
import { ThingTypes } from '../model/ryve/Thing';
import { IListItem } from '../model/list/ListItem';
import { TimeAva, Ava, IconAva } from '@curry-group/mui-curcuma';
import { BaseListItem } from '../components/list-items/default';
import { IListUser } from '../model/search/SearchResult';

export const MTOLogo = {
  logoWithTextSrc: '/images/landingpage/logo-mit-schriftzug.svg',
  logoAlt: 'Logo von Medtec Online',
  logoDarkSrc: '/images/logo_dark.svg',
  logoMobileSrc: '/images/logo_dark.svg',
  partnerLogoSrc: '/images/bmbf/logo.svg',
  partnerLogoMobileSrc: '/images/bmbf/logo_small.svg',
  partnerLogoAlt: 'Logo des Bundesministerium für Bildung und Forschung'
};

/**
 * Generates a fontawesome react component
 * @param icon fontawesome icon syntax
 * @returns fontawesome icon component
 */
export const fai = (icon: IconProp) => {
  return <FontAwesomeIcon icon={icon} />;
};

export const copyValueToClipboard = (value: string) => {
  const tempInput = document.createElement('input');
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand('copy');
  document.body.removeChild(tempInput);
};

export const getVisibleIndexFromChilds = (ref: RefObject<HTMLElement>) => {
  if (!ref.current) return [0, 0];
  const parentRect = ref.current.getBoundingClientRect();
  const childs = ref.current.children;
  let cumulatedWidths = 0;
  for (let i = 0; i < childs.length; i++) {
    const child = childs[i];
    const childRect = child.getBoundingClientRect();
    const relativePosition = parentRect.x - childRect.x;
    if (relativePosition < childRect.width / 2) return [i, Math.min(cumulatedWidths, ref.current.scrollWidth - parentRect.width)];
    cumulatedWidths += childRect.width;
  }
  return [0, 0];
};

export function resolveTimestamp(
  unix?: number | string,
  showDay?: boolean,
  hideTime?: boolean,
  prepositions?: boolean,
  startLowerCase?: boolean,
  prefixed?: boolean
) {
  if (typeof unix === 'undefined' || unix === null) {
    return '';
  }
  const date = dayjs(unix);
  const now = dayjs();
  const time = (prepositions? 'um ': '') + date.format('HH:mm');
  if (date.startOf('d').unix() === now.startOf('d').unix()) {
    return (startLowerCase ? 'heute ' : 'Heute  ') + (hideTime ? '' : time);
  } else if (date.startOf('d').unix() === now.add(-1, 'd').startOf('d').unix()) {
    return (startLowerCase ? 'gestern ' : 'Gestern  ') + (hideTime ? '' : time);
  } else if (date.startOf('d').unix() === now.add(1, 'd').startOf('d').unix()) {
    return (startLowerCase ? 'morgen ' : 'Morgen  ') + (hideTime ? '' : time);
  } else {
    return (prepositions ? `${prefixed ? 'dem' : 'am'} `: '') + (showDay ? intToDay(date.day()) + ' ' : '') + date.format('DD.MM.YYYY') + ' ' + (hideTime ? '' : time);
  }
}

export function resolveTimestampDateOnly(unix?: number) {
  if (typeof unix === 'undefined' || unix === null) {
    return '';
  }
  const date = dayjs(unix);
  return date.format('DD.MM.YYYY');
}

export function compareTimeStampGreaterOrSameDay(timestamp: number, timestampCompare: number) {
  const dateTs = new Date(timestamp);
  const dateTsCompare = new Date(timestampCompare);
  return (
    timestamp > timestampCompare ||
    (dateTs.getDate() === dateTsCompare.getDate() && dateTs.getMonth() === dateTsCompare.getMonth() && dateTs.getFullYear() === dateTsCompare.getFullYear())
  );
}

function intToDay(int: number, short?: boolean) {
  const daysShort = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA'];
  const daysLong = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
  if (short) return daysShort[int];
  else return daysLong[int];
}

export function resolveInterval(timestampFrom?: number, timestampTo?: number) {
  if (typeof timestampFrom === 'undefined' || timestampFrom === null) {
    return '';
  }
  const d = new Date(timestampFrom * 1000);
  const dTo = new Date((timestampTo || 0) * 1000);

  const day = intToDay(d.getDay(), true);

  const date = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  const dateFull = (date < 10 ? '0' + date : date) + '.' + (month < 10 ? '0' + month : month) + '.' + year;

  const from = {
    hour: d.getHours(),
    min: d.getMinutes()
  };

  const to = {
    hour: dTo.getHours(),
    min: dTo.getMinutes()
  };

  const timeFrom = (from.hour < 10 ? '0' + from.hour : from.hour) + ':' + (from.min < 10 ? '0' + from.min : from.min);
  const timeTo = (to.hour < 10 ? '0' + to.hour : to.hour) + ':' + (to.min < 10 ? '0' + to.min : to.min);

  const timeFull = timeFrom + (timeTo ? '-' + timeTo : '') + ' Uhr';

  return day + ' ' + dateFull + ' ' + timeFull;
}

export const getDetailRoute = (
  item: { to?: string | false; typeId: string; alias?: string; _id?: string; parentItem?: { to?: string | false; typeId: string; alias?: string }, __original?: any },
  appconfig: { [alias: string]: IContainerConfig },
  location?: { pathname: string; search: string }
) => {
  if (item.to) { return item.to; }
  if (item.to === false) { return undefined; }
  if (item.typeId === ThingTypes.Profile) {
    return `/userbyid/${item._id + (location ? '?ref=' + location?.pathname : '')}`;
  }
  let groupRoute = '';
  let parentRoute = '';
  let itemRoute = '';
  let directLinkArg = '';
  const messages = item.__original?.content?.messagesResolved;
  if (!!messages && messages.length > 0) {
    const messagesSorted = messages.map(m => ({ threadRoot: m.threadRoot, id: m.id, content: m.content, timestamp: m.createdAt }));
    messagesSorted.sort((a, b) => b.timestamp - a.timestamp);
    const newestMessage = messagesSorted[0];
    const threadRoot = newestMessage.threadRoot || null;
    if (!!threadRoot) {
      const messageId = newestMessage.id || null;
      directLinkArg = `message=${threadRoot}&threadMessage=${messageId}`
    } else {
      const messageId = newestMessage.id || null;
      const isContentConversation = item.typeId === ThingTypes.Idea
        || item.typeId === ThingTypes.QnA
        || item.typeId === ThingTypes.Requisition
        || item.typeId === ThingTypes.Blog
        || item.typeId === ThingTypes.Wiki
        || item.typeId === ThingTypes.BestPractice;
      directLinkArg = `message=${messageId}${isContentConversation ? `&detailContent=true` : '' }`
    }
  }
  for (let alias in appconfig) {
    const config = appconfig[alias];
    for (let typeAlias in config.types) {
      const mapping = appconfig[alias].types[typeAlias].aliasTypeMapping;
      if (item.parentItem) {
        if (mapping.typeId === item.parentItem.typeId) {
          groupRoute = `/${config.groupAlias}/${alias}`;
          parentRoute = `/${mapping.typeAlias}/${item.parentItem.alias}`;
        }
        if (mapping.typeId === item.typeId) {
          itemRoute = `/${mapping.typeAlias}/${item.alias}`;
        }
        if (groupRoute && parentRoute && itemRoute) {
          return `${groupRoute}${parentRoute}${itemRoute}${getQueryString(location, directLinkArg)}`;
        }
      } else if (mapping.typeId === item.typeId) {
        return `/${config.groupAlias}/${alias}/${mapping.typeAlias}/${item.alias}${getQueryString(location, directLinkArg)}`;
      }
    }
  }
};

export const getQueryString = (location?:{pathname:string, search:string}, addQueryString?:string) => {
  let queryString = location ? `?ref=${location.pathname}` : '';
  if (!!addQueryString) {
    if (!!queryString) {
      queryString = `${queryString}&${addQueryString}`
    } else {
      queryString = `?${addQueryString}`;
    }
  }
  return queryString;
};

export const getProfileRouteByUserId = (userId: string) => {
  return `/userbyid/${userId}`;
};

export function removeDetailUrl(url: string) {
  const result = url.split('/');
  result.pop();
  result.pop();
  return result.join('/');
}

export function removeDashboardUrl(url: string) {
  const result = url.split('/');
  if (result[result.length - 1] === 'dashboard') {
    result.pop();
  }
  return result.join('/');
}

export function isGatedContentRoute(url: string) {
  if (
    url.startsWith('/info/forum/idea') ||
    url.startsWith('/info/forum/qna') ||
    url.startsWith('/info/forum/requisition') ||
    url.startsWith('/info/magazine/wiki') ||
    url.startsWith('/info/magazine/blog') ||
    url.startsWith('/info/magazine/bestpractice') ||
    url.startsWith('/info/magazine/collection') ||
    url.startsWith('/coop/groups-projects/groups') ||
    url.startsWith('/coop/groups-projects/projects')
  ) {
    var sections = url.split('/');
    if (sections.length > 5) {
      return false;
    }
    return true;
  } else {
    return false;
  }
}

export function isGatedContentType(type?: string) {
  if (
    type === ThingTypes.Idea ||
    type === ThingTypes.QnA ||
    type === ThingTypes.Requisition ||
    type === ThingTypes.Wiki ||
    type === ThingTypes.Blog ||
    type === ThingTypes.BestPractice ||
    type === ThingTypes.Collection ||
    type === ThingTypes.Group ||
    type === ThingTypes.Project
  )
    return true;
  else return false;
}

export const getScrollLeftByIndex = (ref: RefObject<HTMLElement>, idx: number) => {
  if (!ref.current) return 0;
  const parentRect = ref.current.getBoundingClientRect();
  const childs = ref.current.children;
  let cumulatedWidths = 0;
  for (let i = 0; i < idx; i++) {
    const child = childs[i];
    const childRect = child.getBoundingClientRect();
    cumulatedWidths += childRect.width;
  }
  return Math.min(cumulatedWidths, ref.current.scrollWidth - parentRect.width);
};

/**
 * Truncates a given string after a given amount of characters. Avoids truncating in the middle of a word.
 * @param str
 * @param maxLength
 */
export const shorten = (str: any, maxLength: number) => {
  if (!str) return undefined;
  let trimmedString = str.substr(0, maxLength);
  let ellipsis = str.length > maxLength;
  if (str.length > trimmedString.length) {
    trimmedString = str.substr(0, maxLength);
    trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')));
  }

  return ellipsis ? trimmedString + '...' : trimmedString;
};

export const baseImageUrl = () => {
  return localStorage.BASE_URL ? localStorage.BASE_URL : '/services/';
};

export const baseNodeUrl = () => {
  return localStorage.nodeUrl ? localStorage.nodeUrl : '';
};

export const getQueryParam = (key: string, url: string) => {
  const query = new URLSearchParams(url);
  return query.get(key);
};
export const setQueryParam = (key: string, value: any, url: string) => {
  const query = new URLSearchParams(url);
  query.set(key, value);
  return decodeURIComponent(query.toString());
};
export const setQueryStringWithoutPageReload = (value: string) => {
  const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + value;
  window.history.pushState({ path: newurl }, '', newurl);
};

export const stripHtml = (str: string) => {
  return str.replace(/(<([^>]+)>)/gi, '');
};
export const stripParagraph = (str: string) => {
  if (str.startsWith('<p>') && str.endsWith('</p>')) {
    str = str.substr(3).slice(0, -4);
  }
  return str;
};
export const addParagraph = (str: string) => {
  return '<p>' + str + '</p>';
};

export const sumProperties = (obj: any) => {
  let sumProp = 0;
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      sumProp += obj[prop];
    }
  }
  return sumProp;
};

export const isNullOrWhitespace = (str: string | null | undefined) => {
  if (typeof str === 'undefined' || str == null) return true;

  return str.replace(/\s/g, '').length < 1;
};

export const TYPES = {
  BLOG: 'bc624cbe-be13-4fa3-aa99-0b43fa462731',
  NEWS: 'eac2dc7a-1b9b-4f6a-89ca-daad3a2a18a1',
  GLOSSAR: '3ba9955b-dbbb-43a6-99c6-6f770d576d1f',
  IDEA: 'd3f08006-9a61-4ef2-891f-522d92885b96',
  REQUISITION: '45b3e9e4-829f-4c06-b99e-af329f568b09',
  BESTPRACTICE: '26fa1adc-fbcb-4073-b683-6bd9df1b6a33',
  QNA: '3efbd8f0-f7ef-4946-b3ae-22441540653d',
  WIKI: 'dfe6a977-2982-4e47-a023-a477841958a3',
  PROJECT: '98596b5c-4b8b-420d-9f87-4658ac9f063f',
  GROUP: '6933b507-e181-4875-99f7-6d1b46f1a2f9',
  MEETING: '3830919f-405f-4202-8358-b96f82a11940',
  PROFILE: '00000000-0000-0000-0000-000000000001',
  VENTURE_PLAYER: 'ff72bb0a-6701-408a-a535-87d920150eb9',
  SUPPLIER_PLAYER: '4b49dd38-2b07-4e29-8086-ea00f480a7de',
  SCIENTIFIC_PLAYER: '6a09e06f-04cf-4ef6-aa84-533c78da0a86',
  MISC_PLAYER: '6a6b0c0f-38c9-49a7-b008-0a999aefcdaa',
  ACTOR: '0fc077e7-fcd2-40c7-a57b-590e2b01bdb4',
  COLLECTION: '7b2a21f3-6a9e-4af0-8913-298f49e0738d'
  // PLAYER: 'c4af27f1-61b6-4118-bdae-1ed69ffcbbb8'
};

export const PROFILE_TYPES = {
  COMMUNICATION_CHANNELS: {
    MAIL: '856b6c45-eebc-4f61-815a-496908674de2',
    PHONE: 'dd6c6d19-000b-4f90-aebe-d7dde30ec565',
    MOBILE: '6984faa4-2f28-42c0-a3de-7e49c02adb54',
    FAX: '4495230c-81da-4a3d-be81-6a9c99743daa'
  },
  SOCIAL_MEDIA: {
    XING: '28b15afe-f4b0-40df-90a9-b96d01a5f1ee',
    LINKEDIN: 'b1550d46-3cb6-4916-b824-9831d2590cc4'
  }
};

export const DESTINATION_PROPS = {
  impulse: {
    Icon: fai(['fal', 'heart-rate'])
  },
  research: {
    Icon: fai(['fal', 'books'])
  }
};

interface ITypesProps {
  [id: string]: {
    Name: string;
    NameDat?: string;
    NameAkk?: string;
    NamePl: string;
    Article?: {
      zu?: string;
      theNom?: string;
      theDat?: string;
      theAkk?: string;
      yourNom?: string;
      yourDat?: string;
      yourAkk?: string;
    }
    Alt?: {
      Name?: string;
      NameDat?: string;
      NameAkk?: string;
      NamePl?: string;
    };
    Description: string;
    FallbackImage?: IImage;
    FallbackImage2?: IImage;
    OgImage?: IImage;
    OgImage2?: IImage;
    Icon: JSX.Element;
    CardColor: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'accent' | 'success' | 'warning' | 'error';
  };
}

export const DEST_PROPS = {
  news: 'News',
  chats: 'Chats',
  groups: 'Communities',
  forum: 'Forum',
  magazin: 'Magazin',
  experts: 'Personen & Akteure',
}
export const DESTINATIONS_REVERSED = {
  'eac2dc7a-1b9b-4f6a-89ca-daad3a2a18a1': 'news',
  '00000000-0000-0000-0002-000000000000': 'chats',
  '6933b507-e181-4875-99f7-6d1b46f1a2f9': 'groups',
  '98596b5c-4b8b-420d-9f87-4658ac9f063f': 'groups',
  '45b3e9e4-829f-4c06-b99e-af329f568b09': 'forum',
  'd3f08006-9a61-4ef2-891f-522d92885b96': 'forum',
  '3efbd8f0-f7ef-4946-b3ae-22441540653d': 'forum',
  '26fa1adc-fbcb-4073-b683-6bd9df1b6a33': 'magazin',
  'bc624cbe-be13-4fa3-aa99-0b43fa462731': 'magazin',
  'dfe6a977-2982-4e47-a023-a477841958a3': 'magazin',
  '7b2a21f3-6a9e-4af0-8913-298f49e0738d': 'magazin',
  '0fc077e7-fcd2-40c7-a57b-590e2b01bdb4': 'experts',
  '00000000-0000-0000-0000-000000000001': 'experts'
};

export const TYPES_PROPS: ITypesProps = {
  '': {
    Name: 'Fehler',
    NamePl: 'Fehler',
    Description: 'FEHLER Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Blog_AdobeStock_244647617.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Fehler',
      copyright: '©apichon_tee - stock.adobe.com'
    },
    OgImage: {
      src: '/uwao-api/core/asset/System/Social/og_main.png',
      alt: 'Social Bild Fehler',
      copyright: '©apichon_tee - stock.adobe.com'
    },
    Icon: fai(['fal', 'heart-rate']),
    CardColor: 'error'
  },
  'bc624cbe-be13-4fa3-aa99-0b43fa462731': {
    Name: 'Blogbeitrag',
    NamePl: 'Blogbeiträge',
    Article: {
      zu: 'zum',
      theNom: 'der',
      theDat: 'dem',
      theAkk: 'den',
      yourNom: 'Ihr',
      yourDat: 'Ihrem',
      yourAkk: 'Ihren',
    },
    Alt: {
      Name: 'Blog'
    },
    Description: 'BLOG Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Blog_AdobeStock_244647617.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Blogbeitrag',
      copyright: '©apichon_tee - stock.adobe.com'
    },
    OgImage: {
      src: '/uwao-api/core/asset/System/Social/og_blog.png',
      alt: 'Social Blog Bild',
      copyright: '©apichon_tee - stock.adobe.com'
    },
    Icon: fai(['fal', 'heart-rate']),
    CardColor: 'primary'
  },
  'eac2dc7a-1b9b-4f6a-89ca-daad3a2a18a1': {
    Name: 'News',
    NamePl: 'News',
    Alt: {
      Name: 'News'
    },
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Blog_AdobeStock_244647617.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Blogbeitrag',
      copyright: '©apichon_tee - stock.adobe.com'
    },
    Description: 'NEWS Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    Icon: fai(['fal', 'newspaper']),
    CardColor: 'secondary'
  },
  '3ba9955b-dbbb-43a6-99c6-6f770d576d1f': {
    Name: 'Glossareintrag',
    NamePl: 'Glossareinträge',
    Description: 'GLOSSAR Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    Icon: fai(['fal', 'books']),
    CardColor: 'quaternary'
  },
  'd3f08006-9a61-4ef2-891f-522d92885b96': {
    Name: 'Idee',
    NamePl: 'Ideen',
    Article: {
      zu: 'zur',
      theNom: 'die',
      theDat: 'der',
      theAkk: 'die',
      yourNom: 'Ihre',
      yourDat: 'Ihrer',
      yourAkk: 'Ihre',
    },
    Description: 'IDEE Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Idee_AdobeStock_224302832.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Idee',
      copyright: '©sdecoret'
    },
    Icon: fai(['fal', 'heart-rate']),
    CardColor: 'secondary'
  },
  '3efbd8f0-f7ef-4946-b3ae-22441540653d': {
    Name: 'Frage',
    NamePl: 'Fragen',
    Article: {
      zu: 'zur',
      theNom: 'die',
      theDat: 'der',
      theAkk: 'die',
      yourNom: 'Ihre',
      yourDat: 'Ihrer',
      yourAkk: 'Ihre',
    },
    Description: 'FRAGE Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Fragen_AdobeStock_274917005.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Frage',
      copyright: '©Nattakorn - stock.adobe.com'
    },
    Icon: fai(['fal', 'heart-rate']),
    CardColor: 'primary'
  },
  'dfe6a977-2982-4e47-a023-a477841958a3': {
    Name: 'Wikibeitrag',
    NamePl: 'Wikibeiträge',
    Article: {
      zu: 'zum',
      theNom: 'der',
      theDat: 'dem',
      theAkk: 'den',
      yourNom: 'Ihr',
      yourDat: 'Ihrem',
      yourAkk: 'Ihren',
    },
    Alt: {
      Name: 'Wiki'
    },
    Description: 'WIKI Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Wiki_AdobeStock_358453962.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Wikibeitrag',
      copyright: '©zephyr_p - stock.adobe.com'
    },
    OgImage: {
      src: '/uwao-api/core/asset/System/Social/og_wiki.png',
      alt: 'Social Wiki Bild',
      copyright: '©zephyr_p - stock.adobe.com'
    },
    Icon: fai(['fal', 'book-reader']),
    CardColor: 'primary'
  },
  '45b3e9e4-829f-4c06-b99e-af329f568b09': {
    Name: 'Bedarf',
    NamePl: 'Bedarfe',
    Article: {
      zu: 'zum',
      theNom: 'der',
      theDat: 'dem',
      theAkk: 'den',
      yourNom: 'Ihr',
      yourDat: 'Ihrem',
      yourAkk: 'Ihren',
    },
    Description: 'BEDARF Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Bedarf_AdobeStock_415402613.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Bedarf',
      copyright: '©totojang1977 - stock.adobe.com'
    },
    Icon: fai(['fal', 'heart-rate']),
    CardColor: 'quaternary'
  },
  '26fa1adc-fbcb-4073-b683-6bd9df1b6a33': {
    Name: 'Best Practice',
    NamePl: 'Best Practices',
    Article: {
      zu: 'zum',
      theNom: 'das',
      theDat: 'dem',
      theAkk: 'das',
      yourNom: 'Ihr',
      yourDat: 'Ihrem',
      yourAkk: 'Ihr',
    },
    Description: 'BEST PRACTICE Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Best%20Practice_AdobeStock_419192794.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Best Practice',
      copyright: '©Viktor Cap 2015'
    },
    OgImage: {
      src: '/uwao-api/core/asset/System/Social/og_bestpractice.png',
      alt: 'Social Best Practice Bild',
      copyright: '©Viktor Cap 2015'
    },
    Icon: fai(['fal', 'heart-rate']),
    CardColor: 'quaternary'
  },
  'ff72bb0a-6701-408a-a535-87d920150eb9': {
    Name: 'Unternehmen',
    NamePl: 'Unternehmen',
    Description: 'COMP_ACT Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    Icon: fai(['fal', 'building']),
    CardColor: 'primary'
  },
  '4b49dd38-2b07-4e29-8086-ea00f480a7de': {
    Name: 'Leistungserbringer',
    NamePl: 'Leistungserbringer',
    Description: 'HEALTH_ACT Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    Icon: fai(['fal', 'heartbeat']),
    CardColor: 'quaternary'
  },
  '6a09e06f-04cf-4ef6-aa84-533c78da0a86': {
    Name: 'Wissenschaftsakteur',
    NamePl: 'Wissenschaftsakteure',
    Description: 'SCI_ACT Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    Icon: fai(['fal', 'microscope']),
    CardColor: 'secondary'
  },
  '6a6b0c0f-38c9-49a7-b008-0a999aefcdaa': {
    Name: 'Sonstiger Akteur',
    NamePl: 'Sonstige Akteure',
    Description: 'OTHER_ACT Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    Icon: fai(['fal', 'people-carry']),
    CardColor: 'tertiary'
  },
  '7b2a21f3-6a9e-4af0-8913-298f49e0738d': {
    Name: 'Sammlung',
    NamePl: 'Sammlungen',
    Article: {
      zu: 'zur',
      theNom: 'die',
      theDat: 'der',
      theAkk: 'die',
      yourNom: 'Ihre',
      yourDat: 'Ihrer',
      yourAkk: 'Ihre',
    },
    Description: 'SAMMLUNG Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Sammlung_AdobeStock_168614962.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Sammlung',
      copyright: '©Cozine - stock.adobe.com'
    },
    FallbackImage2: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Strategischer%20Dialog_AdobeStock_175955619.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Strategischer Dialog',
      copyright: '©vegefox.com - stock.adobe.com'
    },
    OgImage: {
      src: '/uwao-api/core/asset/System/Social/og_collection.png',
      alt: 'Social Sammlung Bild',
      copyright: '©Cozine - stock.adobe.com'
    },
    OgImage2: {
      src: '/uwao-api/core/asset/System/Social/og_dialogue.png',
      alt: 'Social Strategischer Dialog Bild',
      copyright: '©vegefox.com - stock.adobe.com'
    },
    Icon: fai(['fal', 'people-carry']),
    CardColor: 'primary'
  },
  '6933b507-e181-4875-99f7-6d1b46f1a2f9': {
    Name: 'Arbeitsgruppe',
    NamePl: 'Arbeitsgruppen',
    Article: {
      zu: 'zur',
      theNom: 'die',
      theDat: 'der',
      theAkk: 'die'
    },
    Description: 'ARBEITSGRUPPE Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Arbeitsgruppe_AdobeStock_181392286.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Arbeitsgruppe',
      copyright: '©NDABCREATIVITY - stock.adobe.com'
    },
    OgImage: {
      src: '/uwao-api/core/asset/System/Social/og_group.png',
      alt: 'Social Arbeitsgruppe Bild',
      copyright: '©NDABCREATIVITY - stock.adobe.com'
    },
    Icon: fai(['fal', 'people-carry']),
    CardColor: 'primary'
  },
  '98596b5c-4b8b-420d-9f87-4658ac9f063f': {
    Name: 'Projekt',
    NamePl: 'Projekte',
    Article: {
      zu: 'zum',
      theNom: 'das',
      theDat: 'dem',
      theAkk: 'das'
    },
    Description: 'PROJEKT Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Projekt_AdobeStock_220763150.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Projekt',
      copyright: '©ASDF - stock.adobe.com'
    },
    OgImage: {
      src: '/uwao-api/core/asset/System/Social/og_project.png',
      alt: 'Social Projekt Bild',
      copyright: '©ASDF - stock.adobe.com'
    },
    Icon: fai(['fal', 'people-carry']),
    CardColor: 'secondary'
  },
  '3830919f-405f-4202-8358-b96f82a11940': {
    Name: 'Terminiertes Meeting',
    NameDat: 'Terminierten Meeting',
    NameAkk: 'Terminierte Meeting',
    NamePl: 'Terminierte Meetings',
    Article: {
      zu: 'zum',
      theNom: 'das',
      theDat: 'dem',
      theAkk: 'das'
    },
    Alt: {
      Name: 'Externes Online-Meeting / Präsenz-Meeting',
      NameDat: 'Externen Online-Meeting / Präsenz-Meeting',
      NameAkk: 'Externe Online-Meeting / Präsenz-Meeting',
      NamePl: 'Externe Online-Meetings / Präsenz-Meetings'
    },
    Description: 'MEETING Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Projekt_AdobeStock_220763150.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Projekt',
      copyright: '©ASDF - stock.adobe.com'
    },
    Icon: fai(['fal', 'people-carry']),
    CardColor: 'primary'
  },
  '0fc077e7-fcd2-40c7-a57b-590e2b01bdb4': {
    Name: 'Akteur',
    NamePl: 'Akteure',
    Description: 'ACTOR Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    Icon: fai(['fal', 'people-carry']),
    CardColor: 'primary'
  },
  '00000000-0000-0000-0000-000000000001': {
    Name: 'Benutzer',
    NamePl: 'Benutzer',
    Description: 'USER Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at porttitor sem.',
    FallbackImage: {
      src: '/uwao-api/core/asset/Adobe%20Stock/Person_AdobeStock_90399877.jpeg?tn=1&q=medium&s=background',
      alt: 'Hintergrundbild Benutzer',
      copyright: '©Alex - stock.adobe.com'
    },
    Icon: fai(['fal', 'people-carry']),
    CardColor: 'primary'
  },
  '00000000-0000-0000-0002-000000000000': {
    Name: 'Chat',
    NamePl: 'Chats',
    Description: '',
    Icon: fai(['fal', 'people-carry']),
    CardColor: 'quaternary'
  }
};

export const GetThingTypeName = (type?: any, special?: boolean): string => {
  if (!type || !TYPES_PROPS[type]) return 'Unbekannt';
  if (type === TYPES.COLLECTION) return special ? 'Strategischer Dialog' : 'Sammlung';
  return TYPES_PROPS[type].Name;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const GetConnectedSgPrefix = (type: any) => {
  let sgPrefix = 'verknüpfter';
  switch (type) {
    case TYPES.IDEA:
    case TYPES.QNA:
      sgPrefix = 'verknüpfte';
      break;
    case TYPES.PROJECT:
    case TYPES.NEWS:
    case TYPES.MISC_PLAYER:
    case TYPES.SCIENTIFIC_PLAYER:
    case TYPES.SUPPLIER_PLAYER:
    case TYPES.VENTURE_PLAYER:
      sgPrefix = 'verknüpftes';
      break;
  }
  return sgPrefix;
};

export const GetProfileDisplayName = (userProfile: any): string => {
  if (userProfile) return ((userProfile?.title ?? '') + ' ' + (userProfile?.firstName ?? '') + ' ' + (userProfile?.lastName ?? '')).trim();
  else return 'Ehemaliger Benutzer';
};

export const profilePlayerAsSearchResult = (profilePlayer: IUserProfilePlayer) => {
  return {
    _id: profilePlayer.player,
    _source: {
      content: {
        name: profilePlayer.playerResolved?.name,
        type: profilePlayer.playerResolved?.type,
        _id: profilePlayer.player
      },
      seo: {
        alias: profilePlayer.playerResolved?.alias,
        title: profilePlayer.playerResolved?.name
      }
    }
  };
};

export const GetActorFallbackImage = (type?: string): IImage => {
  if (!type) return { src: '', alt: 'Hintergrundbild' };
  switch (type.toLowerCase()) {
    case 'scientific institution':
      return {
        src: '/uwao-api/core/asset/Adobe%20Stock/Wissenschaft_AdobeStock_108793411.jpeg?tn=1&q=medium&s=background',
        alt: 'Hintergrundbild Wissenschaftsakteur',
        copyright: '©Sergey Nivens - stock.adobe.com'
      };
    case 'company':
      return {
        src: '/uwao-api/core/asset/Adobe%20Stock/Hersteller_AdobeStock_181683033.jpeg?tn=1&q=medium&s=background',
        alt: 'Hintergrundbild Hersteller',
        copyright: '©okrasiuk - stock.adobe.com'
      };
    case 'health institution':
      return {
        src: '/uwao-api/core/asset/Adobe%20Stock/Leistungserbringer_AdobeStock_298001238.jpeg?tn=1&q=medium&s=background',
        alt: 'Hintergrundbild Leistungserbringer',
        copyright: '©ipopba - stock.adobe.com'
      };
    case 'other organization':
      return {
        src: '/uwao-api/core/asset/Adobe%20Stock/Sonstige_AdobeStock_296563182.jpeg?tn=1&q=medium&s=background',
        alt: 'Hintergrundbild Sonstiger Akteur',
        copyright: '©Wasan - stock.adobe.com'
      };
    default:
      return { src: '', alt: 'Hintergrundbild' };
  }
};

export const GetActorType = (type?: string) => {
  if (!type) return '';
  // actor types and subtypes label
  switch (type.toLowerCase()) {
    // type company:
    case 'company':
      return 'Unternehmen';
    case 'manufacturer':
      return 'Hersteller';
    case 'supplier':
      return 'Zulieferer';
    case 'service provider':
      return 'Dienstleister';
    case 'investor':
      return 'Investor';
    case 'distributor':
      return 'Händler';
    case 'authorised representative':
      return 'Bevollmächtigter';
    case 'assembler':
      return 'Assemblierer';
    case 'importer':
      return 'Importeur';
    case 'other':
      return 'Sonstiges Unternehmen';
    
      // type health institution:
    case 'health institution':
      return 'Leistungserbringer';
    // subtype hospital:
    case 'hospital':
      return 'Krankenhaus/ Klinik';
    case 'hospital location':
      return 'Krankenhausstandort';
    case 'hospital department':
      return 'Fachabteilung';
    case 'hospital facility':
      return 'Sonstige Klinikeinrichtung';
    // subtype other stat. care provider:
    case 'other stationary care provider':
      return 'Sonstiger Stationärer Versorger';
    case 'rehabilitation center':
      return 'Rehabilitationszentrum';
    case 'spa clinic':
      return 'Kurklinik';
    case 'nursing home':
      return 'Pflegeheim';
    case 'hospice':
      return 'Hospiz';
    case 'short term care provider':
      return 'Kurzzeitpflege';
    case 'day care provider':
      return 'Tagespflege';
    case 'night care provider':
      return 'Nachtpflege';
    case 'unspecified stationary health institution':
      return 'Sonstiger stationärer Versorger';
    // subtype medical ambulatory care provider:
    case 'medical ambulatory care provider':
      return 'Ambulante Medizinische Einrichtung';
    case 'general medical practice':
      return 'Allgemeinärztliche Praxis';
    case 'specialist medical practice':
      return 'Fachärztliche Praxis';
    case 'medical care center':
      return 'Medizinisches Versorgungszentrum';
    case 'dental practice':
      return 'Zahnärztliche Praxis';
    case 'dental care center':
      return 'Zahnmedizinisches Versorgungszentrum';
    case 'ambulatory rehabilitation center':
      return 'Ambulantes Rehabilitationszentrum';
    case 'psychiatric ambulatory departments':
      return 'Psychiatrische Institutsambulanzen';
    case 'ambulatory clinic':
      return 'Klinikambulanz';
    case 'dialysis care center':
      return 'Dialysezentrum';
    case 'medical laboratory':
      return 'Medizinisches Labor';
    case 'unspecified ambulatory medical institution':
      return 'Sonstige ambulante medizinische Einrichtung';
    // subtype other ambulatory care provider
    case 'other ambulatory care provider':
      return 'Sonstiger Ambulanter Versorger';
    case 'emergency rescue service':
      return 'Psychotherapeutische Praxis';
    case 'psychotherapeutic practice':
      return 'Rettungsdienst';
    case 'physical therapy practice':
      return 'Physiotherapeutische Praxis';
    case 'nursing service':
      return 'Pflegedienst';
    case 'unspecified ambulatory health institution':
      return 'Sonstiger ambulanter Versorger';

    // type scientific institution
    case 'scientific institution':
      return 'Wissenschaftsakteur';
    // subtype university
    case 'university':
      return 'Hochschule';
    case 'university department': // deprecated
      return 'Department';
    case 'university institute': // deprecated
      return 'Department';
    case 'university facility':
      return 'Department';
    // subtype public research center
    case 'public research center':
      return 'Forschungseinrichtung';
    case 'public research center department':
      return 'Department';
    case 'public research center institute':
      return 'Department';
    // subtype non-public research facility
    case 'non-public research facility':
      return 'Forschungseinrichtung';
    case 'non-public research institute':
      return 'Department';

    // type other organization
    case 'other organization':
      return 'sonstiger Akteur';
    case 'ministry':
      return 'Ministerium';
    case 'authority':
      return 'Behörde';
    case 'funding agency':
      return 'Projektträger';
    case 'body of self-governance':
      return 'Organ / Institut der Selbstverwaltung';
    case 'health insurance provider':
      return 'Krankenversicherung';
    case 'medical association':
      return 'Ärztekammer / Med. Fachgesellschaft / Med. Arbeitsgemeinschaft';
    case 'non-medical association':
      return 'Sonstige Fachgesellschaft / Arbeitsgemeinschaft / Akademie';
    case 'professional interest group':
      return 'Verband / Interessengemeinschaft';
    case 'professional network':
      return 'Verein / Netzwerk / Cluster';
    case 'foundation':
      return 'Stiftung';
    case 'chambers':
      return 'Kanzlei';
    case 'unspecified other organization':
      return 'Sonstige';
    default:
      return type;
  }
};
export const GetActorRoute = (type: string) => {
  switch (type) {
    case 'company':
      return 'venture';
    case 'health institution':
      return 'supplier';
    case 'scientific institution':
      return 'scientific';
    case 'other organization':
      return 'misc';
    default:
      return 'unknown';
  }
};

export const GetActorColor = (type: string) => {
  switch (type) {
    case 'company':
      return 'primary';
    case 'health institution':
      return 'quaternary';
    case 'scientific institution':
      return 'secondary';
    case 'other organization':
      return 'tertiary';
    default:
      return 'primary';
  }
};

export const GetActorIcon = (type?: string) => {
  switch (type) {
    case 'company':
      return faBuilding;
    case 'health institution':
      return faHospitalAlt;
    case 'scientific institution':
      return faMicroscope;
    case 'other organization':
      return faBriefcase;
    default:
      return faBuilding;
  }
};

export const GetActorTypeString = (typeIn?: string) => {
  switch (typeIn) {
    case 'scientific institution':
      return 'Wissenschaft';
    case 'company':
      return 'Unternehmen';
    case 'health institution':
      return 'Leistungserbringer';
    case 'other organization':
      return 'Sonstige';
    default:
      return '';
  }
};

export const GetAuthorDisplayName = (authorInfo: any): string =>
  (authorInfo?.profile ? GetProfileDisplayName(authorInfo?.profile) : authorInfo?.authorInfoAuthor);

export const GenerateRandomString = (length: number) => {
  var result = '';
  var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const GetCookie = (name: string) => {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
};

export const DeleteCookie = (name: string) => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export type CategoryNode = { _id: string; parentId: string; name: string; children: Array<CategoryNode> };

//export const CreateCategoryHierarchy = (flatCategoryList: { _id: string, name: string }): Array<CategoryNode> => {
export const createCategoryHierarchy = (flatCategoryList: any, order?: string): Array<CategoryNode> => {
  try {
    if (!flatCategoryList || !Array.isArray(flatCategoryList)) return [];
    var allCategories: any = {};
    var sortProcedure = order === 'custom' ? (a: any, b: any) => (a?.order ?? 0) - (b?.order ?? 0) : (a: any, b: any) => (a?.name ?? '').localeCompare(b?.name ?? '');
    flatCategoryList.forEach((i: any) => {
      const { parentCategory, ...elementWithoutParentCategory } = i;
      allCategories[i._id] = { ...elementWithoutParentCategory, children: [] };
    });
    flatCategoryList.forEach(i => {
      if (allCategories[i.parentCategory]) allCategories[i.parentCategory].children.push(allCategories[i._id]);
    });
    flatCategoryList.forEach(i => allCategories[i._id].children.sort(sortProcedure));
    var topLevelCategories = flatCategoryList.filter(i => !allCategories[i.parentCategory]);
    var mappedCategories = topLevelCategories.map(i => allCategories[i._id]);
    mappedCategories.sort(sortProcedure);
    return mappedCategories;
  } catch (e) {
    return [];
  }
};

export const flattenTreeItems = (obj: Array<CategoryNode>) => {
  if (!obj) return;
  let result: any = [];
  obj.forEach(el => {
    result.push(el);
    if (el.children) {
      let res = flattenTreeItems(el.children);
      if (res) {
        result.push(res);
        result = [].concat.apply([], result);
      }
    }
  });
  return result;
};

export const flattenTreeItemsToIds = (obj: Array<CategoryNode>) => {
  if (!obj) return;
  let result: string[] = [];
  obj.forEach(el => {
    result.push(el._id);
    if (el.children) {
      let res = flattenTreeItemsToIds(el.children);
      if (res) {
        result.concat(res);
        // result = [].concat.apply([], result);
      }
    }
  });
  return result;
};

export const filterTreeById = (array: Array<CategoryNode>, id: string, includeParentNode: boolean): Array<CategoryNode> => {
  const getNode = (node: CategoryNode): Array<CategoryNode> | any => {
    let temp;
    return node._id === id ? (includeParentNode ? [node] : node.children) : (node.children || []).some(node => (temp = getNode(node))) && temp;
  };
  const root: CategoryNode = { _id: 'ROOT', parentId: '', name: 'ROOT', children: array };
  return getNode(root);
};

export const updateArraySelection = (arrayValues: string[], newValue: string) => {
  if (!arrayValues) {
    arrayValues = [];
  }
  if (arrayValues.includes(newValue)) {
    let index = arrayValues.indexOf(newValue);
    let newSelection = [...arrayValues];
    newSelection.splice(index, 1);
    return newSelection;
  } else {
    return [...arrayValues, newValue];
  }
};

export const updateArrayObjectSelection = (compare: string, arrayValues: any[], newValue: any) => {
  if (!compare) {
    return arrayValues;
  }
  if ((arrayValues || []).includes(newValue)) {
    let index = arrayValues.indexOf(newValue);
    let newSelection = [...arrayValues];
    newSelection.splice(index, 1);
    return newSelection;
  } else {
    return [...arrayValues, newValue];
  }
};

export const initials = (argv: any) => {
  let retInitials = '';
  let args = argv;
  if (argv && argv.length > 2) {
    args = argv.slice(argv.length - 2);
  }
  for (const part of args) {
    if (!part || typeof part !== 'string') {
      continue;
    }
    retInitials += part?.charAt(0);
  }
  return retInitials;
};

export const initialsAuthorInfo = (authorInfo: any) => {
  if (authorInfo.profile) {
    return initials([authorInfo.profile?.firstName, authorInfo.profile?.lastName]);
  } else {
    return initials(authorInfo?.authorInfoAuthor.split(' '));
  }
};

export const CategoryItemsLength = (items: any[], children: string): number => {
  let counter = 0;
  items.forEach(item => {
    if (!item[children] || item[children].length === 0) counter += 1;
    else counter += CategoryItemsLength(item[children], children) + 1;
  });
  return counter;
};

const linkRegExpressions = {
  wiki: /<a href="wiki(%3A|:)alias(%3A|:)[^>]+">[^<]+<\/a>/gim,
  blog: /<a href="blog(%3A|:)alias(%3A|:)[^>]+">[^<]+<\/a>/gim,
  normative: /<a href="normative(%3A|:)id(%3A|:)[^>]+">[^<]+<\/a>/gim,
  link: /<a href="link(%3A|:)id(%3A|:)[^>]+">([^<]+)<\/a>/gim,
  source: /<a href="sourceref(%3A|:)id(%3A|:)[^>]+">[^<]+<\/a>/gim,
  image: /<a href="image(%3A|:)id(%3A|:)[^>]+">[^<]+<\/a>/gim,
  img: /<img[^>]+>/gim,
  attachment: /<a href="attachment(%3A|:)id(%3A|:)[^>]+">((\S|\s))+<\/a>/gim,
  thing: /<a href="#thing![^>]+">[^<]+<\/a>/gim,
  unknownThing: /<a href="unknownThing">[^<]+<\/a>/gim
};

const removeLinkTags = (link: string): string => {
  return link.replace(/<a[^>]+>/gim, '').replace(/<\/a>/gim, '');
};

// const removeImageTags = (link: string): string => {
//   return link.replace(/<img[^>]+>/gim, '');
// };

export const removeLinks = (text: string): string => {
  const result = (text || '')
    .replace(linkRegExpressions.wiki, removeLinkTags)
    .replace(linkRegExpressions.blog, removeLinkTags)
    .replace(linkRegExpressions.image, removeLinkTags)
    // .replace(linkRegExpressions.normative, removeLinkTags)
    // .replace(linkRegExpressions.link, removeLinkTags)
    // .replace(linkRegExpressions.source, removeLinkTags)
    // .replace(linkRegExpressions.attachment, removeLinkTags)
    .replace(linkRegExpressions.unknownThing, removeLinkTags);
  return result;
};

export const convertLinks = (text: string, location: any, appconfig: { [alias: string]: IContainerConfig }): string => {
  let result = (text || '')
    .replace(linkRegExpressions.thing, (text) => {
      const thingLinkSplitted = text.split('!');
      const typeId = thingLinkSplitted[1];
      const alias = thingLinkSplitted[2].split('"')[0];
      const linkPath = getDetailRoute({ typeId: typeId, alias: alias }, appconfig, location);
      return text.replace(/<a[^>]+>/gim, `<a href="${linkPath}">`);
    });
  return result;
};

export const resolveLinks = (text, location: any, appconfig?: { [alias: string]: IContainerConfig }) => {
  return convertLinks(removeLinks(text), location, appconfig || {});
};

export const switchLinkHandler = (anchor: string) => {
  let re = new RegExp('^normative(%3A|:)id(%3A|:)', 'gim');
  let match = re.test(anchor);
  if (match) { return true; }
  re = new RegExp('^link(%3A|:)id(%3A|:)', 'gim');
  match = match || re.test(anchor);
  if (match) { return true; }
  re = new RegExp('^sourceref(%3A|:)id(%3A|:)', 'gim');
  match = match || re.test(anchor);
  if (match) { return true; }
  re = new RegExp('^image(%3A|:)id(%3A|:)', 'gim');
  match = match || re.test(anchor);
  if (match) { return true; }
  re = new RegExp('^attachment(%3A|:)id(%3A|:)', 'gim');
  match = match || re.test(anchor);
  return match;
 }

export const removeHTMLTags = (text: string): string => {
  return text.replace(/<[^>]+>/gim, '');
};

export const removeHTMLTagsSpecial = (text: string): string => {
  return text.replace(/<\/h\d+>/gim, ': ').replace(/<[^>]+>/gim, '');
};

export const cropHTML = (text: string, size: number): string[] => {
  const splitted = (text || '').split('<h');
  if (!splitted?.length && splitted.length === 0) return [text, ''];
  if (splitted.length === 1) return [splitted[0], ''];
  if (splitted.length <= size) return [splitted.join('<h'), ''];
  return [splitted.slice(0, size).join('<h'), '<h' + splitted.slice(size).join('<h')];
};

export const flowSteps: any = {
  forum: [
    { component: 'createTypeSelect', nextVisible: false, headline: '' },
    { component: 'createPublicSelect', nextVisible: false, headline: '' },
    { component: 'createIdeaForm', nextVisible: true, headline: '', type: 'idea' },
    { component: 'createQnAForm', nextVisible: true, headline: '', type: 'qna' },
    { component: 'createRequisitionForm', nextVisible: true, headline: '', type: 'requisition' },
    { component: 'createCategoryForm', nextVisible: true, headline: '', lastStep: true },
    { component: 'finishFlow', nextVisible: false, headline: '' }
  ],
  'groups-projects': [
    { component: 'createGroupTypeSelect', nextVisible: false, headline: '' },
    { component: 'createProjectConfirmTerms', nextVisible: true, headline: '', type: 'project' },
    { component: 'createGroupVisibilitySelect', nextVisible: false, headline: '', type: 'workgroup' },
    { component: 'createProjectVisibilitySelect', nextVisible: false, headline: '', type: 'project' },
    { component: 'createWorkgroupForm', nextVisible: true, headline: '', type: 'workgroup' },
    { component: 'createProjectForm', nextVisible: true, headline: '', type: 'project' },
    { component: 'createWorkgroupCategoryForm', nextVisible: true, headline: '', type: 'workgroup' },
    { component: 'createProjectCategoryForm', nextVisible: true, headline: '', type: 'project' },
    { component: 'createGroupUserSelect', nextVisible: true, headline: '', searchAlias: 'find-experts' },
    { component: 'createWorkgroupInvitationForm', nextVisible: true, headline: '', lastStep: true, buttonText: 'Fertig!', excludeIfOptionNotSet: ['usersInvited'] },
    { component: 'finishFlow', nextVisible: false, headline: '' }
  ],
  'add-participants': [
    { component: 'createGroupUserSelect', nextVisible: true, headline: '', searchAlias: 'find-experts' },
    { component: 'createWorkgroupInvitationForm', nextVisible: true, headline: '', lastStep: true, buttonText: 'Einladen', excludeIfOptionNotSet: ['usersInvited'] },
    { component: 'finishFlow', nextVisible: false, headline: '' }
  ],
  chat: [
    { component: 'createGroupUserSelect', nextVisible: true, headline: '', searchAlias: 'find-chat', singleSelect: true },
    { component: 'createWorkgroupInvitationForm', nextVisible: true, headline: '', lastStep: true, buttonText: 'Einladen' },
    { component: 'finishFlow', nextVisible: false, headline: '' }
  ],
  share: [
    { component: 'shareTypeSelect', nextVisible: false, headline: '' },
    { component: 'shareLocationSelect', nextVisible: false, headline: '', searchAlias: 'mychats', type: 'sharetochat' },
    { component: 'shareMessageForm', nextVisible: true, headline: '', lastStep: true, buttonText: 'Fertig!' },
    { component: 'finishFlow', nextVisible: false, headline: '' }
  ],
  register: [
    { component: 'registerUserPassword', nextVisible: true, headline: '', sidebarImageSrc: 'welcome', sidebarImageAlt: 'Willommen' },
    { component: 'registerUserData', nextVisible: true, headline: '', sidebarImageSrc: 'register', sidebarImageAlt: 'Registrieren' },
    { component: 'registerEmployerIntro', nextVisible: true, headline: '', sidebarImageSrc: 'search', sidebarImageAlt: 'Suchen' },
    {
      component: 'registerEmployerSignatureForm',
      nextVisible: true,
      headline: '',
      excludeIfOptionNotSet: ['createActor'],
      sidebarImageSrc: 'search',
      sidebarImageAlt: 'Arbeitgeber'
    },
    { component: 'registerCompetencesForm', nextVisible: true, headline: '', sidebarImageSrc: 'competences', sidebarImageAlt: 'Kompetenzen' },
    {
      component: 'registerInterestsForm',
      nextVisible: true,
      headline: '',
      lastStep: true,
      buttonText: 'Fertig!',
      sidebarImageSrc: 'interests',
      sidebarImageAlt: 'Interessen'
    },
    { component: 'finishFlow', nextVisible: false, headline: '', sidebarImageSrc: '', sidebarImageAlt: '' }
  ],
  optin: [
    { component: 'optinEmailData', nextVisible: true, headline: '', lastStep: true, buttonText: 'Jetzt registrieren' },
    {
      component: 'finishFlow',
      nextVisible: false,
      headline: '',
      finishTimeout: 6000,
      finishHeadline: 'Registrierungsmail wurde verschickt.',
      finishText: 'Folgen Sie dem Registrierunglink in der Mail.'
    }
  ],
  lostpassword: [
    { component: 'lostPassFormData', nextVisible: true, headline: '', lastStep: true, buttonText: 'Absenden' },
    {
      component: 'finishFlow',
      nextVisible: false,
      headline: '',
      finishTimeout: 3000,
      finishHeadline: 'Passwort ändern Mail wurde verschickt.',
      finishText: 'Folgen Sie dem Link in der Mail.'
    }
  ],
  resetpassword: [
    { component: 'resetPassFormData', nextVisible: true, headline: '', lastStep: true, buttonText: 'Absenden' },
    {
      component: 'finishFlow',
      nextVisible: false,
      headline: '',
      finishTimeout: 3000,
      finishHeadline: 'Sie können sich gleich anmelden.',
      finishText: 'Bitte benutzen Sie dafür ihr neues Passwort.'
    }
  ],
  createmeeting: [
    { component: 'meetingTypeSelect', nextVisible: false, headline: '' },
    { component: 'createMeetingForm', nextVisible: true, headline: '' },
    { component: 'createGroupUserSelect', nextVisible: true, headline: '', searchAlias: 'find-experts' },
    {
      component: 'createWorkgroupInvitationForm',
      nextVisible: true,
      headline: '',
      lastStep: true,
      buttonText: 'Fertig!',
      excludeIfOptionNotSet: ['usersInvited']
    },
    {
      component: 'finishFlow',
      nextVisible: false,
      headline: '',
      finishTimeout: 3000,
      finishHeadline: 'Fertig! Meeting wird erstellt...'
    }
  ],
  flowtermsrules: [
    { component: 'registerUserPassword', nextVisible: true, headline: '', sidebarImageSrc: 'welcome', sidebarImageAlt: 'Willommen' },
    { component: 'registerCompetencesForm', nextVisible: true, headline: '', sidebarImageSrc: 'competences', sidebarImageAlt: 'Kompetenzen' },
    {
      component: 'registerInterestsForm',
      nextVisible: true,
      headline: '',
      lastStep: true,
      buttonText: 'Fertig!',
      sidebarImageSrc: 'interests',
      sidebarImageAlt: 'Interessen'
    },
    {
      component: 'finishFlow',
      nextVisible: false,
      headline: '',
      finishTimeout: 1000,
      finishHeadline: 'Sie werden zur Gruppe weitergeleitet.'
    }
  ],
  optout: [
    { component: 'optoutSubscriptionData', nextVisible: true, headline: '', lastStep: true, buttonText: 'Auswahl speichern' },
    {
      component: 'finishFlow',
      nextVisible: false,
      headline: '',
      finishTimeout: 6000,
      finishHeadline: 'Benachrichtigungseinstellungen wurden angepasst.'
    }
  ],
};

export type IDictionary<T> = { [key: string]: T };

export const GetFormData = (_id: string, data: any, categories: string[], attachments: any[], flowAnonymous: boolean) => {
  const newForm = new FormData();
  let newItem = {
    _id: '',
    content: data,
    categories: (categories || []).map(c => ({ categoryId: c }))
  };
  if (_id && _id !== 'NEW') {
    newItem._id = _id;
  }
  if (flowAnonymous) {
    newItem.content = { ...data, anonymousPublish: true };
  }
  if (attachments && attachments.length > 0) {
    for (let attachment of attachments) {
      newForm.append(attachment.name, attachment);
    }
  }
  newForm.append('entry', JSON.stringify(newItem));
  return newForm;
};

export const GetExistingAttachments = (attachments: any[]) => {
  if (!attachments || attachments.length === 0) {
    return [];
  }
  return attachments.map(att => {
    const newFile = new File([], att.attachmentFile);
    Object.assign(newFile, {
      preview: assetUrl(att.attachmentFile, true),
      filesize: att.attachmentFileSize,
      existing_id: att.attachmentFile,
      realname: att.attachmentTitle
    });
    return newFile;
  });
};

export const wordFileExtensions = ['.doc', '.dot', '.docx', '.dotx'];
export const excelFileExtensions = ['.xls', '.xlsx', '.csv'];
export const presentationFileExtensions = ['.ppt', '.pptx'];

export const msDocFileExtensions = wordFileExtensions.concat(excelFileExtensions).concat(presentationFileExtensions);

export const pdfFileExtensions = ['.pdf'];
export const imageFileExtensions = ['.jpg', '.jpeg', '.jpeg?tn=1&q=medium&s=background', '.png', '.gif', '.svg', '.bmp'];
export const audioFileExtensions = ['.ogg', '.mp3', '.wav'];
export const videoFileExtensions = ['.mov', '.mp4', '.avi', '.mpeg', '.mkv'];

export const allowedChatFileExtensions = msDocFileExtensions.concat(imageFileExtensions).concat(pdfFileExtensions).concat(videoFileExtensions).concat(audioFileExtensions);

export function iconForExtension(ext: string) {
  if (wordFileExtensions.includes(ext)) return faFileWord;
  if (excelFileExtensions.includes(ext)) return faFileExcel;
  if (presentationFileExtensions.includes(ext)) return faFilePowerpoint;
  if (pdfFileExtensions.includes(ext)) return faFilePdf;
  if (audioFileExtensions.includes(ext)) return faFileAudio;
  if (videoFileExtensions.includes(ext)) return faFileVideo;
  return faFile;
}

export function assetUrl(assetId?: string, tn: boolean = false) {
  if (!assetId) {
    return undefined;
  }
  let url = `/uwao-api/core/assetbyid?id=${assetId}`;
  if (tn) {
    url += '&tn=1';
  }
  return url;
}

export function avatarUrl(avatarAssetId?: string, tn: boolean = false) {
  if (!avatarAssetId) {
    return undefined;
  }
  let url = `/uwao-api/mto/profile/avatar/${avatarAssetId}`;
  return url;
}

/**
 *  Pre-order tree traversal visits each node using stack.
 *  Checks if leaf node based on children === null otherwise
 *  pushes all children into stack and continues traversal.
 *  hashMap object literal used for deduping.
 *  @param root - deserialized JSON root to begin traversal
 *  @returns array  - final array of nodes in order with no dups
 */
export function convertTreeToIDList(root: Array<CategoryNode>) {
  try {
    if (!root) {
      return [];
    }
    var stack: Array<CategoryNode> = [...root],
      array: Array<string> = [],
      hashMap = {};
    while (stack.length !== 0) {
      var node = stack.pop();
      if (!node) break;
      if (!node?.children || node?.children.length === 0) {
        visitNode(node, hashMap, array);
      } else {
        visitNode(node, hashMap, array);
        for (var i = node.children.length - 1; i >= 0; i--) {
          stack.push(node.children[i]);
        }
      }
    }
    return array;
  } catch (e) {
    console.log('something wrong with category tree input');
    return [];
  }
}

/**
 *  For each node visit if node not a hashMap key, insert
 *  into array.  Then append node into end of the array.
 *  @params node - object to check
 *  @param hashMap - object literal used for deduping
 *  @param array - final array that nodes are inserted
 */
function visitNode(node: CategoryNode, hashMap: any, array: Array<string>) {
  if (!hashMap[node._id]) {
    hashMap[node._id] = true;
    array.push(node._id);
  }
}

export function filterByNodeName(array, searchVal) {
  const getNodes = nodes => {
    let result: any[] = [];
    for (var node of nodes) {
      let addNode: any = null;
      if (node.name.toLowerCase().includes(searchVal.toLowerCase())) {
        addNode = node;
      }
      if (Array.isArray(node.children)) {
        const nodes = getNodes(node.children);
        if (nodes.length) {
          if (addNode) {
            addNode = { ...addNode, children: nodes };
          } else {
            addNode = { ...node, children: nodes };
          }
        } else {
          if (addNode) {
            addNode = { ...addNode, children: [] };
          }
        }
      }
      if (addNode) {
        result.push(addNode);
      }
    }
    return result;
  };
  return getNodes(array);
}

const requirements = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,}$/;
export function passwordValid(pass: string) {
  if (!pass) return false;
  return requirements.test(pass);
}

export const getReferrer = (url: string) => {
  const query = new URLSearchParams(url);
  return query.get('ref');
};

export const isSubscriptionType = (type?: string) => {
  if (!type) return false;
  return [ThingTypes.Wiki, ThingTypes.Blog, ThingTypes.BestPractice, ThingTypes.Collection, ThingTypes.Requisition, ThingTypes.Idea, ThingTypes.QnA].includes(type);
}

const onlyUniqueFilter = (value, index, self) => {
  return self.indexOf(value) === index;
}

export const getUniqueArray = (a: string[]) => {
  return a.filter(onlyUniqueFilter);
};

export const stripUnit = (val: string | number) : number => {
  if (typeof val === 'number') {
    return val;
  }
  if (typeof val === 'string') {
    val.replace('px', '').replace('%', '').replace('em', '').replace('rem', '');
    return Number(val);
  } else {
    return Number(val);
  }
}

/**
 * Returns a hash code for a string.
 * (Compatible to Java's String.hashCode())
 *
 * The hash code for a string object is computed as
 *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
 * using number arithmetic, where s[i] is the i th character
 * of the given string, n is the length of the string,
 * and ^ indicates exponentiation.
 * (The hash value of the empty string is zero.)
 *
 * @param {string} s a string
 * @return {number} a hash code value for the given string.
 */
export const hashCode = (s: string) : number => {
  var h = 0, l = s.length, i = 0;
  if ( l > 0 )
    while (i < l)
      h = (h << 5) - h + s.charCodeAt(i++) | 0;
  return h;
};

export const ListAvatarColors = [
  { color: '#004A75', bg: '#97C0D8' }, // primary.light #006097
  { color: '#4A7500', bg: '#C0D897' }, // secondary.light rgb(110, 144, 51)
  { color: '#75004A', bg: '#D897C0' },
  // { color: '#755200', bg: '#D8C597' }, // accent.light #E6F5FB
  { color: '#007566', bg: '#97D8D0' }, // warning.light #FFD241
  // { color: '#040075', bg: '#9997D8' }, // success.light #71A100
];

export const renderListItem = (
  item: IListItem,
  index: number,
  appconfig?: IDictionary<IContainerConfig> | null,
  location?: { pathname: string; search: string },
  avatarDict?: IDictionary<IListUser>,
  listType?: string,
  isInverted?: boolean
) => {
  try {
    let avatar: ReactChild = <></>;
    if (item.avatar.type === 'time') {
      avatar = <TimeAva size="large" color={item.color} fromTime={item.avatar.time?.from || ''} toTime={item.avatar.time?.to || ''} />;
    } else if (item.avatar.type === 'image') {
      const hash = hashCode(item?.title?.slice(0,2) ?? '');
      const colorIndex = hash % 4;
      const colorObject = ListAvatarColors[colorIndex];
      const anonymousPublish = item?.__original?.content?.anonymousPublish;
      avatar = <Ava size="large"
        src={anonymousPublish ? undefined : item.avatar.image}
        style={{color: colorObject.color, background: 'none'}}
        children={<FontAwesomeIcon icon={anonymousPublish ? faUserSecret : item.avatar.icon as any} />}
      />;
    } else if (item.avatar.type === 'user') {
      const userId = item.avatar.avatarUserId;
      if (item?.__original?.content?.anonymousPublish
        || (
            item?.__original?.content?.itemRefResolved?.content?.anonymousPublish
            && !item?.__original?.content?.notAnonymous
        )
        || (
          item?.__original?.content?.itemRefResolved?.content?.connectedEntryResolved?.[0]?.content?.anonymousPublish
        )
      ) {
        const hash = hashCode('AN');
        const colorIndex = hash % 4;
        const colorObject = ListAvatarColors[colorIndex];
        avatar = <Ava size="large"
          src={undefined}
          style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}}
          children={<FontAwesomeIcon icon={faMask} />}
        />;
      } else {
        let avatarImage: string | undefined = undefined;
        if ((!userId || (!!userId && !avatarDict) || (!!userId && !!avatarDict && !avatarDict[userId])) && !!item?.avatar?.image) {
          avatarImage = item?.avatar?.image;
        } else if (!!userId && !!avatarDict && avatarDict[userId]) {
          avatarImage = assetUrl(avatarDict[userId].avatar, true);
        } else if (!!userId && !!avatarDict && !avatarDict[userId]) {
          avatarImage = undefined;
        }
        const hash = hashCode(item?.avatar?.abbreviation ?? '');
        const colorIndex = hash % 4;
        const colorObject = ListAvatarColors[colorIndex];
        avatar = <Ava size="large" src={avatarImage} style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}}>{item.avatar.abbreviation}</Ava>;
      }
    } else if (item.avatar.type === 'icon' && item.avatar.icon) {
      const hash = hashCode(item?.title?.slice(0,2) ?? '');
      const colorIndex = hash % 4;
      const colorObject = ListAvatarColors[colorIndex];
      avatar = <IconAva style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}} children={<FontAwesomeIcon icon={item.avatar.icon} />} size="large" />;
    }

    let userAvatar: ReactChild = <></>;
    if (item.userAvatar?.type === 'user') {
      const hash = hashCode(item.userAvatar?.abbreviation ?? '');
      const colorIndex = hash % 4;
      const colorObject = ListAvatarColors[colorIndex];
      userAvatar = <Ava size="large" style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}} src={item.userAvatar.image}>{item.userAvatar.abbreviation}</Ava>
    }
    return (
      <BaseListItem
        key={`generic_list_item_${item?._id || index}`}
        {...item}
        avatar={avatar}
        userAvatar={userAvatar}
        to={getDetailRoute(item, appconfig || {}, location)}
        userLink={item?.userLink}
        fontcolor={isInverted ? 'primary.contrastText' : 'text.primary'}
        highlight={item.highlight}
        badge={item?.badge ? <span style={{ visibility: 'hidden' }}>.</span> : undefined}
        selected={item.selected}
        anchor={item.anchor}
        itemTemplate={!!item?.itemTemplate ? item?.itemTemplate : listType}
        noPadding={true}
      />
    );
  } catch (e) {
    return <></>;
  }
};
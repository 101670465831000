import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Bx, Btn, Html2View, LayoutImage, Link, Typo } from '@curry-group/mui-curcuma';
import { Fade, useMediaQuery } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import { IContentInteractionBarProps, ContentInteractionBar } from '../../content-interaction-bar';
import { Skeleton } from '@material-ui/lab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faGlobe } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { interactionButtonSettings, transformForList } from '../../../model/ryve/Thing';
import { setFlowDataAction } from '../../../data/actions/flow';
import { GetActorFallbackImage, GetActorType, getDetailRoute } from '../../../helper';
import { ListWidget } from '../../widget/list';
import { FramedContent } from '../../framed-content';
import { userprofileMemorylistRequestAction } from '../../../data/actions/foundation';

export interface IProfileProps extends IContentInteractionBarProps {
  isScrolled?: boolean;
  setIsScrolled?: (isScrolled: any) => void;
}

const LinkBehavior = props => {
  return <Link {...props} />;
};

export const ConnectedPlayerProfileComponent: React.FC<IProfileProps> = ({ isScrolled, setIsScrolled, children }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const dispatch = useDispatch();
  const history = useHistory();

  const config = useSelector(state => state.foundation.appconfig);
  const item = useSelector(state => state.detail.item);
  const working = useSelector(state => state.detail.working);
  const itemType = useSelector(state => state.detail.item?.type);
  const type = useSelector(state => state.detail.item?.content?.type);
  const subtype = useSelector(state => state.detail.item?.content?.subtype);
  const name = useSelector(state => state.detail.item?.content?.name);
  const address = useSelector(state => state.detail.item?.content?.address);
  const zip = useSelector(state => state.detail.item?.content?.zip);
  const city = useSelector(state => state.detail.item?.content?.city);
  const country = useSelector(state => state.detail.item?.content?.country);
  const url = useSelector(state => state.detail.item?.content?.url);
  const hospital_id = useSelector(state => state.detail.item?.content?.hospital_id);
  const university_id = useSelector(state => state.detail.item?.content?.university_id);
  const parent_id = useSelector(state => state.detail.item?.content?.parent_id);
  const faculties = useSelector(state => state.detail.item?.content?.faculties);
  const standorte = useSelector(state => state.detail.item?.content?.standorte);
  const departments = useSelector(state => state.detail.item?.content?.departments);
  const employees = useSelector(state => state.detail.children.items);
  const employeesWorking = useSelector(state => state.detail.children.working);

  const detailId = useSelector(state => state.detail.item?._id);
  const memoryList = useSelector(state => state.foundation?.profile?.memoryList);
  const memoryIds = memoryList?.map(m => m.elementId);
  const booked = !!memoryIds && !!detailId ? memoryIds.includes(detailId) : false;

  const [bgImageLoaded, setBgImageLoaded] = useState(false);
  const parent = hospital_id || university_id || parent_id;

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  useEffect(() => {
    setIsScrolled?.(false);
  }, [history.location.pathname, setIsScrolled]);

  function handleScroll() {
    if (document.documentElement.scrollTop < 100) {
      if (isScrolled) setIsScrolled?.(false);
      return;
    }
    if (!isScrolled) setIsScrolled?.(true);
  }

  function handleBgImageLoaded() {
    !bgImageLoaded && setBgImageLoaded(true);
  }

  const dispatcher = {
    memoryClick: () => {
      dispatch(userprofileMemorylistRequestAction({
        memoryItem: { elementId: item?._id || '', type: 'thing', typeId: item?.type || ''},
        type: booked ? 'DELETE' : 'POST'
      }))
    },
  }

  return (
    <React.Fragment>
      <Fade in={bgImageLoaded}>
        <div>
          <LayoutImage
            src={GetActorFallbackImage(type).src}
            alt={GetActorFallbackImage(type).alt}
            position="absolute"
            top={0}
            left={0}
            right={0}
            width="100%"
            height={685}
            zIndex={-1}
            bgcolor={[
              { bgcolor: 'transparent', position: 0 },
              { bgcolor: 'background.default', position: 100 }
            ]}
            gradientDirection="to bottom"
            objectFit="cover"
            onLoadCallback={handleBgImageLoaded}
          />
        </div>
      </Fade>
      <FramedContent position="relative" zIndex={1}>
        <Bx>
          {!working && (
            <>
              <Bx bgcolor="background.paper" borderRadius="borderRadius" py={{ xs: 2, md: 5.5 }} px={{ xs: 3, md: 7.5 }}>
                <Bx>
                  {/* Head */}
                  <Bx display="flex" justifyContent="space-between" alignItems="flex-start" mb={3}>
                    <Bx>
                      <Bx mb={5}>
                        <Typo variant="subtitle2" component="span" textTransform="uppercase">
                          {GetActorType(subtype || type)}
                        </Typo>
                      </Bx>

                      {parent && (
                        <Bx mb={2}>
                          {'Gehört zu: '}
                          <LinkBehavior to={getDetailRoute({ alias: parent.seo?.alias, typeId: parent.type }, config || {}, history.location)} underline="hover">
                            {parent.content?.name}
                          </LinkBehavior>
                        </Bx>
                      )}
                      <Bx mb={2}>
                        <Typo variant={isMdUp ? 'h1boosted' : 'h1'} component="h1">
                          {name}
                        </Typo>
                      </Bx>
                      <Bx mb={2}>
                        <Bx mb={1}>
                          <Typo variant={isMdUp ? 'h4boosted' : 'h4'} component="h4">
                            {address}
                          </Typo>
                        </Bx>
                        <Bx mb={1}>
                          <Typo variant={isMdUp ? 'h4boosted' : 'h4'} component="h4">
                            {zip + ' ' + city}
                          </Typo>
                        </Bx>
                        <Bx mb={1}>
                          <Typo variant={isMdUp ? 'h4boosted' : 'h4'} component="h4">
                            {country}
                          </Typo>
                        </Bx>
                      </Bx>
                    </Bx>
                  </Bx>

                  {/* Meta Buttons */}
                  <Bx mb={4}>
                    <ContentInteractionBar
                      shareClick={
                        !interactionButtonSettings(itemType).hideShare
                          ? () => {
                              dispatch(
                                setFlowDataAction({
                                  alias: 'share',
                                  flowData: {
                                    location: history.location,
                                    itemData: {
                                      sharedItem: item
                                    }
                                  }
                                })
                              );
                              history.push(history.location.pathname + '/flow' + history.location.search);
                            }
                          : undefined
                      }
                      booked={booked}
                      memoryClick={dispatcher.memoryClick}
                    />
                  </Bx>
                  {!!url && (
                    <Bx pt={1.5} display="flex" alignItems="center">
                      <Bx width={36}>
                        <Typo variant={isMdUp ? 'h6boosted' : 'h6'}>
                          <FontAwesomeIcon icon={faGlobe} />
                        </Typo>
                      </Bx>
                      <Bx>
                        <Typo variant={isMdUp ? 'h5boosted' : 'h5'}>
                          <LinkBehavior component="a" underline="hover" href={`https://${url.replace('http://', '').replace('https://', '')}`} target="_blank">
                            {url.replace('http://', '').replace('https://', '')}
                          </LinkBehavior>
                        </Typo>
                      </Bx>
                    </Bx>
                  )}
                </Bx>
              </Bx>
              {!employeesWorking && employees && employees.length > 0 && (
                <Bx bgcolor="background.paper" borderRadius="borderRadius" py={{ xs: 2, md: 5.5 }} px={{ xs: 3, md: 7.5 }} mt={4}>
                  <ListWidget
                    headline="Verknüpfte Nutzer"
                    items={transformForList(
                      employees.map(item => {
                        return {
                          _source: {
                            ...item,
                            content: { ...item.content, title: '' }
                          },
                          _id: item._id,
                          _score: 0
                        };
                      })
                    )}
                  />
                </Bx>
              )}
              {!working && faculties && faculties.length > 0 && (
                <Bx bgcolor="background.paper" borderRadius="borderRadius" py={{ xs: 2, md: 5.5 }} px={{ xs: 3, md: 7.5 }} mt={4}>
                  <ListWidget
                    headline="Einrichtungen"
                    items={transformForList(
                      faculties.map(item => {
                        return {
                          _source: {
                            ...item.id,
                            content: { ...item.id.content, title: '' }
                          },
                          _id: item.id._id,
                          _score: 0
                        };
                      })
                    )}
                  />
                </Bx>
              )}
              {!working && standorte && standorte.length > 0 && (
                <Bx bgcolor="background.paper" borderRadius="borderRadius" py={{ xs: 2, md: 5.5 }} px={{ xs: 3, md: 7.5 }} mt={4}>
                  <ListWidget
                    headline="Standorte"
                    items={transformForList(
                      standorte.map(item => {
                        return {
                          _source: {
                            ...item.id,
                            content: { ...item.id.content, title: '' }
                          },
                          _id: item.id._id,
                          _score: 0
                        };
                      })
                    )}
                  />
                </Bx>
              )}
              {!working && departments && departments.length > 0 && (
                <Bx bgcolor="background.paper" borderRadius="borderRadius" py={{ xs: 2, md: 5.5 }} px={{ xs: 3, md: 7.5 }} mt={4}>
                  <ListWidget
                    headline="Einrichtungen"
                    items={transformForList(
                      departments.map(item => {
                        return {
                          _source: {
                            ...item._id,
                            content: { ...item._id.content, title: '' }
                          },
                          _id: item._id._id,
                          _score: 0
                        };
                      })
                    )}
                  />
                </Bx>
              )}
              {children}
            </>
          )}
          {working && (
            <Bx bgcolor="background.paper" borderRadius="borderRadius2" py={5.5} px={7.5}>
              {/* Type and Date */}
              <Bx display="flex" justifyContent="space-between" alignItems="center" mb={5}>
                <Bx>
                  <Typo variant="subtitle2" component="span" textTransform="uppercase">
                    <Skeleton animation="wave" width="152px" />
                  </Typo>
                </Bx>
              </Bx>
              {/* Headline H1 */}
              <Bx mb={3.5}>
                <Typo variant="h1" component="h1">
                  <Skeleton animation="wave" width="80%" />
                  <Skeleton animation="wave" width="60%" />
                </Typo>
              </Bx>

              {/* Meta Buttons */}
              <Bx mb={4}>
                <Bx borderColor="divider" border={1} borderLeft={0} borderRight={0} height={74}>
                  <Bx display="flex" alignItems="center" height="100%" py={0.5}>
                    <Bx display="flex" justifyContent="center" alignItems="center">
                      <Btn color="default" size="large" variant="contained" startIcon={<Skeleton animation="wave" width="20px" height="20px" />}>
                        <Skeleton animation="wave" width="150px" />
                      </Btn>
                    </Bx>
                    <Bx ml={1} display="flex" justifyContent="center" alignItems="center">
                      <Btn color="default" size="large" variant="contained" startIcon={<Skeleton animation="wave" width="20px" height="20px" />}>
                        <Skeleton animation="wave" width="80px" />
                      </Btn>
                    </Bx>
                  </Bx>
                </Bx>
              </Bx>
              {/* Content */}
              <Html2View boosted loading />
            </Bx>
          )}
        </Bx>
        {GetActorFallbackImage(type).copyright && (
          <Bx mt={2}>
            <Typo variant="subtitle2">{'Hintergrundbild: ' + GetActorFallbackImage(type).copyright}</Typo>
          </Bx>
        )}
        <Fade in={isScrolled}>
          <Bx position="fixed" bottom={theme.spacing(4)} right={theme.spacing(4)}>
            <Btn
              size="large"
              endIcon={<FontAwesomeIcon icon={faAngleUp} />}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              zum Seitenanfang
            </Btn>
          </Bx>
        </Fade>
      </FramedContent>
    </React.Fragment>
  );
};

import { Bx, ClickableCard, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { FramedContent } from '../../framed-content';

const PublicSelect = (props: any) => {
  return (
    <FramedContent>
      <Bx mb={1}>
        <Typo variant="h1" component="h1">
          Wie möchten Sie Ihren Beitrag posten?
        </Typo>
      </Bx>
      <Typo variant="subtitle1">Wir favorisieren Transparenz. Manchmal gibt es aber gute Gründe, anonym zu bleiben.</Typo>
      <Bx my={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.flowAnonymous === false}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              to="#"
              onClick={e => {
                props.flowFunctions.setFlowAnon(false);
                props.nextClick(e);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/klarnamen.svg" alt="Idee teilen" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    mit Klarnamen
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Ihr Beitrag weist Ihre Urheberschaft aus. Nutzende des Portals können Sie mit Ihrer Zustimmung kontaktieren.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
          <Grid item xs={12} md={4} lg="auto">
            <ClickableCard
              active={props.flowAnonymous}
              variant="outlined"
              fluid={{ from: 278, to: 458 }}
              height="100%"
              to="#"
              onClick={e => {
                props.flowFunctions.setFlowAnon(true);
                props.nextClick(e);
              }}
            >
              <Bx py={2} px={3} height="100%" display="flex" flexDirection="column">
                <Bx display="flex" justifyContent="center" alignItems="center" mt={4} mb={4} height="100%">
                  <img src="/images/anonym.svg" alt="Bedarf melden" width="70%" />
                </Bx>
                <Bx flexGrow={1} flexShrink={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <Typo variant="h2" align="center">
                    lieber anonym
                  </Typo>
                  <Bx mt={2} mb={2}>
                    <Typo variant="body1" align="center">
                      Ihr Beitrag weist keine Urheberschaft aus. Nutzende des Portals können Sie persönlich nicht kontaktieren.
                    </Typo>
                  </Bx>
                </Bx>
              </Bx>
            </ClickableCard>
          </Grid>
        </Grid>
      </Bx>
    </FramedContent>
  );
};

export default PublicSelect;

import { callDelete, callGet, callPost, callPostPlain } from '@curry-group/data-addons';
import { all, put, takeLatest } from 'redux-saga/effects';
import { IToken } from '../../../components/user-profile/tokenAdminComponent';
import { GetCookie, GetFormData } from '../../../helper';
import { IProfile } from '../../../model/profile';
import { ThingTypes, transformForList } from '../../../model/ryve/Thing';
import { userLogoutRequestAction } from '../../actions/auth';
import { fetchStartupRequestAction } from '../../actions/foundation';
import { api } from '../../api';
import { subscribeToSignalR } from '../../reducer/communication';
import {
  fetchDetailSuccess,
  userprofileRestore,
  userprofileRestoreSuccess,
  userprofileTermination,
  userprofileTerminationFailed,
  userprofileUpdate,
  userprofileUpdateAvatar,
  userprofileUpdateAvatarFailed,
  userprofileUpdateFailed,
  userprofileUpdateKeyVisual,
  userprofileUpdateKeyVisualFailed,
  userprofileUpdatePassword,
  userprofileUpdatePasswordFailed,
  userprofileUpdatePasswordSuccess,
} from '../../reducer/detail';
import {
  clearRequestToken,
  fetchActorProfile,
  fetchActorProfileFailed,
  fetchActorProfileSuccess,
  fetchPlayerAutoSuggest,
  fetchPlayerAutoSuggestFailed,
  fetchPlayerAutoSuggestSuccess,
  fetchRelatedItems,
  fetchRelatedItemsFailed,
  fetchRelatedItemsSuccess,
  fetchUserprofileAdminPendingTokens,
  fetchUserprofileAdminPendingTokensFailed,
  fetchUserprofileAdminPendingTokensSuccess,
  IRelatedItem,
  IUserActivities,
  registerAdmin,
  registerExpertAdmin,
  registerExpertAdminResponse,
  userprofileCommunicationCreateFailed,
  userprofileCommunicationCreateRequest,
  userprofileCommunicationCreateSuccess,
  userprofileCommunicationFailed,
  userprofileCommunicationRequest,
  userprofileCommunicationSuccess,
  getAllGroupsRequest,
  getAllGroupsSuccess,
  getAllGroupsFailed,
  resetAndResendNotifications,
} from '../../reducer/userprofile';
import { IGroupSelect } from '../../../model/profile/groupselect';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export function useGetAllGroups() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllGroupsRequest({}));
  }, [dispatch]);
}

export function useGetAllGroupsState(): Array<IGroupSelect> | undefined {
  return useSelector(state => state.userProfile.groupList);
}

function* watcher() {
  yield takeLatest(userprofileUpdate.type, userProfileUpdateWorker);
  yield takeLatest(userprofileUpdatePassword.type, userProfileUpdatePasswordWorker);
  yield takeLatest(userprofileUpdateAvatar.type, userProfileAvatarUpdateWorker);
  yield takeLatest(userprofileUpdateKeyVisual.type, userProfileKeyVisualUpdateWorker);
  yield takeLatest(userprofileTermination.type, userProfileTerminationWorker);
  yield takeLatest(userprofileRestore.type, userProfileRestoreWorker);
  yield takeLatest(fetchPlayerAutoSuggest.type, fetchPlayerAutosuggestWorker);
  yield takeLatest(fetchRelatedItems.type, fetchRelateditemsWorker);
  yield takeLatest(userprofileCommunicationRequest.type, fetchOneToOneWorker);
  yield takeLatest(userprofileCommunicationCreateRequest.type, requestOneToOneWorker);
  yield takeLatest(fetchActorProfile.type, fetchActorProfileWorker);
  yield takeLatest(fetchUserprofileAdminPendingTokens.type, fetchUserprofileAdminPendingTokensWorker);
  yield takeLatest(registerAdmin.type, registerAdminWorker);
  yield takeLatest(registerExpertAdmin.type, registerExpertAdminWorker);
  yield takeLatest(clearRequestToken.type, clearRequestTokenWorker);
  yield takeLatest(getAllGroupsRequest.type, getAllGroupsWorker);
  yield takeLatest(resetAndResendNotifications.type, resetAndResendNotificationsWorker);
}

function* userProfileUpdateWorker(action: ReturnType<typeof userprofileUpdate>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result: IProfile = yield callPost(api.v1.profile.updateProfile, action.payload, addHeader);
    yield put(fetchDetailSuccess(result));
    yield put(fetchStartupRequestAction());
  } catch (e: any) {
    yield put(userprofileUpdateFailed());
  }
}

function* userProfileUpdatePasswordWorker(action: ReturnType<typeof userprofileUpdatePassword>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    yield callPost(api.v1.profile.changePassword, action.payload, addHeader);
    yield put(userprofileUpdatePasswordSuccess());
  } catch (e: any) {
    yield put(userprofileUpdatePasswordFailed());
  }
}

function* userProfileAvatarUpdateWorker(action: ReturnType<typeof userprofileUpdateAvatar>) {
  try {
    const uploadData = new FormData();
    uploadData.append('file', action.payload, action.payload.name);
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result: IProfile = yield callPostPlain(api.v1.profile.updateAvatar, uploadData, addHeader);
    yield put(fetchDetailSuccess(result));
  } catch (e: any) {
    //----- restore profile -----
    const storedProfile = yield callGet(api.v1.profile.myProfile);
    yield put(userprofileUpdateAvatarFailed(storedProfile));
  }
}

function* userProfileKeyVisualUpdateWorker(action: ReturnType<typeof userprofileUpdateKeyVisual>) {
  try {
    const uploadData = new FormData();
    uploadData.append('file', action.payload, action.payload.name);
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result: IProfile = yield callPostPlain(api.v1.profile.updateKeyVisual, uploadData, addHeader);
    yield put(fetchDetailSuccess(result));
  } catch (e: any) {
    //----- restore profile -----
    const storedProfile = yield callGet(api.v1.profile.myProfile);
    yield put(userprofileUpdateKeyVisualFailed(storedProfile));
  }
}

function* userProfileTerminationWorker(action: ReturnType<typeof userprofileTermination>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    yield callPost(api.v1.profile.terminateAccount, undefined, addHeader);
    // ----- logout after termination -----
    yield put(userLogoutRequestAction);
  } catch (e: any) {
    yield put(userprofileTerminationFailed());
  }
}

function* userProfileRestoreWorker() {
  yield put(userprofileRestoreSuccess());
}

function* fetchPlayerAutosuggestWorker(action: ReturnType<typeof fetchPlayerAutoSuggest>) {
  try {
    // no csrf token needed, post does not manipulate data
    const result = yield callPost(api.v1.players.autosuggest, { queryTerm: action.payload, querySort: '', queryCategories: {}, queryTypes: [] });
    yield put(fetchPlayerAutoSuggestSuccess(result));
  } catch (e: any) {
    yield put(fetchPlayerAutoSuggestFailed());
  }
}

function* fetchRelateditemsWorker(action: ReturnType<typeof fetchRelatedItems>) {
  try {
    const result: IUserActivities = yield callGet(api.params(api.v1.profile.myItems, action.payload));
    const wrappedResult: IUserActivities = {
      created: new Array<IRelatedItem>(),
      member: new Array<IRelatedItem>(),
    };
    //----- prefilter UserProfile -----
    for (const key in result) {
      result[key].filter(r => r.type !== ThingTypes.Profile).forEach(r => wrappedResult[key].push(r));
    }
    yield put(
      fetchRelatedItemsSuccess({
        created: transformForList(
          wrappedResult.created?.map(item => {
            return {
              _source: item,
              _id: item._id,
              _score: item._score,
            };
          })
        ),
        member: transformForList(
          wrappedResult.member?.map(item => {
            return {
              _source: item,
              _id: item._id,
              _score: item._score,
            };
          })
        ),
      })
    );
  } catch (e: any) {
    yield put(fetchRelatedItemsFailed());
  }
}

function* fetchActorProfileWorker(action: ReturnType<typeof fetchActorProfile>) {
  try {
    yield put(fetchActorProfileSuccess(yield callGet(api.params(api.v2.detail, { type: 'actor', alias: action.payload.alias }))));
  } catch (e: any) {
    yield put(fetchActorProfileFailed());
  }
}

function* fetchOneToOneWorker(action: ReturnType<typeof userprofileCommunicationRequest>) {
  try {
    const result = yield callGet(api.params(api.v2.communication.fetchonetoone, { partnerId: action.payload.id }));
    if (result.success) {
      yield put(userprofileCommunicationSuccess({ data: result.data }));
      yield put(subscribeToSignalR({ id: result.data.id }));
    } else {
      yield put(userprofileCommunicationFailed({}));
    }
  } catch (e: any) {
    yield put(userprofileCommunicationFailed({ message: e.message }));
  }
}

function* requestOneToOneWorker(action: ReturnType<typeof userprofileCommunicationCreateRequest>) {
  try {
    const newConversationForm = GetFormData('NEW', { partner: action.payload.userId, invitation: action.payload.message }, [], [], false);
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    yield callPostPlain(api.v2.communication.fetchonetoone, newConversationForm, addHeader);
    yield put(userprofileCommunicationCreateSuccess({}));
    // in der action muss detail auf die thingId des chats gesetzt werden
    if (action.payload.detail) {
      // hier soll dann zum single chat geleitet werden
      // yield put(fetchParticipationRequest({ id: action.payload.detail }));
      // yield put(fetchCommunicationByIdRequest({ id: action.payload.detail }));
    } else {
      yield put(userprofileCommunicationRequest({ id: action.payload.userId }));
    }
  } catch (e: any) {
    yield put(userprofileCommunicationCreateFailed({ message: e.message }));
  }
}

function* fetchUserprofileAdminPendingTokensWorker(action: ReturnType<typeof fetchUserprofileAdminPendingTokens>) {
  try {
    const result: Array<IToken> = yield callGet(api.v1.profile.pendingtokens);
    yield put(
      fetchUserprofileAdminPendingTokensSuccess({
        pendingTokens: transformForList(
          result?.map(item => {
            return { _source: { _id: item._id, content: { ...item, title: item.refId }, type: 'OTT', categories: [], _score: 0 }, _id: item._id, _score: 0 };
          })
        ),
      })
    );
  } catch (e: any) {
    yield put(fetchUserprofileAdminPendingTokensFailed({}));
  }
}

function* registerAdminWorker(action: ReturnType<typeof registerAdmin>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;

    const email = action.payload.email;
    yield callPost(api.query(api.auth.register, { email }), undefined, addHeader);
  } catch (e: any) {
    console.log(e);
  }
}

function* registerExpertAdminWorker(action: ReturnType<typeof registerExpertAdmin>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;

    const expertRequest = action.payload.request;
    const result = yield callPost(api.auth.registerExpert, expertRequest, addHeader);
    yield put(registerExpertAdminResponse({ response: result }));
  } catch (e: any) {
    yield put(registerExpertAdminResponse({ response: 'Fahler bei Registrierung!' }));
    console.log(e);
  }
}

function* clearRequestTokenWorker(action: ReturnType<typeof clearRequestToken>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result: boolean = yield callDelete(api.params(api.v1.profile.resettokenrequest, action.payload), undefined, addHeader);
    if (!result) {
      console.log('token could not be deleted');
    }
    yield put(fetchUserprofileAdminPendingTokens({}));
  } catch (e: any) {
    console.log(e);
  }
}

function* getAllGroupsWorker(action: ReturnType<typeof getAllGroupsRequest>) {
  try {
    const result: Array<IGroupSelect> = yield callGet(api.auth.getallgroups);
    if (result) {
      yield put(getAllGroupsSuccess({ groupList: result }));
    } else {
      yield put(getAllGroupsFailed({}));
    }
  } catch (e: any) {
    yield put(getAllGroupsFailed({}));
  }
}

function* resetAndResendNotificationsWorker(action: ReturnType<typeof resetAndResendNotifications>) {
  try {
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    const result: boolean = yield callGet(api.params(api.v1.profile.resendnotifications, action.payload), undefined, addHeader);
    if (!result) {
      console.log('messages cold not be send');
    }
  } catch (e: any) {
    console.log(e);
  }
}

export function* userProfile() {
  yield all([watcher()]);
}

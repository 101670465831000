import React, { useEffect } from 'react';
import { ListWidget } from '../widget/list';
import { IMemoryListItem, ThingTypes, transformForList } from '../../model/ryve/Thing';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchNextPageSearchEntriesRequestAction } from '../../data/actions/search';
import { ISearchResult } from '../../model/search/SearchResult';
import { FramedContent } from '../framed-content';
import { INotification } from '../../model/notifications';
import { userprofileMemorylistRequestAction } from '../../data/actions/foundation';
import { setFlowDataAction } from '../../data/actions/flow';

export interface ISearchResultProps {
  result?: ISearchResult | null;
  error?: string;
  first?: boolean;
  working?: boolean;
  moreDataAvailable?: boolean;
  unreadNotifications?: Array<INotification>;
  fetchNextPageSearchEntries?: () => void;
  memoryIds?: Array<string>;
  itemMemoryClicked?: (item: IMemoryListItem, type: 'GET'|'POST'|'DELETE') => void;
  profileId?: string;
  shareClick?: (e:any) => void;
}

export const SearchResults: React.FC<ISearchResultProps> = ({
  result,
  error,
  first,
  working,
  moreDataAvailable,
  unreadNotifications,
  fetchNextPageSearchEntries,
  memoryIds,
  itemMemoryClicked,
  profileId,
  shareClick
}) => {
  function handleScroll() {
    if (window.innerHeight + document.documentElement.scrollTop + window.innerHeight * 1.2 <= document.documentElement.offsetHeight) return;
    if (!working && moreDataAvailable) fetchNextPageSearchEntries?.();
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });
  return (
    <FramedContent>
      <ListWidget
        working={first || (working && !result?.hits?.hits?.length)}
        items={transformForList(result?.hits?.hits.map(hit => {
          return {
            ...hit,
            badge: !!unreadNotifications?.find(notification => notification.content?.itemRef === hit._id),
            booked: hit._source?.type === ThingTypes.News
              ? (!!hit._source?.content.url ? memoryIds?.includes(hit._source?.content.url) : false)
              : memoryIds?.includes(hit._id),
            noShare: hit._source?.type === ThingTypes.OneToOne || ((hit._source?.type === ThingTypes.Group || hit._source?.type === ThingTypes.Project) && !hit._source?.content?.listed),
            profileId: memoryIds || hit._source?.type === ThingTypes.OneToOne ? profileId : undefined
          };
        }))}
        headline={result?.hits?.total ? `Suchergebnisse (${result.hits.total})` : 'Keine Suchergebnisse'}
        itemMemoClicked={itemMemoryClicked}
        shareClick={shareClick}
      />
    </FramedContent>
  );
};

export const ConnectedSearchContent = () => {
  const history = useHistory();
  const result = useSelector(state => state.search?.result);
  const error = useSelector(state => state.search?.error);
  const first = useSelector(state => state.search?.first);
  const working = useSelector(state => state.search?.working);
  const moreDataAvailable = useSelector(state => state.search?.moreDataAvailable);
  const notifications = useSelector(state => state.notifications?.items);
  const memoryItems = useSelector(state => state.foundation?.profile?.memoryList);
  const profileId = useSelector(state => state.foundation?.profile?.profileId);
  
  const dispatch = useDispatch();
  const dispatcher = {
    fetchNextPageSearchEntriesAction: () => dispatch(fetchNextPageSearchEntriesRequestAction?.({})),
    itemMemoryClicked: (item: IMemoryListItem, type: 'GET'|'POST'|'DELETE') => dispatch(userprofileMemorylistRequestAction({
      memoryItem: item,
      type: type
    })),
    shareClick: (detailItem) => {
      dispatch(setFlowDataAction({
        alias: 'share',
        flowData: {
          location: history.location,
          itemData: { sharedItem: detailItem }
        }
      }));
      history.push(history.location.pathname + '/flow' + history.location.search);
    }
  };

  const memoryIds = memoryItems?.map(m => m.typeId === ThingTypes.News ? (m.externalId ?? '') : m.elementId);

  return (
    <SearchResults
      result={result}
      error={error}
      first={first}
      working={working}
      moreDataAvailable={moreDataAvailable}
      unreadNotifications={notifications.filter(notification => !notification.content?.notificationRead)}
      fetchNextPageSearchEntries={dispatcher.fetchNextPageSearchEntriesAction}
      memoryIds={memoryIds}
      itemMemoryClicked={dispatcher.itemMemoryClicked}
      profileId={profileId}
      shareClick={dispatcher.shareClick}
    />
  );
};

import { createAction } from '@reduxjs/toolkit';
import { IPageElement } from '../../../model/data/PageElement';
import { ISearchResult } from '../../../model/search/SearchResult';

export const fetchFirstPageSearchEntriesRequestAction = createAction<{}, 'FETCH_FIRST_PAGE_SEARCH_ENTRIES_REQUEST'>('FETCH_FIRST_PAGE_SEARCH_ENTRIES_REQUEST');
export const fetchFirstPageSearchEntriesErrorAction = createAction<{ message: string }, 'FETCH_FIRST_PAGE_SEARCH_ENTRIES_ERROR'>('FETCH_FIRST_PAGE_SEARCH_ENTRIES_ERROR');
export const fetchFirstPageSearchEntriesSuccessAction = createAction<{ result: ISearchResult }, 'FETCH_FIRST_PAGE_SEARCH_ENTRIES_SUCCESS'>(
  'FETCH_FIRST_PAGE_SEARCH_ENTRIES_SUCCESS'
);

export const fetchNextPageSearchEntriesRequestAction = createAction<{}, 'FETCH_NEXT_PAGE_SEARCH_ENTRIES_REQUEST'>('FETCH_NEXT_PAGE_SEARCH_ENTRIES_REQUEST');
export const fetchNextPageSearchEntriesErrorAction = createAction<{ message: string }, 'FETCH_NEXT_PAGE_SEARCH_ENTRIES_ERROR'>('FETCH_NEXT_PAGE_SEARCH_ENTRIES_ERROR');
export const fetchNextPageSearchEntriesSuccessAction = createAction<{ result: ISearchResult }, 'FETCH_NEXT_PAGE_SEARCH_ENTRIES_SUCCESS'>(
  'FETCH_NEXT_PAGE_SEARCH_ENTRIES_SUCCESS'
);

export const clearSearchEntriesAction = createAction<{}, 'CLEAR_SEARCH_ENTRIES'>('CLEAR_SEARCH_ENTRIES');

export const fetchFirstPageRelatedEntriesRequestAction = createAction<{ pageElement: IPageElement }, 'FETCH_FIRST_PAGE_RELATED_ENTRIES_REQUEST'>(
  'FETCH_FIRST_PAGE_RELATED_ENTRIES_REQUEST'
);
export const fetchFirstPageRelatedEntriesErrorAction = createAction<{ message: string }, 'FETCH_FIRST_PAGE_RELATED_ENTRIES_ERROR'>('FETCH_FIRST_PAGE_RELATED_ENTRIES_ERROR');
export const fetchFirstPageRelatedEntriesSuccessAction = createAction<{ result: ISearchResult }, 'FETCH_FIRST_PAGE_RELATED_ENTRIES_SUCCESS'>(
  'FETCH_FIRST_PAGE_RELATED_ENTRIES_SUCCESS'
);

export const fetchNextPageRelatedEntriesRequestAction = createAction<{ pageElement: IPageElement }, 'FETCH_NEXT_PAGE_RELATED_ENTRIES_REQUEST'>(
  'FETCH_NEXT_PAGE_RELATED_ENTRIES_REQUEST'
);
export const fetchNextPageRelatedEntriesErrorAction = createAction<{ message: string }, 'FETCH_NEXT_PAGE_RELATED_ENTRIES_ERROR'>('FETCH_NEXT_PAGE_RELATED_ENTRIES_ERROR');
export const fetchNextPageRelatedEntriesSuccessAction = createAction<{ result: ISearchResult }, 'FETCH_NEXT_PAGE_RELATED_ENTRIES_SUCCESS'>(
  'FETCH_NEXT_PAGE_RELATED_ENTRIES_SUCCESS'
);

export const fetchPlayersByParentIdRequestAction = createAction<{ type: string; id: string }, 'FETCH_PLAYERS_BY_PARENT_ID_REQUEST'>('FETCH_PLAYERS_BY_PARENT_ID_REQUEST');
export const fetchPlayersByParentIdErrorAction = createAction<{ message: string }, 'FETCH_PLAYERS_BY_PARENT_ID_ERROR'>('FETCH_PLAYERS_BY_PARENT_ID_ERROR');
export const fetchPlayersByParentIdSuccessAction = createAction<{ result: any }, 'FETCH_PLAYERS_BY_PARENT_ID_SUCCESS'>('FETCH_PLAYERS_BY_PARENT_ID_SUCCESS');
export const fetchPlayersByParentIdResetAction = createAction<{}, 'FETCH_PLAYERS_BY_PARENT_ID_RESET'>('FETCH_PLAYERS_BY_PARENT_ID_RESET');

export const setSearchWorkingAction = createAction<{ working: boolean }, 'SET_SEARCH_WORKING'>('SET_SEARCH_WORKING');

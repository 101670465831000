import { call, put, take, fork, select, cancel, takeLatest, actionChannel } from 'redux-saga/effects';
import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr';
import { EventChannel, eventChannel } from 'redux-saga';
import { MmoaoMessageAction, MmoaoActionTypes, MmoaoParticipationAction, MmoaoFileAction, MmoaoResponseAction } from '../../../model/communication/Actions';

import { userDeauthAction, userLoginSuccessAction, userLogoutSuccessAction, userMtoLoginSuccessAction } from '../../actions/auth';
import { createAction } from '@reduxjs/toolkit';

import {
  fetchParticipationRequest,
  fetchMessageByIdRequest,
  fetchParticipantsRequest,
  getNumUnreadMessagesByIdRequest,
  fetchCommunicationByIdRequest,
  subscribeToSignalR,
  unsubscribeFromSignalR,
  fetchParticipationSuccess,
} from '../../reducer/communication';

import { DefaultRootState } from 'react-redux';
import { api } from '../../api';
import { userprofileCommunicationRequest } from '../../reducer/userprofile';
import { notificationsUpdatedAction } from '../../actions/notifications';
import { CommunicationType } from '../../../model/communication/Communication';
import { fetchDetailRequest, fetchMeetingParticipantsRequest } from '../../reducer/detail';
import { botResponseChunkAction, botResponseEndAction } from '../../actions/news';

const connectionEstablishedSignalRAction = createAction<undefined, 'signalr-connection-established'>('signalr-connection-established');

const messageCreatedSignalRAction = createAction<MmoaoMessageAction, 'signalr-message-created'>('signalr-message-created');
const messageDeletedSignalRAction = createAction<MmoaoMessageAction, 'signalr-message-deleted'>('signalr-message-deleted');
const messageUpdatedSignalRAction = createAction<MmoaoMessageAction, 'signalr-message-updated'>('signalr-message-updated');
const messageVotedSignalRAction = createAction<MmoaoMessageAction, 'signalr-message-voted'>('signalr-message-voted');

const participantRemovedSignalRAction = createAction<MmoaoParticipationAction, 'signalr-participant-removed'>('signalr-participant-removed');
const participantLeftSignalRAction = createAction<MmoaoParticipationAction, 'signalr-participant-left'>('signalr-participant-left');

const requestCreatedSignalRAction = createAction<MmoaoParticipationAction, 'signalr-request-created'>('signalr-request-created');
const requestDeclinedSignalRAction = createAction<MmoaoParticipationAction, 'signalr-request-declined'>('signalr-request-declined');
const requestAcceptedSignalRAction = createAction<MmoaoParticipationAction, 'signalr-request-accepted'>('signalr-request-accepted');
const requestRevokedSignalRAction = createAction<MmoaoParticipationAction, 'signalr-request-revoked'>('signalr-request-revoked');

const invitationCreatedSignalRAction = createAction<MmoaoParticipationAction, 'signalr-invitiation-created'>('signalr-invitiation-created');
const invitationUpdatedSignalRAction = createAction<MmoaoParticipationAction, 'signalr-invitiation-updated'>('signalr-invitiation-updated');
const invitationDeclinedSignalRAction = createAction<MmoaoParticipationAction, 'signalr-invitiation-declined'>('signalr-invitiation-declined');
const invitationAcceptedSignalRAction = createAction<MmoaoParticipationAction, 'signalr-invitiation-accepted'>('signalr-invitiation-accepted');
const invitationRevokedSignalRAction = createAction<MmoaoParticipationAction, 'signalr-invitiation-revoked'>('signalr-invitiation-revoked');

const fileUploadedSignalRAction = createAction<MmoaoFileAction, 'signalr-file-uploaded'>('signalr-file-uploaded');

const botResponseChunkSignalRAction = createAction<MmoaoResponseAction, 'signalr-xb-chunk'>('signalr-xb-chunk');
const botResponseEndSignalRAction = createAction<MmoaoResponseAction, 'signalr-xb-end'>('signalr-xb-end');

function createSignalRConnection() {
  let signalUrl = api.v2.communication.signalr;
  if (localStorage['nodeUrl']) {
    if (localStorage['nodeUrl'].endsWith('/')) {
      signalUrl = (localStorage['nodeUrl'] as string).substr(0, localStorage['nodeUrl'].length - 1) + signalUrl;
    } else {
      signalUrl = localStorage['nodeUrl'] + signalUrl;
    }
  }
  const hubConnBuilder = new HubConnectionBuilder().withAutomaticReconnect({ nextRetryDelayInMilliseconds: () => 5000 }).withUrl(signalUrl);
  if (process.env.NODE_ENV === 'production') {
    hubConnBuilder.configureLogging(LogLevel.Error);
  }
  return hubConnBuilder.build();
}

function createSignalRChannel(hubConn: HubConnection): EventChannel<any> {
  return eventChannel(emit => {
    // message actions
    const handleMessageCreated = (action: MmoaoMessageAction) => {
      emit(messageCreatedSignalRAction(action));
    };
    const handleMessageDeleted = (action: MmoaoMessageAction) => {
      emit(messageDeletedSignalRAction(action));
    };
    const handleMessageUpdated = (action: MmoaoMessageAction) => {
      emit(messageUpdatedSignalRAction(action));
    };
    const handleMessageVoted = (action: MmoaoMessageAction) => {
      emit(messageVotedSignalRAction(action));
    };
    // participant actions
    const handleParticipantRemoved = (action: MmoaoParticipationAction) => {
      emit(participantRemovedSignalRAction(action));
    };
    const handleParticipantLeft = (action: MmoaoParticipationAction) => {
      emit(participantLeftSignalRAction(action));
    };
    // request actions
    const handleRequestCreated = (action: MmoaoParticipationAction) => {
      emit(requestCreatedSignalRAction(action));
    };
    const handleRequestRevoked = (action: MmoaoParticipationAction) => {
      emit(requestRevokedSignalRAction(action));
    };
    const handleRequestAccepted = (action: MmoaoParticipationAction) => {
      emit(requestAcceptedSignalRAction(action));
    };
    const handleRequestDeclined = (action: MmoaoParticipationAction) => {
      emit(requestDeclinedSignalRAction(action));
    };
    // invitation actions
    const handleInvitationCreated = (action: MmoaoParticipationAction) => {
      emit(invitationCreatedSignalRAction(action));
    };
    const handleInvitationUpdated = (action: MmoaoParticipationAction) => {
      emit(invitationUpdatedSignalRAction(action));
    };
    const handleInvitationRevoked = (action: MmoaoParticipationAction) => {
      emit(invitationRevokedSignalRAction(action));
    };
    const handleInvitationAccepted = (action: MmoaoParticipationAction) => {
      emit(invitationAcceptedSignalRAction(action));
    };
    const handleInvitationDeclined = (action: MmoaoParticipationAction) => {
      emit(invitationDeclinedSignalRAction(action));
    };
    // file actions
    const handleFileUploaded = (action: MmoaoFileAction) => {
      emit(fileUploadedSignalRAction(action));
    };

    // XB actions
    const handleXBResponseChunk = (action: MmoaoResponseAction) => {
      emit(botResponseChunkSignalRAction(action));
    };
    const handleXBResponseEnd = (action: MmoaoResponseAction) => {
      emit(botResponseEndSignalRAction(action));
    };

    // special actions
    const handleNotificationsUpdated = () => {
      emit(notificationsUpdatedAction());
    };
    const handleConnected = () => {
      emit(connectionEstablishedSignalRAction());
    };
    hubConn.on('reconnect', async () => {
      await hubConn.stop();
      hubConn.start();
    });
    hubConn.on('connected', handleConnected);
    hubConn.on(MmoaoActionTypes.MESSAGE_CREATED, handleMessageCreated);
    hubConn.on(MmoaoActionTypes.MESSAGE_DELETED, handleMessageDeleted);
    hubConn.on(MmoaoActionTypes.MESSAGE_UPDATED, handleMessageUpdated);
    hubConn.on(MmoaoActionTypes.MESSAGE_VOTED, handleMessageVoted);

    hubConn.on(MmoaoActionTypes.PARTICIPANT_REMOVED, handleParticipantRemoved);
    hubConn.on(MmoaoActionTypes.PARTICIPANT_LEFT, handleParticipantLeft);

    hubConn.on(MmoaoActionTypes.REQUEST_CREATED, handleRequestCreated);
    hubConn.on(MmoaoActionTypes.REQUEST_REVOKED, handleRequestRevoked);
    hubConn.on(MmoaoActionTypes.REQUEST_ACCEPTED, handleRequestAccepted);
    hubConn.on(MmoaoActionTypes.REQUEST_DECLINED, handleRequestDeclined);

    hubConn.on(MmoaoActionTypes.INVITATION_CREATED, handleInvitationCreated);
    hubConn.on(MmoaoActionTypes.INVITATION_UPDATED, handleInvitationUpdated);
    hubConn.on(MmoaoActionTypes.INVITATION_ACCEPTED, handleInvitationAccepted);
    hubConn.on(MmoaoActionTypes.INVITATION_DECLINED, handleInvitationDeclined);
    hubConn.on(MmoaoActionTypes.INVITATION_REVOKED, handleInvitationRevoked);

    hubConn.on(MmoaoActionTypes.FILE_UPLOADED, handleFileUploaded);

    hubConn.on(MmoaoActionTypes.XPERT_BOT_CHUNK, handleXBResponseChunk);
    hubConn.on(MmoaoActionTypes.XPERT_BOT_END, handleXBResponseEnd);

    hubConn.on('notifications-updated', handleNotificationsUpdated);
    hubConn.start();
    return () => {
      hubConn.off('connected', handleConnected);
      hubConn.off(MmoaoActionTypes.MESSAGE_CREATED, handleMessageCreated);
      hubConn.off(MmoaoActionTypes.MESSAGE_DELETED, handleMessageDeleted);
      hubConn.off(MmoaoActionTypes.MESSAGE_UPDATED, handleMessageUpdated);
      hubConn.off(MmoaoActionTypes.MESSAGE_VOTED, handleMessageVoted);

      hubConn.off(MmoaoActionTypes.PARTICIPANT_REMOVED, handleParticipantRemoved);
      hubConn.off(MmoaoActionTypes.PARTICIPANT_LEFT, handleParticipantLeft);

      hubConn.off(MmoaoActionTypes.REQUEST_CREATED, handleRequestCreated);
      hubConn.off(MmoaoActionTypes.REQUEST_REVOKED, handleRequestRevoked);
      hubConn.off(MmoaoActionTypes.REQUEST_ACCEPTED, handleRequestAccepted);
      hubConn.off(MmoaoActionTypes.REQUEST_DECLINED, handleRequestDeclined);

      hubConn.off(MmoaoActionTypes.INVITATION_CREATED, handleInvitationCreated);
      hubConn.off(MmoaoActionTypes.INVITATION_UPDATED, handleInvitationUpdated);
      hubConn.off(MmoaoActionTypes.INVITATION_ACCEPTED, handleInvitationAccepted);
      hubConn.off(MmoaoActionTypes.INVITATION_DECLINED, handleInvitationDeclined);
      hubConn.off(MmoaoActionTypes.INVITATION_REVOKED, handleInvitationRevoked);

      hubConn.off(MmoaoActionTypes.FILE_UPLOADED, handleFileUploaded);

      hubConn.off(MmoaoActionTypes.XPERT_BOT_CHUNK, handleXBResponseChunk);
      hubConn.off(MmoaoActionTypes.XPERT_BOT_END, handleXBResponseEnd);

      hubConn.off('notifications-updated', handleNotificationsUpdated);
      hubConn.stop();
    };
  });
}

function* signalrHandling() {
  try {
    const hubConn = yield call(createSignalRConnection);
    yield fork(outgoingMessages, hubConn);
    const hubChannel = yield call(createSignalRChannel, hubConn);
    try {
      while (true) {
        const action = yield take(hubChannel);
        yield put(action);
      }
    } finally {
      yield hubChannel.close();
    }
  } catch {
    console.error('cannot create signalr connection');
  }
}

function* outgoingMessages(hubConn) {
  try {
    const chan = yield actionChannel([subscribeToSignalR.type, unsubscribeFromSignalR.type]);
    let action;
    while ((action = yield take(chan))) {
      switch (action.type) {
        case subscribeToSignalR.type: {
          hubConn.send('subscribe-to', action.payload.id);
          break;
        }
        case unsubscribeFromSignalR.type: {
          hubConn.send('unsubscribe-from', action.payload.id);
          break;
        }
        case connectionEstablishedSignalRAction.type: {
          const communicationId = yield select(state => state.communication?.id);
          if (communicationId) yield put(subscribeToSignalR({ id: communicationId }));
        }
      }
    }
  } finally {
  }
}

export function* signalrEventHandling() {
  const loggedIn: boolean = yield select((s: DefaultRootState) => s.foundation.auth);
  let signalrSaga: any;
  if (loggedIn) {
    signalrSaga = yield fork(signalrHandling);
  }
  let action;
  while ((action = yield take([userDeauthAction.type, userLogoutSuccessAction.type, userMtoLoginSuccessAction.type, userLoginSuccessAction.type]))) {
    if (signalrSaga) {
      yield cancel(signalrSaga);
    }
    if ([userLoginSuccessAction.type, userMtoLoginSuccessAction.type].includes(action.type)) {
      signalrSaga = yield fork(signalrHandling);
    }
  }
}

export function* signalRWatcher() {
  yield takeLatest(messageCreatedSignalRAction.type, messageCreatedHandler);
  yield takeLatest(messageDeletedSignalRAction.type, messageDeletedHandler);
  yield takeLatest(messageUpdatedSignalRAction.type, messageUpdatedHandler);
  yield takeLatest(messageVotedSignalRAction.type, messageVotedHandler);

  yield takeLatest(participantRemovedSignalRAction.type, participantRemovedHandler);
  yield takeLatest(participantLeftSignalRAction.type, participantLeftHandler);

  yield takeLatest(requestCreatedSignalRAction.type, requestCreatedHandler);
  yield takeLatest(requestDeclinedSignalRAction.type, requestDeclinedHandler);
  yield takeLatest(requestAcceptedSignalRAction.type, requestAcceptedHandler);
  yield takeLatest(requestRevokedSignalRAction.type, requestRevokedHandler);

  yield takeLatest(invitationCreatedSignalRAction.type, invitationCreatedHandler);
  yield takeLatest(invitationUpdatedSignalRAction.type, invitationUpdatedHandler);
  yield takeLatest(invitationDeclinedSignalRAction.type, invitationDeclinedHandler);
  yield takeLatest(invitationAcceptedSignalRAction.type, invitationAcceptedHandler);
  yield takeLatest(invitationRevokedSignalRAction.type, invitationRevokedHandler);

  yield takeLatest(fileUploadedSignalRAction.type, fileUploadedHandler);

  yield takeLatest(botResponseChunkSignalRAction.type, botResponseChunkHandler);
  yield takeLatest(botResponseEndSignalRAction.type, botResponseEndHandler);
}

function* messageCreatedHandler(action: ReturnType<typeof messageCreatedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  if (id === action.payload.parent) {
    yield put(fetchMessageByIdRequest({ new: true, communicationId: action.payload.parent, messageId: action.payload.payload.messageId }));
  }
  yield put(getNumUnreadMessagesByIdRequest({ id: action.payload.parent }));
  yield put(fetchCommunicationByIdRequest({ id: action.payload.parent }));
}

function* messageDeletedHandler(action: ReturnType<typeof messageDeletedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  if (id === action.payload.parent) {
    yield put(fetchMessageByIdRequest({ communicationId: action.payload.parent, messageId: action.payload.payload.messageId }));
  }
  yield put(getNumUnreadMessagesByIdRequest({ id: action.payload.parent }));
}

function* messageUpdatedHandler(action: ReturnType<typeof messageUpdatedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  if (id === action.payload.parent) {
    yield put(fetchMessageByIdRequest({ communicationId: action.payload.parent, messageId: action.payload.payload.messageId }));
  }
  yield put(getNumUnreadMessagesByIdRequest({ id: action.payload.parent }));
}

function* messageVotedHandler(action: ReturnType<typeof messageUpdatedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  if (id === action.payload.parent) {
    yield put(fetchMessageByIdRequest({ communicationId: action.payload.parent, messageId: action.payload.payload.messageId }));
  }
}

function* participantRemovedHandler(action: ReturnType<typeof participantRemovedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  const participants = yield select(state => state.communication?.participants);
  const participation = yield select(state => state.communication?.participation);
  const { userId } = yield select(state => state.foundation.profile);

  const meetingId = yield select(state => state.detail.meetingParticipants.meetingId);

  if (meetingId === action.payload.parent) {
    yield put(fetchMeetingParticipantsRequest());
  }

  if (id === action.payload.parent && participants) {
    yield put(fetchParticipantsRequest({ id }));
  }
  if (participation && action.payload.payload.userId === userId) {
    yield put(fetchParticipationRequest({ id }));
  }
}

function* participantLeftHandler(action: ReturnType<typeof participantLeftSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  const participants = yield select(state => state.communication?.participants);
  const participation = yield select(state => state.communication?.participation);
  const { userId } = yield select(state => state.foundation.profile);

  const meetingId = yield select(state => state.detail.meetingParticipants.meetingId);

  if (meetingId === action.payload.parent) {
    yield put(fetchMeetingParticipantsRequest());
  }

  if (id === action.payload.parent && participants) {
    yield put(fetchParticipantsRequest({ id }));
  }
  if (participation && action.payload.payload.userId === userId) {
    yield put(fetchParticipationRequest({ id }));
  }
}

function* requestCreatedHandler(action: ReturnType<typeof requestCreatedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  const participants = yield select(state => state.communication?.participants);
  const participation = yield select(state => state.communication?.participation);
  const { userId } = yield select(state => state.foundation.profile);
  const currentProfileUserId = yield select(state => state.detail?.item?.userId);

  const meetingId = yield select(state => state.detail.meetingParticipants.meetingId);

  if (meetingId === action.payload.parent) {
    yield put(fetchMeetingParticipantsRequest());
  }

  if (id === action.payload.parent && participants) {
    // teilnehmer für diesen chat waren bereits geladen -> neu laden
    yield put(fetchParticipantsRequest({ id }));
  }

  if (participation && participation.parent === action.payload.parent && action.payload.payload.userId === userId) {
    // einladung für diesen USER
    yield put(fetchParticipationRequest({ id }));
  }

  if (action.payload.payload.userId === userId) {
    // sidebar aktualisieren
    yield put(fetchCommunicationByIdRequest({ id: action.payload.parent }));
  }

  if (
    currentProfileUserId &&
    currentProfileUserId !== userId &&
    (currentProfileUserId === action.payload.createdBy || currentProfileUserId === action.payload.payload.userId)
  ) {
    // user befindet sich auf einem anderen profil
    yield put(userprofileCommunicationRequest({ id: currentProfileUserId }));
  }
}

function* requestDeclinedHandler(action: ReturnType<typeof requestDeclinedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  const participants = yield select(state => state.communication?.participants);
  const participation = yield select(state => state.communication?.participation);
  const { userId } = yield select(state => state.foundation.profile);
  const currentProfileUserId = yield select(state => state.detail?.item?.userId);

  const meetingId = yield select(state => state.detail.meetingParticipants.meetingId);

  if (meetingId === action.payload.parent) {
    yield put(fetchMeetingParticipantsRequest());
  }

  if (id === action.payload.parent && participants) {
    // teilnehmer für diesen chat waren bereits geladen -> neu laden
    yield put(fetchParticipantsRequest({ id }));
  }

  if (participation && participation.parent === action.payload.parent && action.payload.payload.userId === userId) {
    // einladung für diesen USER
    yield put(fetchParticipationRequest({ id }));
  }

  if (action.payload.payload.userId === userId) {
    // sidebar aktualisieren
    yield put(fetchCommunicationByIdRequest({ id: action.payload.parent }));
  }

  if (
    currentProfileUserId &&
    currentProfileUserId !== userId &&
    (currentProfileUserId === action.payload.createdBy || currentProfileUserId === action.payload.payload.userId)
  ) {
    // user befindet sich auf einem anderen profil
    yield put(userprofileCommunicationRequest({ id: currentProfileUserId }));
  }
}

function* requestAcceptedHandler(action: ReturnType<typeof requestAcceptedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  const participants = yield select(state => state.communication?.participants);
  const participation = yield select(state => state.communication?.participation);
  const { userId } = yield select(state => state.foundation.profile);
  const currentProfileUserId = yield select(state => state.detail?.item?.userId);

  const meetingId = yield select(state => state.detail.meetingParticipants.meetingId);

  if (meetingId === action.payload.parent) {
    yield put(fetchMeetingParticipantsRequest());
  }

  if (id === action.payload.parent && participants) {
    // teilnehmer für diesen chat waren bereits geladen -> neu laden
    yield put(fetchParticipantsRequest({ id }));
  }

  if (participation && participation.parent === action.payload.parent && action.payload.payload.userId === userId) {
    // einladung für diesen USER
    yield put(fetchParticipationRequest({ id }));
  }

  if (action.payload.payload.userId === userId) {
    // sidebar aktualisieren
    yield put(fetchCommunicationByIdRequest({ id: action.payload.parent }));
  }

  if (
    currentProfileUserId &&
    currentProfileUserId !== userId &&
    (currentProfileUserId === action.payload.createdBy || currentProfileUserId === action.payload.payload.userId)
  ) {
    // user befindet sich auf einem anderen profil
    yield put(userprofileCommunicationRequest({ id: currentProfileUserId }));
  }
}

function* requestRevokedHandler(action: ReturnType<typeof requestRevokedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  const participants = yield select(state => state.communication?.participants);
  const participation = yield select(state => state.communication?.participation);
  const { userId } = yield select(state => state.foundation.profile);
  const currentProfileUserId = yield select(state => state.detail?.item?.userId);

  const meetingId = yield select(state => state.detail.meetingParticipants.meetingId);

  if (meetingId === action.payload.parent) {
    yield put(fetchMeetingParticipantsRequest());
  }

  if (id === action.payload.parent && participants) {
    // teilnehmer für diesen chat waren bereits geladen -> neu laden
    yield put(fetchParticipantsRequest({ id }));
  }

  if (participation && participation.parent === action.payload.parent && action.payload.payload.userId === userId) {
    // einladung für diesen USER
    yield put(fetchParticipationRequest({ id }));
  }

  if (action.payload.payload.userId === userId) {
    // sidebar aktualisieren
    yield put(fetchCommunicationByIdRequest({ id: action.payload.parent }));
  }

  if (
    currentProfileUserId &&
    currentProfileUserId !== userId &&
    (currentProfileUserId === action.payload.createdBy || currentProfileUserId === action.payload.payload.userId)
  ) {
    // user befindet sich auf einem anderen profil
    yield put(userprofileCommunicationRequest({ id: currentProfileUserId }));
  }
}

function* invitationCreatedHandler(action: ReturnType<typeof invitationCreatedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  const participants = yield select(state => state.communication?.participants);
  const participation = yield select(state => state.communication?.participation);
  const { userId } = yield select(state => state.foundation.profile);
  const currentProfileUserId = yield select(state => state.detail?.item?.userId);

  const meetingId = yield select(state => state.detail.meetingParticipants.meetingId);

  if (meetingId === action.payload.parent) {
    yield put(fetchMeetingParticipantsRequest());
  }

  if (id === action.payload.parent && participants) {
    // teilnehmer für diesen chat waren bereits geladen -> neu laden
    yield put(fetchParticipantsRequest({ id }));
  }

  if (participation && participation.parent === action.payload.parent && action.payload.payload.userId === userId) {
    // einladung für diesen USER
    yield put(fetchParticipationRequest({ id }));
  }

  if (action.payload.payload.userId === userId) {
    // sidebar aktualisieren
    yield put(fetchCommunicationByIdRequest({ id: action.payload.parent }));
  }

  if (
    currentProfileUserId &&
    currentProfileUserId !== userId &&
    (currentProfileUserId === action.payload.createdBy || currentProfileUserId === action.payload.payload.userId)
  ) {
    // user befindet sich auf einem anderen profil
    yield put(userprofileCommunicationRequest({ id: currentProfileUserId }));
  }
}

function* invitationUpdatedHandler(action: ReturnType<typeof invitationUpdatedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  const participants = yield select(state => state.communication?.participants);
  const participation = yield select(state => state.communication?.participation);
  const { userId } = yield select(state => state.foundation.profile);
  const currentProfileUserId = yield select(state => state.detail?.item?.userId);

  const meetingId = yield select(state => state.detail.meetingParticipants.meetingId);

  if (meetingId === action.payload.parent) {
    yield put(fetchMeetingParticipantsRequest());
  }

  if (id === action.payload.parent && participants) {
    // teilnehmer für diesen chat waren bereits geladen -> neu laden
    yield put(fetchParticipantsRequest({ id }));
  }

  if (participation && participation.parent === action.payload.parent && action.payload.payload.userId === userId) {
    // einladung für diesen USER
    yield put(fetchParticipationRequest({ id }));
  }

  if (action.payload.payload.userId === userId) {
    // sidebar aktualisieren
    yield put(fetchCommunicationByIdRequest({ id: action.payload.parent }));
  }

  if (
    currentProfileUserId &&
    currentProfileUserId !== userId &&
    (currentProfileUserId === action.payload.createdBy || currentProfileUserId === action.payload.payload.userId)
  ) {
    // user befindet sich auf einem anderen profil
    yield put(userprofileCommunicationRequest({ id: currentProfileUserId }));
  }
}

function* invitationDeclinedHandler(action: ReturnType<typeof invitationDeclinedSignalRAction>) {
  const communication = yield select(state => state.communication?.communication);
  const id = yield select(state => state.communication?.id);
  const participants = yield select(state => state.communication?.participants);
  const participation = yield select(state => state.communication?.participation);
  const { userId } = yield select(state => state.foundation.profile);
  const currentProfileUserId = yield select(state => state.detail?.item?.userId);

  const meetingId = yield select(state => state.detail.meetingParticipants.meetingId);

  if (meetingId === action.payload.parent) {
    yield put(fetchMeetingParticipantsRequest());
  }

  if (id === action.payload.parent) {
    if (communication && communication.type === CommunicationType.ONETOONE) {
      // route to chats
      yield put(fetchParticipationSuccess({ participation: { ...participation, status: 'declined' } }));
    } else {
      if (participants) {
        // teilnehmer für diesen chat waren bereits geladen -> neu laden
        yield put(fetchParticipantsRequest({ id }));
      }
      if (action.payload.payload.userId === userId) {
        // einladung für diesen USER
        yield put(fetchParticipationRequest({ id }));
      }
    }
    // sidebar aktualisieren
    yield put(fetchCommunicationByIdRequest({ id: action.payload.parent }));
  }

  if (
    currentProfileUserId &&
    currentProfileUserId !== userId &&
    (currentProfileUserId === action.payload.createdBy || currentProfileUserId === action.payload.payload.userId)
  ) {
    // user befindet sich auf einem anderen profil
    yield put(userprofileCommunicationRequest({ id: currentProfileUserId }));
  }
}

function* invitationAcceptedHandler(action: ReturnType<typeof invitationAcceptedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  const participants = yield select(state => state.communication?.participants);
  const participation = yield select(state => state.communication?.participation);
  const type = yield select(state => state.communication?.communication?.type);
  const { userId } = yield select(state => state.foundation.profile);
  const currentProfileUserId = yield select(state => state.detail?.item?.userId);
  const alias = yield select(state => state.detail?.item?.seo?.alias);
  const typeAlias = yield select(state => state.foundation?.activeConfig?.alias);

  const meetingId = yield select(state => state.detail.meetingParticipants.meetingId);

  if (meetingId === action.payload.parent) {
    yield put(fetchMeetingParticipantsRequest());
  }

  if (id === action.payload.parent) {
    if (participants) {
      // teilnehmer für diesen chat waren bereits geladen -> neu laden
      yield put(fetchParticipantsRequest({ id }));
    } else if (type === CommunicationType.ONETOONE) {
      // teilnehmer undefined und onetoone
      yield put(fetchDetailRequest({ type: typeAlias, alias: alias }));
    }
  }

  if (participation && participation.parent === action.payload.parent && action.payload.payload.userId === userId) {
    // einladung für diesen USER
    yield put(fetchParticipationRequest({ id }));
  }

  if (action.payload.payload.userId === userId || type === CommunicationType.ONETOONE) {
    // sidebar aktualisieren
    yield put(fetchCommunicationByIdRequest({ id: action.payload.parent }));
  }

  if (
    currentProfileUserId &&
    currentProfileUserId !== userId &&
    (currentProfileUserId === action.payload.createdBy || currentProfileUserId === action.payload.payload.userId)
  ) {
    // user befindet sich auf einem anderen profil
    yield put(userprofileCommunicationRequest({ id: currentProfileUserId }));
  }
}

function* invitationRevokedHandler(action: ReturnType<typeof invitationRevokedSignalRAction>) {
  const id = yield select(state => state.communication?.id);
  const participants = yield select(state => state.communication?.participants);
  const participation = yield select(state => state.communication?.participation);
  const { userId } = yield select(state => state.foundation.profile);
  const currentProfileUserId = yield select(state => state.detail?.item?.userId);

  const meetingId = yield select(state => state.detail.meetingParticipants.meetingId);

  if (meetingId === action.payload.parent) {
    yield put(fetchMeetingParticipantsRequest());
  }

  if (id === action.payload.parent && participants) {
    // teilnehmer für diesen chat waren bereits geladen -> neu laden
    yield put(fetchParticipantsRequest({ id }));
  }

  if (participation && participation.parent === action.payload.parent && action.payload.payload.userId === userId) {
    // einladung für diesen USER
    yield put(fetchParticipationRequest({ id }));
  }

  if (action.payload.payload.userId === userId) {
    // sidebar aktualisieren
    yield put(fetchCommunicationByIdRequest({ id: action.payload.parent }));
  }

  if (
    currentProfileUserId &&
    currentProfileUserId !== userId &&
    (currentProfileUserId === action.payload.createdBy || currentProfileUserId === action.payload.payload.userId)
  ) {
    // user befindet sich auf einem anderen profil
    yield put(userprofileCommunicationRequest({ id: currentProfileUserId }));
  }
}

function* fileUploadedHandler(action: ReturnType<typeof fileUploadedSignalRAction>) {
  yield put(fetchCommunicationByIdRequest({ id: action.payload.parent }));
}

function* botResponseChunkHandler(action: ReturnType<typeof botResponseChunkSignalRAction>) {
  yield put(botResponseChunkAction({ chunk: action.payload.payload.response, timestamp: action.payload.timestamp }));
}

function* botResponseEndHandler(action: ReturnType<typeof botResponseEndSignalRAction>) {
  yield put(botResponseEndAction({ response: action.payload.payload.response }));
}

import { Bx } from '@curry-group/mui-curcuma';
import React, { useEffect, useState } from 'react';
import { PageElementsResolve } from '../page-element';
import { IContainerConfig } from '../../model/configuration';
import { useConfiguration } from '../../data/sagas/foundation';
import { DashboardFooter } from '../dashboard-footer';
import { FormMagazine } from '../form/compositions/FormMagazine';
import { useDispatch, useSelector } from 'react-redux';
import { proposeThemeAction } from '../../data/actions/mail';
import { FramedContent } from '../framed-content';
import { setNotificationParentReadRequestAction } from '../../data/actions/notifications';
import { IMemoryListItem, ThingTypes } from '../../model/ryve/Thing';

export type TDashboardViewType = 'dashboard' | 'contribute';

export const DashboardContent: React.FC<{
  config?: IContainerConfig;
  view: TDashboardViewType;
  setView: (view: TDashboardViewType) => void;
  memoryList?: Array<IMemoryListItem>;
}> = ({
  config,
  view,
  setView,
  memoryList
}) => {
  const [formData, setFormData] = useState({ theme: '', benefit: '' });
  const dispatch = useDispatch();

  const memoryIds = memoryList?.map(m => m.typeId === ThingTypes.News ? (m.externalId ?? '') : m.elementId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [view]);

  useEffect(() => {
    if (config?.alias && ['magazine', 'forum'].includes(config.alias) && view === 'dashboard') {
      dispatch(setNotificationParentReadRequestAction({ alias: config.alias }));
    }
  }, [dispatch, config, view]);

  return (
    <FramedContent>
      {view === 'dashboard' && (
        <Bx>
          {config && <PageElementsResolve
                        prefix={config.alias}
                        pageElements={config.pageElements}
                        memoryList={config.dashboardMemoryLists || false}
                        memoryIds={memoryIds}
                      />}
          {config && config.footer && (
            <DashboardFooter
              title="Mitmachen!?"
              btnCaption="Thema einreichen"
              btnClick={() => {
                setFormData({ theme: '', benefit: '' });
                setView('contribute');
              }}
            />
          )}
        </Bx>
      )}
      {view === 'contribute' && (
        <FormMagazine
          formData={formData}
          headline={'Mitmachen.'}
          subtitle={'Sie haben Interesse, einen Beitrag für das MTO-Magazin zu verfassen?'}
          onChange={formData => {
            setFormData(formData);
          }}
          submit={() => {
            dispatch(proposeThemeAction(formData));
          }}
          close={() => {
            setView('dashboard');
          }}
        />
      )}
    </FramedContent>
  );
};

export const ConnectedDashboardContent: React.FC<{
  alias: string;
  view: TDashboardViewType;
  setView: (view: TDashboardViewType) => void;
}> = ({
  alias,
  view,
  setView
}) => {
  const config = useConfiguration(alias);
  const memoryList = useSelector(state => state.foundation?.profile?.memoryList);
  return <DashboardContent config={config} view={view} setView={setView} memoryList={memoryList} />;
};

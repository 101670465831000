import { createAction } from '@reduxjs/toolkit';

export const fetchFilterRequestAction = createAction<{ alias: string }, 'FETCH_FILTER_REQUEST'>('FETCH_FILTER_REQUEST');
export const fetchFilterErrorAction = createAction<{ message: string }, 'FETCH_FILTER_ERROR'>('FETCH_FILTER_ERROR');
export const fetchFilterSuccessAction = createAction<{ filterItem: any }, 'FETCH_FILTER_SUCCESS'>('FETCH_FILTER_SUCCESS');

export const showFilterAction = createAction<{}, 'SHOW_FILTER'>('SHOW_FILTER');
export const hideFilterAction = createAction<{}, 'HIDE_FILTER'>('HIDE_FILTER');

export const resetSearchObjectAction =
  createAction<{ keepFilter?: boolean; keepExcludedIds?: boolean; keepFilterAlias?: boolean; keepQuery?: boolean }, 'RESET_SEARCHOBJECT'>('RESET_SEARCHOBJECT');

export const changeQueryContainedIdsAction = createAction<{ ids: string[] }, 'CHANGE_QUERY_CONTAINED_IDS'>('CHANGE_QUERY_CONTAINED_IDS');
export const changeQueryTermAction = createAction<{ term: string }, 'CHANGE_QUERYTERM'>('CHANGE_QUERYTERM');
export const changeQuerySortAction = createAction<{ sortId: string }, 'CHANGE_QUERYSORT'>('CHANGE_QUERYSORT');
export const changeQuerySearchTypeAction = createAction<{ searchTypeId: 'combined'|'combined2'|'ontology'|'elastic' }, 'CHANGE_QUERY_SEARCH_TYPE'>('CHANGE_QUERY_SEARCH_TYPE');
export const changeQueryCategoriesAction = createAction<{ category: any; noToggle: boolean; parentCategoryId: string }, 'CHANGE_QUERYCATEGORIES'>('CHANGE_QUERYCATEGORIES');
export const changeQueryCategoryIdsAction = createAction<{ categoryIds: string[] }, 'CHANGE_QUERY_CATEGORY_IDS'>('CHANGE_QUERY_CATEGORY_IDS');
export const changeQueryCategiesExactAction = createAction<{ exact: boolean }, 'CHANGE_QUERY_CATEGORIES_EXACT'>('CHANGE_QUERY_CATEGORIES_EXACT');
export const changeQueryTypeAction = createAction<{ thingType: string }, 'CHANGE_QUERYTYPE'>('CHANGE_QUERYTYPE');
export const changeQueryDestinationAction = createAction<{ destinationAlias: string }, 'CHANGE_QUERYDESTINATION'>('CHANGE_QUERYDESTINATION');
export const changeQueryDateRangeFromAction = createAction<{ fromDate: any }, 'CHANGE_QUERYDATERANGE_FROM'>('CHANGE_QUERYDATERANGE_FROM');
export const changeQueryDateRangeToAction = createAction<{ toDate: any }, 'CHANGE_QUERYDATERANGE_TO'>('CHANGE_QUERYDATERANGE_TO');
export const changeQueryExcludedIdsAction = createAction<{ excludedIDs: string[] }, 'CHANGE_QUERY_EXCLUDED_IDS'>('CHANGE_QUERY_EXCLUDED_IDS');

export const copySearchParamsToSearchAction =
  createAction<{ searchObject: any; lastUpdatedFilter: Array<string> }, 'COPY_SEARCH_PARAMS_TO_SEARCH'>('COPY_SEARCH_PARAMS_TO_SEARCH');

export const setFrontendFilterAction = createAction<{}, 'SET_FRONTEND_FILTER'>('SET_FRONTEND_FILTER');
export const setSelectedSearchAction = createAction<{ searchAlias: string; flowSearch?: boolean }, 'SET_SELECTED_SEARCH'>('SET_SELECTED_SEARCH');

export const setBotSearchAction = createAction<{ botSearch?: boolean }, 'SET_BOT_SEARCH'>('SET_BOT_SEARCH');

import { Wizard } from '@curry-group/react-wizard';
import React, { useEffect, useState } from 'react';
import { ConnectedFlowContent } from '../../components/flow/content';
import { ConnectedFlowFooter } from '../../components/flow/footer';
import { AppFrameContent, AppFrameNavbar } from '../../components/frame';
import { AppFrameFooter } from '../../components/frame/footer';
import { AppFrameHeader } from '../../components/frame/header';
import { ConnectedFlowHeader } from '../../components/flow/header';
import { useFlow } from '../../data/sagas/flow';
import { flowSteps } from '../../helper';
import { setFlowAliasAction } from '../../data/actions/flow';
import { useDispatch, useSelector } from 'react-redux';
import { useConfiguration } from '../../data/sagas/foundation';
import { IContainerConfig } from '../../model/configuration';
import { useBreakpoints } from '@curry-group/mui-curcuma';
import { ConnectedNavBar, ConnectedNavBarDrawer } from '../navbar';
import { useIsMobile } from '../../helper/hooks/isMobile';
import _ from 'lodash';

export interface IFlowComponentFrame {
  alias: string;
  flow: any;
  currentStep: number;
  close?: () => void;
  config?: IContainerConfig;
}

export const FlowComponentFrame: React.FC<IFlowComponentFrame> = ({ config, alias, flow, currentStep, close }) => {
  const isMobile = useIsMobile();
  const isLargeDesktop = !!useBreakpoints().lgUp;
  const [nextStepDisabled, setNextStepDisabled] = useState(true);
  const flowStepsByAlias = flowSteps[alias];
  let ignoredSteps = Array<number>();
  if (flow?.currentType || flow?.currentAlias === 'register' || flow?.currentAlias === 'createmeeting' || flow?.currentAlias === 'add-participants') {
    for (let i = 0; i < flowStepsByAlias.length; i++) {
      const step = flowStepsByAlias[i];
      if (step.type && step.type !== flow?.currentType) {
        ignoredSteps.push(i);
      }
      if (!!step.excludeIfOptionNotSet && step.excludeIfOptionNotSet.length) {
        if (flow.flowOptions && flow.flowOptions.length > 0) {
          if (_.intersection(step.excludeIfOptionNotSet, flow.flowOptions).length !== step.excludeIfOptionNotSet.length) {
            ignoredSteps.push(i);
          }
        } else {
          if (ignoredSteps.indexOf(i) === -1) {
            ignoredSteps.push(i);
          }
        }
      }
    }
  }
  return (
    <Wizard step={currentStep} stepCount={flowStepsByAlias.length} ignoredSteps={ignoredSteps}>
      {alias === 'flowtermsrules' && <>
        <ConnectedNavBarDrawer />
        {!isMobile && (
          <AppFrameNavbar>
            <ConnectedNavBar small={!isLargeDesktop} showHamburger={!isLargeDesktop} />
          </AppFrameNavbar>
        )}
      </>}
      <AppFrameHeader>
        <ConnectedFlowHeader config={config} alias={alias} flowSteps={flowStepsByAlias} close={close} ignoredSteps={ignoredSteps} />
      </AppFrameHeader>
      <AppFrameContent>
        <ConnectedFlowContent alias={alias} flowSteps={flowStepsByAlias} close={close} ignoredSteps={ignoredSteps} setNextStepDisabled={setNextStepDisabled} />
      </AppFrameContent>
      <AppFrameFooter>
        <ConnectedFlowFooter alias={alias} flowSteps={flowStepsByAlias} nextStepDisabled={nextStepDisabled} />
      </AppFrameFooter>
    </Wizard>
  );
};

export const ConnectedFlowComponentFrame: React.FC<{ alias: string; close?: () => void }> = ({ alias, close }) => {
  const config = useConfiguration(alias);
  const dispatch = useDispatch();
  const currentStep = useSelector(state => state.flow.currentStep);
  const currentAlias = useSelector(state => state.flow.currentAlias);

  if (!!currentAlias && currentAlias !== alias) {
    alias = currentAlias;
  }

  useEffect(() => {
    dispatch(setFlowAliasAction({ alias, flowSteps: flowSteps[alias] }));
  }, [dispatch, alias]);
  const flow = useFlow();

  return (
    <FlowComponentFrame
      config={config}
      alias={alias}
      flow={flow}
      currentStep={currentStep}
      close={close}
    />
  );
};

import { Bx, Typo } from '@curry-group/mui-curcuma';
import { Grid } from '@material-ui/core';
import React from 'react';
import { allowedMimeTypes } from '../../../helper/validator';
import { FormDropZone } from '../controls/dropzone';
import { FormInput } from '../controls/input';
import { FormRTE } from '../controls/rte';

interface IFormQnA {
  formData: any;
  headline?: string;
  subtitle?: string;
  onChange: (value: any) => void;
}

export const FormQnA: React.FC<IFormQnA> = ({ formData, headline, subtitle, onChange }) => {
  return (
    <React.Fragment>
      {headline && (
        <Bx mb={1}>
          <Typo variant="h1" component="h1">
            {headline}
          </Typo>
        </Bx>
      )}
      {subtitle && <Typo variant="subtitle1">{subtitle}</Typo>}
      <Bx my={headline || subtitle ? 8 : 0}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={12}>
            <FormInput
              value={formData?.itemData?.title}
              label={'Frage'}
              placeholder={'Frage eingeben'}
              minLength={5}
              maxLength={150}
              onChange={val => {
                onChange({ ...formData, itemData: { ...formData?.itemData, title: val } });
              }}
              lazy
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormRTE
              value={formData?.itemData?.content}
              label={'Hintergrund Ihrer Frage'}
              placeholder={'Bitte beschreiben Sie Ihre Frage...'}
              mandatory={false}
              minLength={0}
              maxLength={1000}
              onChange={val => {
                onChange({ ...formData, itemData: { ...formData?.itemData, content: val } });
              }}
              lazy
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormDropZone
              value={formData?.attachments ? formData?.attachments : []}
              label={'Anhänge'}
              placeholder={'Hochladen oder Datei(en) hier ablegen'}
              mandatory={false}
              accept={allowedMimeTypes}
              onChange={val => {
                onChange({ ...formData, attachments: val });
              }}
              multiple
            />
          </Grid>
        </Grid>
      </Bx>
    </React.Fragment>
  );
};

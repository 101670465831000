import { Btn, Bx, Ctn, IconBtn, Typo, Link } from '@curry-group/mui-curcuma';
import { useTheme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFlow } from '../../../data/sagas/flow';
import { setFlowDataAction, setFlowOptionAction, setFlowStepAction } from '../../../data/actions/flow';
import { Collapse, Fade, Grid } from '@material-ui/core';
import { faLongArrowLeft, faSlidersV, faUsersMedical, faUsersSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFilterConfig } from '../../../data/sagas/filter';
import { HeaderFilter } from '../../filter';
import { ISearchRequestBody } from '../../../model/search/SearchRequest';
import {
  changeQueryCategoriesAction,
  changeQueryExcludedIdsAction,
  changeQuerySortAction,
  changeQueryTermAction,
  changeQueryTypeAction,
  fetchFilterRequestAction,
  resetSearchObjectAction,
  setBotSearchAction,
  setSelectedSearchAction
} from '../../../data/actions/filter';
import { clearSearchEntriesAction, fetchFirstPageSearchEntriesRequestAction } from '../../../data/actions/search';
import _ from 'lodash';
import { IContainerConfig } from '../../../model/configuration';
import { fetchCommunicationsRequest } from '../../../data/reducer/communication';
import { useIsMobile } from '../../../helper/hooks/isMobile';
import { HeadBar } from '../../head-bar';
import { MobileHeadBar } from '../../mobile-head-bar';
import { HeadSearchInput } from '../../head-search-input';
import { MTOLogo } from '../../../helper';
import { IParticipantResolvedModel } from '../../../model/communication/Participant';

export interface IFlowHeaderProps {
  flow: any;
  flowSteps: any[];
  auth?: boolean;
  logoSrc: string;
  logoAlt: string;
  partnerLogoSrc: string;
  partnerLogoMobileSrc: string;
  partnerLogoAlt: string;
  currentStep: any;
  filterItem: any;
  filterAlias: string;
  filterWorking: boolean;
  searchObject?: ISearchRequestBody;
  searchResult?: any;
  queryTerm?: string;
  searchWorking?: boolean;
  searchError?: string;
  allSelected?: boolean;
  setFlowStep: (step: number) => void;
  setFlowData: (flowData: any) => void;
  setFlowOption: (option: string, remove?: boolean) => void;
  changeQueryTerm?: (term: string) => void;
  changeQueryTypeAction?: (thingType: string) => void;
  changeQueryCategoriesAction?: (category: any, noToggle: boolean, parentCategoryId: string) => void;
  changeQuerySortAction: (sortId: string) => void;
  changeQuerySearchTypeAction?: (searchTypeId: string) => void;
  resetSearchObject?: () => void;
  clearSearchEntries?: () => void;
  fetchFirstPageSearchEntries?: () => void;
  close?: () => void;
  resultHits: any;
  participants: IParticipantResolvedModel[],
}

export const FlowHeader: React.FC<IFlowHeaderProps> = ({
  flow,
  auth = false,
  partnerLogoSrc,
  partnerLogoMobileSrc,
  partnerLogoAlt,
  currentStep,
  filterItem,
  filterAlias,
  filterWorking,
  searchResult,
  queryTerm,
  searchWorking,
  searchError,
  searchObject,
  allSelected,
  resultHits,
  setFlowStep,
  setFlowData,
  setFlowOption,
  changeQueryTerm,
  changeQueryTypeAction,
  changeQueryCategoriesAction,
  changeQuerySortAction,
  fetchFirstPageSearchEntries,
  resetSearchObject,
  clearSearchEntries,
  close,
  participants
}) => {
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [updateHandler, setHandler] = useState(0);
  const [queryTermShow, setQueryTermShow] = useState<string>(queryTerm || '');
  const history = useHistory();
  const currentComponent = currentStep?.component;
  const searchActive = currentComponent === 'createGroupUserSelect' || currentComponent === 'registerEmployerSearch' || currentComponent === 'shareLocationSelect';
  useEffect(() => {
    if (searchActive) {
      if (!searchResult && !searchError && !searchWorking && !filterWorking && currentStep?.searchAlias === filterAlias) {
        fetchFirstPageSearchEntries?.();
      }
      if (currentComponent === 'shareLocationSelect') {
        setFilterVisible(true);
      }
    } else {
      setFilterVisible(false);
    }
  }, [currentComponent, currentStep?.searchAlias, fetchFirstPageSearchEntries, filterAlias, filterWorking, queryTerm, searchError, searchActive, searchResult, searchWorking]);
  return (
    <>
      <HeadBar
        transparent={false}
        backButtonLink={currentComponent !== 'registerNewTerms'
          ? () => {
            if (!flow?.currentStep) {
              if (close) {
                close?.();
              } else {
                history.goBack();
              }
            } else {
              setFlowStep(flow?.currentStep - 1);
              if (searchActive) {
                resetSearchObject?.();
                clearSearchEntries?.();
              }
            }}
          : undefined
        }
      >
        {searchActive && (
          <Fade in={true}>
            <Bx flexShrink={1} flexGrow={1}>
              <Bx height="100%" display="flex" alignItems="center">
                <Ctn>
                  <Bx display="flex">
                    <Bx flexGrow={1} flexShrink={1} flexBasis="auto">
                      <HeadSearchInput
                        input={{
                          id: 'head-search',
                          value: queryTermShow,
                          placeholder: filterItem?.content?.title || 'Suchen',
                          onChange: e => {
                            setQueryTermShow(e.target.value);
                            window.clearTimeout(updateHandler);
                            setHandler(
                              window.setTimeout(() => {
                                changeQueryTerm?.(e.target.value);
                                if (e.target.value !== undefined && (e.target.value.length > 1 || e.target.value.length === 0)) {
                                  fetchFirstPageSearchEntries?.();
                                }
                              }, 200)
                            );
                          }
                        }}
                        inputLabel={{ htmlFor: 'head-search' }}
                        clearOnClick={() => {
                          setQueryTermShow('');
                          resetSearchObject?.();
                          fetchFirstPageSearchEntries?.();
                        }}
                      />
                    </Bx>
                    {flow?.currentAlias === 'createmeeting' && (
                      <Bx alignSelf="flex-end" textAlign="right" mr={1}>
                        <Btn
                          size="large"
                          color="success"
                          variant={allSelected ? 'outlined' : 'contained'}
                          onClick={e => {
                            if (allSelected) {
                              setFlowOption('usersInvited', true);
                            } else {
                              setFlowOption('usersInvited', false);
                            }
                            setFlowData({
                              ...flow?.flowData,
                              selectedUsers: allSelected ? [] : participants.map(user => user?.user),
                              selectedUserItems: allSelected ? [] : resultHits
                            });
                          }}
                        >
                          <Typo variant="inherit" noWrap>
                            {allSelected ? 'Alle Mitglieder abwählen' : 'Alle Mitglieder einladen'}
                          </Typo>
                        </Btn>
                      </Bx>
                    )}
                    {!filterItem?.content?.autoSuggestActive && (
                      <Bx>
                        <Btn
                          size="large"
                          startIcon={<FontAwesomeIcon icon={faSlidersV} />}
                          onClick={() => {
                            if (filterVisible) {
                              setFilterVisible(false);
                            } else {
                              setFilterVisible(true);
                            }
                          }}
                          color={filterVisible ? 'primary' : 'default'}
                          variant={filterVisible ? 'contained' : 'outlined'}
                        >
                          Filtern
                        </Btn>
                      </Bx>
                    )}
                  </Bx>
                </Ctn>
              </Bx>
            </Bx>
          </Fade>
        )}
        {!auth && <Bx ml={'auto'}>
          <Bx mt={0} display="flex" justifyContent={{ xs: 'center', sm: 'flex-end' }}>
            <Bx mr={2} position="relative" top={-5}>
              <Typo variant="body2" component="span" style={{ fontSize: 12, textTransform: 'uppercase' }}>
                Eine Initiative vom
              </Typo>
            </Bx>
            <Bx>
              <img src={partnerLogoSrc} alt={partnerLogoAlt} style={{ maxHeight: '50px' }}/>
            </Bx>
          </Bx>
        </Bx>}
      </HeadBar>
      {!filterItem?.content?.autoSuggestActive && (
        <HeaderFilter
          filterItem={filterItem}
          filterAlias={filterAlias}
          filtersVisible={filterVisible}
          filterWorking={filterWorking}
          searchResult={searchResult}
          searchObject={searchObject}
          selectedSearch={currentStep?.searchAlias}
          handleClick={() => {}}
          resetSearchFilter={() => {
            resetSearchObject?.();
            fetchFirstPageSearchEntries?.();
          }}
          onTypeChange={(thingType: string) => {
            changeQueryTypeAction?.(thingType);
            fetchFirstPageSearchEntries?.();
          }}
          onCategoryChange={(category: string, noToggle: boolean, parentCategoryId: string) => {
            changeQueryCategoriesAction?.(category, noToggle, parentCategoryId);
            fetchFirstPageSearchEntries?.();
          }}
          onSortChange={(sortId: string) => {
            changeQuerySortAction?.(sortId);
            fetchFirstPageSearchEntries?.();
          }}
        />
      )}
    </>
  );
};

export const FlowMobileHeader: React.FC<IFlowHeaderProps> = ({
  flow,
  flowSteps,
  auth,
  logoAlt,
  logoSrc,
  partnerLogoAlt,
  partnerLogoSrc,
  partnerLogoMobileSrc,
  currentStep,
  filterItem,
  filterAlias,
  filterWorking,
  searchResult,
  queryTerm,
  searchWorking,
  searchError,
  searchObject,
  allSelected,
  resultHits,
  setFlowStep,
  setFlowData,
  setFlowOption,
  changeQueryTerm,
  changeQueryTypeAction,
  changeQueryCategoriesAction,
  changeQuerySortAction,
  // showFilterAction,
  // hideFilterAction,
  fetchFirstPageSearchEntries,
  resetSearchObject,
  clearSearchEntries,
  close,
  participants
}) => {
  const theme = useTheme();
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [updateHandler, setHandler] = useState(0);
  const [queryTermShow, setQueryTermShow] = useState<string>(queryTerm || '');
  const history = useHistory();
  const currentComponent = currentStep?.component;
  const searchActive = currentComponent === 'createGroupUserSelect' || currentComponent === 'registerEmployerSearch' || currentComponent === 'shareLocationSelect';
  useEffect(() => {
    if (searchActive) {
      if (!searchResult && !searchError && !searchWorking && !filterWorking && currentStep?.searchAlias === filterAlias) {
        fetchFirstPageSearchEntries?.();
      }
      if (currentComponent === 'shareLocationSelect') {
        setFilterVisible(true);
      }
    } else {
      setFilterVisible(false);
    }
  }, [currentComponent, currentStep?.searchAlias, fetchFirstPageSearchEntries, filterAlias, filterWorking, queryTerm, searchError, searchActive, searchResult, searchWorking]);
  return (
    <MobileHeadBar style={{ minHeight: !auth ? 60 : 64 }} boxShadow={{ xs: !auth ? 3 : 0, md: !auth ? 3 : 0 }}>
      <Bx display="flex" height="100%" minHeight="inherit" alignItems="center" py={!auth ? 0 : 1}>
        <Grid item xs={!auth ? 1 : 3}>
          <Bx display="flex" pr={1} alignItems="center" justifyContent="flex-start" height="100%">
            <IconBtn
              size="medium"
              onClick={() => {
                if (!flow?.currentStep) {
                  if (close) {
                    close?.();
                  } else {
                    history.goBack();
                  }
                } else {
                  setFlowStep(flow?.currentStep - 1);
                  if (searchActive) {
                    resetSearchObject?.();
                    clearSearchEntries?.();
                  }
                }
              }}
            >
              <FontAwesomeIcon icon={faLongArrowLeft} />
            </IconBtn>
          </Bx>
        </Grid>
        <Grid item xs={!auth ? 8 : 6}>
          <Bx pl={2} display="flex" alignItems="center" justifyContent="flex-start" height="100%">
            <Link to="/" onClick={e => { window.scrollTo({ top: 0, behavior: 'smooth' }); }} >
              <img src={logoSrc} alt={logoAlt} style={{ height: 28, display: 'block' }} />
            </Link>
          </Bx>
        </Grid>
        <Grid item xs={3}>
          {!auth && <Bx display="flex" flexDirection="row" justifyContent="flex-end">
            <Typo variant="body2" component="span" style={{ fontSize: 8, lineHeight: 1, textTransform: 'uppercase' }}>
              Eine Initiative vom
            </Typo>
            <Bx pl={.5}>
              <img src={partnerLogoMobileSrc} alt={partnerLogoAlt} style={{ height: 42 }}/>
            </Bx>
          </Bx>}
        </Grid>
      </Bx>
      <Collapse in={searchActive}>
        <Bx display="flex" height="100%" minHeight={64} alignItems="center" py={1}>
          <Bx display="flex" width="100%" zIndex={2}>
            <Bx px={1} flexGrow={1} flexShrink={1} flexBasis="auto">
              <HeadSearchInput
                input={{
                  id: 'head-search',
                  value: queryTermShow,
                  placeholder: filterItem?.content?.title || 'Suchen',
                  onChange: e => {
                    setQueryTermShow(e.target.value);
                    window.clearTimeout(updateHandler);
                    setHandler(
                      window.setTimeout(() => {
                        changeQueryTerm?.(e.target.value);
                        if (e.target.value !== undefined && (e.target.value.length > 1 || e.target.value.length === 0)) {
                          fetchFirstPageSearchEntries?.();
                        }
                      }, 200)
                    );
                  }
                }}
                inputLabel={{ htmlFor: 'head-search' }}
                clearOnClick={() => {
                  setQueryTermShow('');
                  resetSearchObject?.();
                  fetchFirstPageSearchEntries?.();
                }}
              />
            </Bx>
            <Bx ml={1}>
              {flow?.currentAlias === 'createmeeting' && (
                <Bx zIndex={2}>
                  <Bx pl={1}>
                    <IconBtn
                      size="small"
                      onClick={e => {
                        if (allSelected) {
                          setFlowOption('usersInvited', true);
                        } else {
                          setFlowOption('usersInvited', false);
                        }
                        setFlowData({
                          ...flow?.flowData,
                          selectedUsers: allSelected ? [] : participants.map(user => user?.user),
                          selectedUserItems: allSelected ? [] : resultHits
                        });
                      }}
                      color="success"
                      variant={allSelected ? 'outlined' : 'contained'}
                      style={{ borderRadius: theme.shape.borderRadius }}
                    >
                      <FontAwesomeIcon icon={allSelected ? faUsersSlash : faUsersMedical} />
                    </IconBtn>
                  </Bx>
                </Bx>
              )}
            </Bx>
            <Bx mr={1}>
              {!filterItem?.content?.autoSuggestActive && (
                <Bx zIndex={2}>
                  <Bx px={1}>
                    <IconBtn
                      size="small"
                      onClick={() => {
                        if (filterVisible) setFilterVisible(false);
                        else setFilterVisible(true);
                      }}
                      color={filterVisible ? 'primary' : 'default'}
                      variant={filterVisible ? 'contained' : 'outlined'}
                      style={{ borderRadius: theme.shape.borderRadius }}
                    >
                      <FontAwesomeIcon icon={faSlidersV} />
                    </IconBtn>
                  </Bx>
                </Bx>
              )}
            </Bx>
          </Bx>
        </Bx>
      </Collapse>
      {!filterItem?.content?.autoSuggestActive && (
        <HeaderFilter
          smallVariant={true}
          filterItem={filterItem}
          filterAlias={filterAlias}
          filtersVisible={filterVisible}
          filterWorking={filterWorking}
          searchResult={searchResult}
          searchObject={searchObject}
          selectedSearch={currentStep?.searchAlias}
          handleClick={() => {}}
          resetSearchFilter={() => {
            resetSearchObject?.();
            fetchFirstPageSearchEntries?.();
          }}
          onTypeChange={(thingType: string) => {
            changeQueryTypeAction?.(thingType);
            fetchFirstPageSearchEntries?.();
          }}
          onCategoryChange={(category: string, noToggle: boolean, parentCategoryId: string) => {
            changeQueryCategoriesAction?.(category, noToggle, parentCategoryId);
            fetchFirstPageSearchEntries?.();
          }}
          onSortChange={(sortId: string) => {
            changeQuerySortAction?.(sortId);
            fetchFirstPageSearchEntries?.();
          }}
        />
      )}
    </MobileHeadBar>
  );
};

export interface IConnectedFlowHeader {
  alias: string;
  flowSteps: any[];
  ignoredSteps: Array<number>;
  close?: () => void;
  config?: IContainerConfig;
}

export const ConnectedFlowHeader: React.FC<IConnectedFlowHeader> = ({ config, alias, flowSteps, ignoredSteps, close }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (config) dispatch(fetchCommunicationsRequest({ types: config.sidebarTypes }));
    return () => {
      if (alias === 'share') {
        return;
      }
      dispatch(setBotSearchAction({ botSearch: false }));
      dispatch(resetSearchObjectAction?.({}));
      dispatch(clearSearchEntriesAction({}));
      dispatch(fetchFilterRequestAction({ alias }));
      dispatch(setSelectedSearchAction({ searchAlias: alias, flowSearch: false }));
    };
  }, [dispatch, config, alias]);
  const flow = useFlow();
  const currentStep = flowSteps[flow?.currentStep];
  useFilterConfig(currentStep?.searchAlias, true, true);
  const isMobile = useIsMobile();
  const filterItem = useSelector(state => state.filter?.filterItem);
  const searchObject = useSelector(state => state.filter?.searchObject);
  const filterAlias = useSelector(state => state.filter?.filterAlias);
  const filterWorking = useSelector(state => state.filter?.filterWorking);
  const queryTerm = useSelector(state => state.filter?.searchObject?.queryTerm);
  const excludedIDs = useSelector(state => state.filter?.searchObject?.excludedIDs);
  const resultHits = useSelector(state => state.search?.result?.hits?.hits);
  const result = useSelector(state => state.search.result);
  const working = useSelector(state => state.search.working);
  const error = useSelector(state => state.search.error);
  const dispatcher = {
    setFlowStepAction: (step: number) => dispatch(setFlowStepAction?.({ step })),
    setFlowDataAction: (flowData: any) => dispatch(setFlowDataAction?.({ alias, flowData })),
    changeQueryTermAction: (term: string) => dispatch(changeQueryTermAction?.({ term })),
    changeQueryTypeAction: (thingType: string) => dispatch(changeQueryTypeAction?.({ thingType })),
    changeQueryCategoriesAction: (category: string, noToggle: boolean, parentCategoryId: string) =>
      dispatch(changeQueryCategoriesAction?.({ category, noToggle, parentCategoryId })),
    changeQuerySortAction: (sortId: string) => dispatch(changeQuerySortAction?.({ sortId })),
    resetSearchObjectAction: () => dispatch(resetSearchObjectAction?.({ keepFilterAlias: true })),
    clearSearchEntriesAction: () => dispatch(clearSearchEntriesAction({})),
    fetchFirstPageSearchEntriesAction: () => dispatch(fetchFirstPageSearchEntriesRequestAction?.({})),
    setFlowOptionAction: (option: string, remove?: boolean) => dispatch(setFlowOptionAction?.({ option, remove }))
  };
  const currentUserProfileId = useSelector(state => state.foundation?.profile?.profileId);
  const ownUserId = useSelector(state => state.foundation?.profile?.userId) || '';
  const communications = useSelector(state => state.communication?.communications);
  const participants: IParticipantResolvedModel[] = (useSelector(state => state.communication?.participants) || []).filter(u => u.user !== ownUserId && (u.status === 'accepted' || u.status === 'pending'));
  const auth = useSelector(state => state.foundation.auth);
  
  if (currentStep) {
    if (currentStep.component === 'createGroupUserSelect') {
      if (flow.currentAlias === 'chat') {
        const newExcludedIDs = currentUserProfileId ? [currentUserProfileId] : [];
        if (communications) {
          for (const comm of communications) {
            const partnerId = comm?.content?.partner?._id;
            if (partnerId) {
              newExcludedIDs.push(partnerId);
            }
          }
        }
        if (!excludedIDs || !_.isEqual(excludedIDs, newExcludedIDs)) {
          dispatch(changeQueryExcludedIdsAction({ excludedIDs: newExcludedIDs }));
        }
      } else if (flow.currentAlias === 'add-participants') {
        const newExcludedIDs = currentUserProfileId ? [currentUserProfileId] : [];
        if (participants) {
          for (const participant of participants) {
            const participantId = participant?.userResolved?._id;
            if (participantId) {
              newExcludedIDs.push(participantId);
            }
          }
        }
        if (!excludedIDs || !_.isEqual(excludedIDs, newExcludedIDs)) {
          dispatch(changeQueryExcludedIdsAction({ excludedIDs: newExcludedIDs }));
        }
      } else if (flow.currentAlias === 'groups-projects') {
        const newExcludedIDs = currentUserProfileId ? [currentUserProfileId] : [];
        if (!excludedIDs || !_.isEqual(excludedIDs, newExcludedIDs)) {
          dispatch(changeQueryExcludedIdsAction({ excludedIDs: newExcludedIDs }));
        }
      }
    }
  }
  if (flow?.userRequestInvalid) {
    return <HeadBar transparent={true} backButtonLink={undefined}></HeadBar>;
  }
  let allSelected = false;
  if (flow?.currentAlias === 'createmeeting' && resultHits && resultHits.length > 0) {
    if (flow?.flowData?.selectedUsers?.length >= resultHits?.length) {
      allSelected = true;
    }
  }
  const logoSrc = MTOLogo.logoWithTextSrc;
  const logoAlt = MTOLogo.logoAlt;
  const partnerLogoSrc = MTOLogo.partnerLogoSrc;
  const partnerLogoMobileSrc = MTOLogo.partnerLogoMobileSrc;
  const partnerLogoAlt = MTOLogo.partnerLogoAlt;

  const FlowHeaderProps = {
    flow: flow,
    flowSteps: flowSteps,
    currentStep: currentStep,
    setFlowStep: dispatcher.setFlowStepAction,
    setFlowData: dispatcher.setFlowDataAction,
    setFlowOption: dispatcher.setFlowOptionAction,
    filterItem: filterItem,
    filterAlias: filterAlias,
    filterWorking: filterWorking,
    queryTerm: queryTerm,
    searchResult: result,
    searchWorking: working,
    searchError: error,
    searchObject: searchObject,
    allSelected: allSelected,
    resultHits: resultHits,
    changeQueryTerm: dispatcher.changeQueryTermAction,
    changeQueryTypeAction: dispatcher.changeQueryTypeAction,
    changeQueryCategoriesAction: dispatcher.changeQueryCategoriesAction,
    changeQuerySortAction: dispatcher.changeQuerySortAction,
    resetSearchObject: dispatcher.resetSearchObjectAction,
    clearSearchEntries: dispatcher.clearSearchEntriesAction,
    close: close,
    fetchFirstPageSearchEntries: alias !== 'share' ? dispatcher.fetchFirstPageSearchEntriesAction : undefined,
    auth,
    partnerLogoSrc,
    partnerLogoMobileSrc,
    partnerLogoAlt,
    logoSrc,
    logoAlt,
    participants
  };

  if (isMobile) return <FlowMobileHeader {...FlowHeaderProps} />;
  else return <FlowHeader {...FlowHeaderProps} />;
};

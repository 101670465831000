export const validationPatterns = {
  ZipCode: /^[0-9]{5}$/,
  Phone: /^\+[\d\s]{8,}|[\d\s]{6,}$/,
  Mail: /\b[\w.-]+@[\w.-]+\.\w{2,}\b/i,
  Url: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
  Linkedin: /^(https:\/\/www\.linkedin\.com\/in\/)\S+$/,
  Xing: /^(https:\/\/www\.xing\.com\/profile\/)\S+$/i,
  HRAB: /^(HRA|HRB)$/,
  HRNr: /^[0-9][0-9]{0,11}/
};

export const validateNotEmpty = (value: string) => {
  return !!value;
};

export const validateHRAB = (value: string) => {
  return validationPatterns.HRAB.test(value);
};

export const validateHRNumber = (value: string) => {
  return validationPatterns.HRNr.test(value);
};

export const validateRegistrar = (value: string) => {
  return registrarLocations.indexOf(value) > -1;
};

export const validateEmail = (value: string, blacklist?: string[]) => {
  if (blacklist && blacklist.length > 0) {
    let valid = validationPatterns.Mail.test(value);
    if (!valid) {
      return false;
    }
    for (const domain of blacklist) {
      const domainPattern = new RegExp('.*' + domain + '$');
      valid = valid && !domainPattern.test(value);
    }
    return valid;
  }
  return validationPatterns.Mail.test(value);
};

export const validateZip = (value: string) => {
  return validationPatterns.ZipCode.test(value);
};

export const validatePhone = (value: string) => {
  return validationPatterns.Phone.test(value);
};

export const validateUrl = (value: string) => {
  return validationPatterns.Url.test(value);
};

export const validateXing = (value: string) => {
  return validationPatterns.Xing.test(value);
};

export const validateLinkedIn = (value: string) => {
  return validationPatterns.Linkedin.test(value);
};

export const allowedMimeTypes = [
  'application/epub+zip',
  'application/gzip',
  'application/msonenote',
  'application/msword',
  'application/pdf',
  'application/vnd.ms-access',
  'application/vnd.ms-excel',
  'application/vnd.ms-excel.addin.macroEnabled.12',
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  'application/vnd.ms-excel.sheet.macroEnabled.12',
  'application/vnd.ms-excel.template.macroEnabled.12',
  'application/vnd.ms-officetheme',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  'application/vnd.ms-powerpoint.slide.macroEnabled.12',
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  'application/vnd.ms-powerpoint.template.macroEnabled.12',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
  'application/vnd.oasis.opendocument.chart',
  'application/vnd.oasis.opendocument.database',
  'application/vnd.oasis.opendocument.formula',
  'application/vnd.oasis.opendocument.graphics',
  'application/vnd.oasis.opendocument.graphics-template',
  'application/vnd.oasis.opendocument.image',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.oasis.opendocument.presentation-template',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.spreadsheet-template',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.text-master',
  'application/vnd.oasis.opendocument.text-template',
  'application/vnd.oasis.opendocument.text-web',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.slide',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/x-7z-compressed',
  'application/zip',
  'audio/*',
  'image/*',
  'text/csv',
  'text/plain',
  'text/rtf',
  'video/*'
];

const registrarLocations = [
  'Aachen',
  'Altenburg',
  'Amberg',
  'Ansbach',
  'Apolda',
  'Arnsberg',
  'Arnstadt',
  'Arnstadt Zweigstelle Ilmenau',
  'Aschaffenburg',
  'Augsburg',
  'Aurich',
  'Bad Hersfeld',
  'Bad Homburg v.d.H.',
  'Bad Kreuznach',
  'Bad Oeynhausen',
  'Bad Salzungen',
  'Bamberg',
  'Bayreuth',
  'Berlin (Charlottenburg)',
  'Bielefeld',
  'Bochum',
  'Bonn',
  'Braunschweig',
  'Bremen',
  'Chemnitz',
  'Coburg',
  'Coesfeld',
  'Cottbus',
  'Darmstadt',
  'Deggendorf',
  'Dortmund',
  'Dresden',
  'Duisburg',
  'Düren',
  'Düsseldorf',
  'Eisenach',
  'Erfurt',
  'Eschwege',
  'Essen',
  'Flensburg',
  'Frankfurt am Main',
  'Frankfurt/Oder',
  'Freiburg',
  'Friedberg',
  'Fritzlar',
  'Fulda',
  'Fürth',
  'Gelsenkirchen',
  'Gera',
  'Gießen',
  'Gotha',
  'Göttingen',
  'Greiz',
  'Gütersloh',
  'Hagen',
  'Hamburg',
  'Hamm',
  'Hanau',
  'Hannover',
  'Heilbad Heiligenstadt',
  'Hildburghausen',
  'Hildesheim',
  'Hof',
  'Homburg',
  'Ingolstadt',
  'Iserlohn',
  'Jena',
  'Kaiserslautern',
  'Kassel',
  'Kempten (Allgäu)',
  'Kiel',
  'Kleve',
  'Koblenz',
  'Köln',
  'Königstein',
  'Korbach',
  'Krefeld',
  'Landau',
  'Landshut',
  'Langenfeld',
  'Lebach',
  'Leipzig',
  'Lemgo',
  'Limburg',
  'Lübeck',
  'Ludwigshafen a.Rhein (Ludwigshafen)',
  'Lüneburg',
  'Mainz',
  'Mannheim',
  'Marburg',
  'Meiningen',
  'Memmingen',
  'Merzig',
  'Mönchengladbach',
  'Montabaur',
  'Mühlhausen',
  'München',
  'Münster',
  'Neubrandenburg',
  'Neunkirchen',
  'Neuruppin',
  'Neuss',
  'Nordhausen',
  'Nürnberg',
  'Offenbach am Main',
  'Oldenburg (Oldenburg)',
  'Osnabrück',
  'Ottweiler',
  'Paderborn',
  'Passau',
  'Pinneberg',
  'Pößneck',
  'Pößneck Zweigstelle Bad Lobenstein',
  'Potsdam',
  'Recklinghausen',
  'Regensburg',
  'Rostock',
  'Rudolstadt',
  'Saarbrücken',
  'Saarlouis',
  'Schweinfurt',
  'Schwerin',
  'Siegburg',
  'Siegen',
  'Sömmerda',
  'Sondershausen',
  'Sonneberg',
  'Stadthagen',
  'Stadtroda',
  'Steinfurt',
  'Stendal',
  'St. Ingbert (St Ingbert)',
  'Stralsund',
  'Straubing',
  'Stuttgart',
  'St. Wendel (St Wendel)',
  'Suhl',
  'Tostedt',
  'Traunstein',
  'Ulm',
  'Völklingen',
  'Walsrode',
  'Weiden i. d. OPf.',
  'Weimar',
  'Wetzlar',
  'Wiesbaden',
  'Wittlich',
  'Wuppertal',
  'Würzburg',
  'Zweibrücken'
];
import _ from 'lodash';
import React, { ReactChild, ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Ava, Btn, IconAva, TimeAva, Typo } from '@curry-group/mui-curcuma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton } from '@material-ui/lab';
import { faLongArrowRight, faUserSecret, IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { BaseListItem, BaseListItemEmpty, BaseListItemSkeleton } from '../../list-items/default';
import { assetUrl, getDetailRoute, hashCode, IDictionary, ListAvatarColors } from '../../../helper';
import { IListItem } from '../../../model/list/ListItem';
import { IListWithHeadlineProps, ListWithHeadline } from '../../list/index';
import Hidden from '@material-ui/core/Hidden';
import Badge from '@material-ui/core/Badge';
import { IMemoryListItem, ThingTypes } from '../../../model/ryve/Thing';
import { ActivityListItem } from '../../list-items/activities';
import { faHourglassClock, faMask } from '@fortawesome/pro-solid-svg-icons';
import { IListUser } from '../../../model/search/SearchResult';
import { ParticipantListItem } from '../../list-items/participant';

export interface IListWidgetProps extends Omit<IListWithHeadlineProps<IListItem>, 'renderItem'> {
  working?: boolean;
  backgroundWorking?: boolean;
  ignoreWorking?: boolean;
  showAllCaption?: string;
  showAllClick?: () => void;
  showAllIcon?: IconDefinition;
  emptyCaption?: string;
  menuClicked?: (item: IListItem, close: () => void) => ReactNode;
  shareClick?: (e:any) => void;
  itemMemoClicked?: (item: IMemoryListItem, type: 'GET'|'POST'|'DELETE', seed?: string) => void;
  itemActions?: (item: IListItem) => ReactNode;
  replaceLinks?: string;
  divider?: 'top' | 'bottom' | 'both' | 'none' | undefined;
  listType?: 'default' | 'news' | 'members' | 'meeting' | 'references' | 'adminParticipation';
  memoryList?: boolean;
  externalLinkClicked?: (link: string) => void;
}

export const ListWidget: React.FC<IListWidgetProps> = ({
  items,
  working,
  backgroundWorking,
  ignoreWorking = false,
  headline,
  showAllClick,
  showAllCaption,
  showAllIcon,
  top,
  emptyCaption,
  menuClicked,
  shareClick,
  itemMemoClicked,
  itemActions,
  replaceLinks,
  divider,
  listType='default',
  endAdornment,
  memoryList,
  externalLinkClicked
}) => {
  const [initialWorking, setInitialWorking] = useState(true && !ignoreWorking);
  const location = useLocation();
  const appconfig = useSelector(state => state.foundation.appconfig);
  const catItems = useSelector(state => state.categories?.items);
  const avatarDict = useSelector(state => state.foundation?.avatarsById);
  const botSearch = useSelector(state => state.filter?.botSearch);

  const renderItem = (item: IListItem, index: number, avatarDict?: IDictionary<IListUser>) => {
    try {
      const avatarWithBadge =
        item?.__original?.userResolved?.content?.generalUserStatus === 'to_check_signature_employer' ||
        item?.__original?.content?.generalUserStatus === 'to_check_signature_employer' ||
        item?.avatar?.generalUserStatus === 'to_check_signature_employer' ||
        false;
      let avatar: ReactChild = <></>;
      if (item.avatar.type === 'time') {
        avatar = <TimeAva size="large" color={item.color} fromTime={item.avatar.time?.from || ''} toTime={item.avatar.time?.to || ''} />;
      } else if (item.avatar.type === 'image') {
        const hash = hashCode(item?.title?.slice(0,2) ?? '');
        const colorIndex = hash % 4;
        const colorObject = ListAvatarColors[colorIndex];
        const anonymousPublish = item?.__original?.content?.anonymousPublish;
        avatar =
          <Ava size="large"
            src={anonymousPublish ? undefined : item.avatar.image}
            style={{color: colorObject.color, background: 'none'}}
            children={<FontAwesomeIcon icon={anonymousPublish ? faUserSecret : item.avatar.icon as any} />}
          />;
      } else if (item.avatar.type === 'user') {
        const userId = item.avatar.avatarUserId;
        if (item?.__original?.content?.anonymousPublish
          || (
              item?.__original?.content?.itemRefResolved?.content?.anonymousPublish
              && !item?.__original?.content?.notAnonymous
          )
          || (
            item?.__original?.content?.itemRefResolved?.content?.connectedEntryResolved?.[0]?.content?.anonymousPublish
          )
        ) {
          const hash = hashCode('AN');
          const colorIndex = hash % 4;
          const colorObject = ListAvatarColors[colorIndex];
          const baseAvatar =
            <Ava size="large"
              src={undefined}
              style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}}
              children={<FontAwesomeIcon icon={faMask} />}
            />;
          avatar = avatarWithBadge
            ? <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                color={'error'}
                title={'User noch nicht validiert.'}
                badgeContent={<FontAwesomeIcon icon={faHourglassClock} />}
              >
                {baseAvatar}
              </Badge>
            : baseAvatar;
        } else {
          let avatarImage: string | undefined = undefined;
          if ((!userId || (!!userId && !avatarDict) || (!!userId && !!avatarDict && !avatarDict[userId])) && !!item?.avatar?.image) {
            avatarImage = item?.avatar?.image;
          } else if (!!userId && !!avatarDict && avatarDict[userId]) {
            avatarImage = assetUrl(avatarDict[userId].avatar, true);
          } else if (!!userId && !!avatarDict && !avatarDict[userId]) {
            avatarImage = undefined;
          }
          const hash = hashCode(item?.avatar?.abbreviation ?? '');
          const colorIndex = hash % 4;
          const colorObject = ListAvatarColors[colorIndex];
          const baseAvatar = <Ava size="large" src={avatarImage} style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}}>{item.avatar.abbreviation}</Ava>;
          avatar = avatarWithBadge
            ? <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                color={'error'}
                title={'User noch nicht validiert.'}
                badgeContent={<FontAwesomeIcon icon={faHourglassClock} />}
              >
                {baseAvatar}
              </Badge>
            : baseAvatar;
        }
      } else if (item.avatar.type === 'icon' && item.avatar.icon) {
        const hash = hashCode(item?.title?.slice(0,2) ?? '');
        const colorIndex = hash % 4;
        const colorObject = ListAvatarColors[colorIndex];
        avatar = <IconAva style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}} children={<FontAwesomeIcon icon={item.avatar.icon} />} size="large" />;
      }

      let userAvatar: ReactChild = <></>;
      if (item.userAvatar?.type === 'user') {
        const hash = hashCode(item.userAvatar?.abbreviation ?? '');
        const colorIndex = hash % 4;
        const colorObject = ListAvatarColors[colorIndex];
        const baseAvatar =
          <Ava size="large"
            style={{color: colorObject?.color, background: colorObject?.bg, fontWeight: 500}}
            src={item.userAvatar.image}
          >
            {item.userAvatar.abbreviation}
          </Ava>;
        userAvatar = item?.userAvatar.generalUserStatus === 'to_check_signature_employer'
          ? <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              color={'error'}
              title={'User noch nicht validiert.'}
              badgeContent={<FontAwesomeIcon icon={faHourglassClock} />}
            >
              {baseAvatar}
            </Badge>
          : baseAvatar;
      }
      if (item.__original.type === ThingTypes.Notification) {
        return <ActivityListItem
          key={item?._id || index}
          {...item}
          avatar={avatar}
          userAvatar={userAvatar}
          to={!replaceLinks ? getDetailRoute(item, appconfig || {}, location) : replaceLinks }
          userLink={!replaceLinks ? item?.userLink : replaceLinks }
          menuClicked={close => menuClicked?.(item, close)}
          actions={itemActions?.(item)}
          fontcolor={'textPrimary'}
          highlight={item.highlight}
          badge={item?.badge ? <span style={{ visibility: 'hidden' }}>.</span> : undefined}
          categories={item.categories?.map(cat => catItems[cat] ? catItems[cat].name : cat)}
          onLinkClick={externalLinkClicked}
        />
      }
      if (listType === 'members') {
        return (
          <ParticipantListItem
            key={`generic_list_item_${item?._id || index}`}
            {...item}
            avatar={avatar}
            userAvatar={userAvatar}
            to={!replaceLinks ? getDetailRoute(item, appconfig || {}, location) : replaceLinks }
            userLink={!replaceLinks ? item?.userLink : replaceLinks }
            menuClicked={close => menuClicked?.(item, close)}
            itemMemoClicked={itemMemoClicked}
            shareClick={item?.shareClick ? item?.shareClick : shareClick ? shareClick : undefined}
            actions={itemActions?.(item)}
            fontcolor={'textPrimary'}
            highlight={item.highlight}
            badge={item?.badge ? <span style={{ visibility: 'hidden' }}>.</span> : undefined}
            categories={item.categories?.map(cat => catItems[cat] ? catItems[cat].name : cat)}
            selected={item.selected}
            anchor={item.anchor}
            itemTemplate={!!item?.itemTemplate ? item?.itemTemplate : listType}
            avatarDict={avatarDict}
          />
        );
      }
      return (
        <BaseListItem
          key={`generic_list_item_${item?._id || index}`}
          {...item}
          avatar={avatar}
          userAvatar={userAvatar}
          to={!replaceLinks ? getDetailRoute(item, appconfig || {}, location) : replaceLinks }
          userLink={!replaceLinks ? item?.userLink : replaceLinks }
          menuClicked={close => menuClicked?.(item, close)}
          itemMemoClicked={itemMemoClicked}
          shareClick={item?.shareClick ? item?.shareClick : shareClick ? shareClick : undefined}
          actions={itemActions?.(item)}
          fontcolor={'textPrimary'}
          highlight={item.highlight}
          badge={item?.badge ? <span style={{ visibility: 'hidden' }}>.</span> : undefined}
          categories={item.categories?.map(cat => catItems[cat] ? catItems[cat].name : cat)}
          selected={item.selected}
          anchor={item.anchor}
          itemTemplate={!!item?.itemTemplate ? item?.itemTemplate : listType}
          avatarDict={avatarDict}
        />
      );
    } catch (e) {
      return <></>;
    }
  }

  const standardEndAdornment = (
    <Btn onClick={showAllClick} variant="text" size="large" endIcon={<FontAwesomeIcon icon={showAllIcon ? showAllIcon : faLongArrowRight} />}>
      <Hidden mdUp>{showAllCaption || 'Alle'}</Hidden>
      <Hidden smDown>{showAllCaption || 'Alle anzeigen'}</Hidden>
    </Btn>
  );

  useEffect(() => {
    if (working || backgroundWorking || (items || []).length > 0) {
      setInitialWorking(false);
    }
  }, [working, backgroundWorking, items]);

  if (listType==='news') {
    return (
      <ListWithHeadline
        top={top}
        items={items}
        headline={working && !botSearch
          ? <Typo fontWeight={500} variant="body1" component="span">
              <Skeleton animation="wave" style={{ width: '200px' }} />
            </Typo>
          : headline}
        endAdornment={endAdornment}
        renderItem={renderItem}
        divider={divider}
        avatarDict={avatarDict}
        small={memoryList ? true : false}
      >
        { working && _.range(5).map(i => <BaseListItemSkeleton key={i} />)}
        { !working && !items?.length && (
          <BaseListItemEmpty title={'Bitte pflegen Sie im Profil Ihre Interessen, um News angezeigt zu bekommen.'} key={'empty_list_item_0'} />
        ) }
      </ListWithHeadline>
    );
  }

  return (
    <ListWithHeadline
      top={top}
      items={items}
      headline={initialWorking || working
        ? <Typo fontWeight={500} variant="body1" component="span">
            <Skeleton animation="wave" style={{ width: '200px' }} />
          </Typo>
        : headline}
      endAdornment={showAllClick && standardEndAdornment}
      renderItem={initialWorking ? () => <></> : renderItem}
      divider={divider}
      avatarDict={avatarDict}
      small={memoryList ? true : false}
    >
      { (initialWorking || working) && _.range(5).map(i => <BaseListItemSkeleton key={i} />)}
      { (!initialWorking && !working && !items?.length) && (
        <BaseListItemEmpty title={emptyCaption} key={'empty_list_item_0'} />
      ) }
    </ListWithHeadline>
  );
};

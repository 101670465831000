import { Btn, Bx, IconBtn, Link, Typo } from '@curry-group/mui-curcuma';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { faCalendarCheck, faCalendarMinus, faCameraHome, faComment, faDownload, faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Hidden } from '@material-ui/core';
import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { setFlowDataAction } from '../../../data/actions/flow';
import {
  createConferenceMessageRequest,
  fetchParticipantsRequest,
  joinConferenceRequest,
  leaveCommunicationRequest,
  requestParticipationRequest
} from '../../../data/reducer/communication';
import { updateVotingRequest } from '../../../data/reducer/detail';
import { getDetailRoute, GetThingTypeName, removeDashboardUrl, removeHTMLTagsSpecial, resolveLinks, shorten, TYPES, TYPES_PROPS } from '../../../helper';
import { IDetail } from '../../../model/data/Detail';
import { getAuthor, getRelatedFromData, interactionButtonSettings, ThingTypes, toProfileListItem, toReferenceListItem, transformForList } from '../../../model/ryve/Thing';
import { ContentInteractionBar, IContentInteractionBarProps } from '../../content-interaction-bar';
import { DetailContentComponent, DetailWorkingComponent, IDetailContentProps } from '../content';
import { NotFoundComponent } from '../not-found';
import dayjs from 'dayjs';
import { IMessageModel } from '../../../model/communication/Message';
import { ParticipationRequestStatus, ParticipationRequestType } from '../../../model/communication/Participant';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { api } from '../../../data/api';
import { IResolvedMessageContent, resolveMessageContentLinks } from '../../communication/message/content';
import { OpenExternalLinkDialog } from '../../dialogs/open-external-link';
import { detailItemRelatedGroupSearch } from '../../../data/reducer/data';
import { IContentAuthorProps } from '../../content-author';
import { buildAbbreviation } from '../../../helper/user';
import { userprofileMemorylistRequestAction } from '../../../data/actions/foundation';
import { INotification } from '../../../model/notifications';

export interface IContentSwitcherProps {
  top?: ReactNode;
  isScrolled?: boolean;
  setIsScrolled?: (scrolled: boolean) => void;
  chatButton?: IContentInteractionBarProps['chatButton'];
  editClick?: () => void;
  inviteClick?: () => void;
  anonymousAccess?: boolean;
  onRequestActionCanvas?: (msg: IMessageModel) => void;
  memoryIds?: Array<string>;
  unreadNotifications?: Array<INotification>;
}

export const DetailSwitcherComponent: React.FC<IContentSwitcherProps> = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const match = useRouteMatch();
  const item = useSelector(state => state.detail.item);
  const working = useSelector(state => state.detail.working);
  const type = useSelector(state => state.detail.item?.type);
  const numVotes = useSelector(state => state.detail.item?.content?.voting);
  const numComments = useSelector(state => state.detail.item?.content?.commentCount);
  const userVoting = useSelector(state => state.detail.item?.content?.userVoting);
  const anonymousAccess = useSelector(state => !state.foundation?.auth);
  const memoryList = useSelector(state => state.foundation?.profile?.memoryList);
  const notifications = useSelector(state => state.notifications?.items);
  const listed = useSelector(state => state.detail?.item?.content?.listed);
  const parentListed = useSelector(state => state.detail?.item?.content?.parentThingResolved?.[0]?.content?.listed);

  const memoryIds = memoryList?.map(m => m.typeId === ThingTypes.News ? (m.externalId ?? '') : m.elementId);
  const unreadNotifications = notifications.filter(notification => !notification.content?.notificationRead);
  const booked = !!item?._id && memoryIds?.includes(item?._id);

  const allCategories = useSelector(state => state.categories?.items);
  let searchGoupsClick: undefined | (() => void) = undefined;
  if (item && (type === ThingTypes.BestPractice || type === ThingTypes.Blog || type === ThingTypes.Wiki)) {
    searchGoupsClick = () => {
      dispatch(detailItemRelatedGroupSearch({ allCategories, item, history, target: '/coop/groups-projects/search', ref: removeDashboardUrl(match.url) }));
    };
  }

  const unlistedGroup = (type === TYPES.GROUP || type === TYPES.PROJECT) && listed === false;
  const unlistedParent = type === TYPES.MEETING && parentListed === false;

  const contentInteractionBarProps = !anonymousAccess
    ? {
        searchGoupsClick,
        chatButton: interactionButtonSettings(type).hideComments ? undefined : props.chatButton,
        numVotes: numVotes,
        voteDownClick:
          (userVoting?.vote !== -1 && !interactionButtonSettings(type).hideVoting ? true : undefined) &&
          (() => {
            dispatch(updateVotingRequest({ voting: -1 }));
          }),
        voteUpClick:
          (userVoting?.vote !== 1 && !interactionButtonSettings(type).hideVoting ? true : undefined) &&
          (() => {
            dispatch(updateVotingRequest({ voting: 1 }));
          }),
        numComments: numComments,
        shareClick: !interactionButtonSettings(type).hideShare && !(unlistedGroup || unlistedParent)
          ? () => {
              dispatch(
                setFlowDataAction({
                  alias: 'share',
                  flowData: {
                    location: history.location,
                    itemData: {
                      sharedItem: item
                    }
                  }
                })
              );
              history.push(history.location.pathname + '/flow' + history.location.search);
            }
          : undefined,
        editClick: props.editClick,
        inviteClick: props.inviteClick,
        memoryClick: () => {
          dispatch(userprofileMemorylistRequestAction({
            memoryItem: {
              elementId: item?._id || '',
              type: 'thing',
              typeId: item?.type || ''
            },
            type: booked ? 'DELETE' : 'POST'
          }))
        },
        booked: booked,
      }
    : undefined;

  if (working) {
    return <DetailWorkingComponent />;
  }
  let component = <NotFoundComponent />;
  let notFound = false;
  switch (type) {
    case ThingTypes.Idea: {
      component = <ConnectedIdeaDetail
        {...props}
        contentInteractionBarProps={contentInteractionBarProps}
        anonymousAccess={anonymousAccess}
        unreadNotifications={unreadNotifications}
        memoryIds={memoryIds}
      />;
      break;
    }
    case ThingTypes.QnA: {
      component = <ConnectedQnADetail
        {...props}
        contentInteractionBarProps={contentInteractionBarProps}
        anonymousAccess={anonymousAccess}
        unreadNotifications={unreadNotifications}
        memoryIds={memoryIds}
      />;
      break;
    }
    case ThingTypes.Requisition: {
      component = <ConnectedRequisitionDetail
        {...props}
        contentInteractionBarProps={contentInteractionBarProps}
        anonymousAccess={anonymousAccess}
        unreadNotifications={unreadNotifications}
        memoryIds={memoryIds}
      />;
      break;
    }
    case ThingTypes.Blog: {
      component = <ConnectedBlogDetail
        {...props}
        contentInteractionBarProps={contentInteractionBarProps}
        anonymousAccess={anonymousAccess}
        unreadNotifications={unreadNotifications}
        memoryIds={memoryIds}
      />;
      break;
    }
    case ThingTypes.Wiki: {
      component = <ConnectedWikiDetail
        {...props}
        contentInteractionBarProps={contentInteractionBarProps}
        anonymousAccess={anonymousAccess}
        unreadNotifications={unreadNotifications}
        memoryIds={memoryIds}
      />;
      break;
    }
    case ThingTypes.BestPractice: {
      component = <ConnectedBestPracticeDetail
        {...props}
        contentInteractionBarProps={contentInteractionBarProps}
        anonymousAccess={anonymousAccess}
        unreadNotifications={unreadNotifications}
        memoryIds={memoryIds}
      />;
      break;
    }
    case ThingTypes.Group: {
      component = <ConnectedGroupDetail
        {...props}
        contentInteractionBarProps={contentInteractionBarProps}
        anonymousAccess={anonymousAccess}
        unreadNotifications={unreadNotifications}
        memoryIds={memoryIds}
      />;
      break;
    }
    case ThingTypes.Project: {
      component = <ConnectedProjectDetail
        {...props}
        contentInteractionBarProps={contentInteractionBarProps}
        anonymousAccess={anonymousAccess}
        unreadNotifications={unreadNotifications}
        memoryIds={memoryIds}
      />;
      break;
    }
    case ThingTypes.Meeting: {
      component = <ConnectedMeetingDetail
        {...props}
        contentInteractionBarProps={contentInteractionBarProps}
      />;
      break;
    }
    case ThingTypes.Collection: {
      component = <ConnectedCollectionDetail
        {...props}
        contentInteractionBarProps={contentInteractionBarProps}
        anonymousAccess={anonymousAccess}
        unreadNotifications={unreadNotifications}
        memoryIds={memoryIds}
      />;
      break;
    }
    case ThingTypes.ContentPage: {
      component = <ConnectedContentDetail {...props} />;
      break;
    }
    default: {
      notFound = true;
      component = <NotFoundComponent />;
      break;
    }
  }
  if (notFound) {
    return component;
  } else {
    return <Bx py={anonymousAccess ? { md: 8, lg: 6, xl: 6.25 } : undefined} px={anonymousAccess ? { lg: 4, xl: 4 } : undefined}>
      {component}
    </Bx>
  }
};

export interface IConntectedContentDetailProps extends IContentSwitcherProps {
  contentInteractionBarProps?: IContentInteractionBarProps;
}

export const ConnectedIdeaDetail: React.FC<IConntectedContentDetailProps> = props => {
  const anonymousPublish = useSelector(state => state.detail?.item?.content.anonymousPublish);
  const appconfig = useSelector(state => state.foundation.appconfig);
  const attachmentsSubD = useSelector(state => state.detail?.item?.content.attachmentsSubD);
  const authorSubD = useSelector(state => state.detail?.item?.content.authorInfoSubD);
  const content = useSelector(state => state.detail?.item?.content.content);
  const createdAt = useSelector(state => state.detail?.item?.createdAt);
  const creator = useSelector(state => state.detail?.item?.content.creator);
  const data = useSelector(state => state.data);
  const id = useSelector(state => state.detail?.item?._id);
  const location = useLocation();
  const modifiedAt = useSelector(state => state.detail?.item?.modifiedAt);
  const participationWorking = useSelector(state => state.communication.participationWorking);
  const readingTime = useSelector(state => state.detail?.item?.content.readingTime);
  const releaseDate = useSelector(state => state.detail?.item?.releaseDate);
  const categories = useSelector(state => state.detail?.item?.content.categoriesResolved);
  const title = useSelector(state => state.detail?.item?.content.title);
  const type = useSelector(state => state.detail?.item?.type);
  const working = useSelector(state => state.detail.working);

  const references: IDetail['referenceItems'] = [[]];
  if (attachmentsSubD && attachmentsSubD.filter(attachment => attachment.attachmentFile).length) {
    references[0].push({
      title: 'Anhänge',
      items: attachmentsSubD
        .filter(attachment => attachment.attachmentFile)
        .map((item: any) => toReferenceListItem({ 
          title: 'Download', 
          content: item?.attachmentTitle, 
          url: api.query(api.params(api.v2.communication.downloadFiles, { id: id }), { ids: item?.attachmentFile }),
          download: true 
        }))
    });
  }

  if (!type || !id) return <></>;

  let finalContent;
  if (props.anonymousAccess) {
    const contentLength = content?.length || 0;
    if (contentLength === 0) {
      finalContent = content;
    } else {
      const modifier = contentLength % 2;
      finalContent = shorten(content, (contentLength + modifier) / 2);
    }
  } else {
    finalContent = content;
  }

  return (
    <DetailContentComponent
      {...props}
      categories={categories}
      contentInteractionBar={props.contentInteractionBarProps ? <ContentInteractionBar {...props.contentInteractionBarProps} /> : undefined}
      authors={getAuthor(authorSubD, creator)}
      type={TYPES_PROPS[type]?.Name}
      timestamp={releaseDate || modifiedAt || createdAt}
      readingTime={readingTime}
      title={title || ''}
      html={resolveLinks(finalContent, location, appconfig || undefined)}
      keyvisual={TYPES_PROPS[type]?.FallbackImage}
      referenceItems={references}
      anonymousPublish={anonymousPublish}
      relatedItems={getRelatedFromData(id, data).map(dataset => {
        return {
          title: 'Verwandte Inhalte',
          items: transformForList(
            (dataset || []).map(item => {
              return {
                _source: item,
                _id: item?._id,
                _score: item?._score,
                badge: !!props.unreadNotifications?.find(notification => notification.content?.itemRef === item._id),
                booked: item.type === ThingTypes.News
                  ? (!!item?.content?.url ? props.memoryIds?.includes(item?.content?.url) : false)
                  : props.memoryIds?.includes(item._id),
                noShare: item.type === ThingTypes.OneToOne || ((item.type === ThingTypes.Group || item.type === ThingTypes.Project) && !item.content?.listed)
              };
            })
          )
        };
      })}
      working={working || participationWorking}
    ></DetailContentComponent>
  );
};

export const ConnectedQnADetail: React.FC<IConntectedContentDetailProps> = props => {
  const anonymousPublish = useSelector(state => state.detail?.item?.content.anonymousPublish);
  const appconfig = useSelector(state => state.foundation.appconfig);
  const attachmentsSubD = useSelector(state => state.detail?.item?.content.attachmentsSubD);
  const authorSubD = useSelector(state => state.detail?.item?.content.authorInfoSubD);
  const content = useSelector(state => state.detail?.item?.content.content);
  const createdAt = useSelector(state => state.detail?.item?.createdAt);
  const creator = useSelector(state => state.detail?.item?.content.creator);
  const data = useSelector(state => state.data);
  const id = useSelector(state => state.detail?.item?._id);
  const location = useLocation();
  const modifiedAt = useSelector(state => state.detail?.item?.modifiedAt);
  const participationWorking = useSelector(state => state.communication.participationWorking);
  const readingTime = useSelector(state => state.detail?.item?.content.readingTime);
  const releaseDate = useSelector(state => state.detail?.item?.releaseDate);
  const categories = useSelector(state => state.detail?.item?.content.categoriesResolved);
  const title = useSelector(state => state.detail?.item?.content.title);
  const type = useSelector(state => state.detail?.item?.type);
  const working = useSelector(state => state.detail.working);

  const references: IDetail['referenceItems'] = [[]];
  if (attachmentsSubD && attachmentsSubD.filter(attachment => attachment.attachmentFile).length) {
    references[0].push({
      title: 'Anhänge',
      items: attachmentsSubD
        .filter(attachment => attachment.attachmentFile)
        .map((item: any) => toReferenceListItem({ 
          title: 'Download', 
          content: item?.attachmentTitle, 
          url: api.query(api.params(api.v2.communication.downloadFiles, { id: id }), { ids: item?.attachmentFile }),
          download: true 
        }))
    });
  }

  if (!type || !id) return <></>;

  let finalContent;
  if (props.anonymousAccess) {
    const contentLength = content?.length || 0;
    if (contentLength === 0) {
      finalContent = content;
    } else {
      const modifier = contentLength % 2;
      finalContent = shorten(content, (contentLength + modifier) / 2);
    }
  } else {
    finalContent = content;
  }

  return (
    <DetailContentComponent
      {...props}
      categories={categories}
      contentInteractionBar={props.contentInteractionBarProps ? <ContentInteractionBar {...props.contentInteractionBarProps} /> : undefined}
      authors={getAuthor(authorSubD, creator)}
      type={TYPES_PROPS[type]?.Name}
      timestamp={releaseDate || modifiedAt || createdAt}
      readingTime={readingTime}
      title={title || ''}
      html={resolveLinks(finalContent, location, appconfig || undefined)}
      keyvisual={TYPES_PROPS[type]?.FallbackImage}
      referenceItems={references}
      anonymousPublish={anonymousPublish}
      relatedItems={getRelatedFromData(id, data).map(dataset => {
        return {
          title: 'Verwandte Inhalte',
          items: transformForList(
            (dataset || []).map(item => {
              return {
                _source: item,
                _id: item?._id,
                _score: item?._score,
                badge: !!props.unreadNotifications?.find(notification => notification.content?.itemRef === item._id),
                booked: item.type === ThingTypes.News
                  ? (!!item?.content?.url ? props.memoryIds?.includes(item?.content?.url) : false)
                  : props.memoryIds?.includes(item._id),
                noShare: item.type === ThingTypes.OneToOne || ((item.type === ThingTypes.Group || item.type === ThingTypes.Project) && !item.content?.listed)
              };
            })
          )
        };
      })}
      working={working || participationWorking}
    ></DetailContentComponent>
  );
};

export const ConnectedRequisitionDetail: React.FC<IConntectedContentDetailProps> = props => {
  const anonymousPublish = useSelector(state => state.detail?.item?.content.anonymousPublish);
  const attachmentsSubD = useSelector(state => state.detail?.item?.content.attachmentsSubD);
  const authorSubD = useSelector(state => state.detail?.item?.content.authorInfoSubD);
  const content = useSelector(state => state.detail?.item?.content.content);
  const contentRC = useSelector(state => state.detail?.item?.content.contentRequisitionContext);
  const contentRS = useSelector(state => state.detail?.item?.content.contentRequisitionSolutions);
  const createdAt = useSelector(state => state.detail?.item?.createdAt);
  const creator = useSelector(state => state.detail?.item?.content.creator);
  const data = useSelector(state => state.data);
  const id = useSelector(state => state.detail?.item?._id);
  const modifiedAt = useSelector(state => state.detail?.item?.modifiedAt);
  const participationWorking = useSelector(state => state.communication.participationWorking);
  const readingTime = useSelector(state => state.detail?.item?.content.readingTime);
  const releaseDate = useSelector(state => state.detail?.item?.releaseDate);
  const title = useSelector(state => state.detail?.item?.content.title);
  const categories = useSelector(state => state.detail?.item?.content.categoriesResolved);
  const type = useSelector(state => state.detail?.item?.type);
  const working = useSelector(state => state.detail.working);

  const references: IDetail['referenceItems'] = [[]];
  if (attachmentsSubD && attachmentsSubD.filter(attachment => attachment.attachmentFile).length) {
    references[0].push({
      title: 'Anhänge',
      items: attachmentsSubD
        .filter(attachment => attachment.attachmentFile)
        .map((item: any) => toReferenceListItem({ 
          title: 'Download', 
          content: item?.attachmentTitle, 
          url: api.query(api.params(api.v2.communication.downloadFiles, { id: id }), { ids: item?.attachmentFile }),
          download: true 
        }))
    });
  }

  if (!type || !id) return <></>;

  return (
    <DetailContentComponent
      {...props}
      categories={categories}
      contentInteractionBar={props.contentInteractionBarProps ? <ContentInteractionBar {...props.contentInteractionBarProps} /> : undefined}
      authors={getAuthor(authorSubD, creator)}
      type={TYPES_PROPS[type]?.Name}
      timestamp={releaseDate || modifiedAt || createdAt}
      readingTime={readingTime}
      title={title || ''}
      keyvisual={TYPES_PROPS[type]?.FallbackImage}
      html={`
        ${contentRC ? '<h2>Einordnung in das Versorgungsumfeld</h2>' + contentRC : ''}
        ${content ? '<h2>Versorgungsbedarf</h2>' + content : ''}
        ${contentRS ? '<h2>Ideen für Lösungen</h2>' + (props.anonymousAccess ? '' : contentRS) : ''}
      `}
      referenceItems={references}
      anonymousPublish={anonymousPublish}
      relatedItems={getRelatedFromData(id, data).map(dataset => {
        return {
          title: 'Verwandte Inhalte',
          items: transformForList(
            (dataset || []).map(item => {
              return {
                _source: item,
                _id: item?._id,
                _score: item?._score,
                badge: !!props.unreadNotifications?.find(notification => notification.content?.itemRef === item._id),
                booked: item.type === ThingTypes.News
                  ? (!!item?.content?.url ? props.memoryIds?.includes(item?.content?.url) : false)
                  : props.memoryIds?.includes(item._id),
                noShare: item.type === ThingTypes.OneToOne || ((item.type === ThingTypes.Group || item.type === ThingTypes.Project) && !item.content?.listed)
              };
            })
          )
        };
      })}
      working={working || participationWorking}
    ></DetailContentComponent>
  );
};

export const ConnectedBlogDetail: React.FC<IConntectedContentDetailProps> = props => {
  const anonymousPublish = useSelector(state => state.detail?.item?.content.anonymousPublish);
  const appconfig = useSelector(state => state.foundation.appconfig);
  const attachmentsSubD = useSelector(state => state.detail?.item?.content.attachmentsSubD);
  const authorSubD = useSelector(state => state.detail?.item?.content.authorInfoSubD);
  const commentCount = useSelector(state => state.detail.item?.content?.commentCount);
  const content = useSelector(state => state.detail?.item?.content.content);
  const createdAt = useSelector(state => state.detail?.item?.createdAt);
  const creator = useSelector(state => state.detail?.item?.content.creator);
  const data = useSelector(state => state.data);
  const externalLinksSubD = useSelector(state => state.detail?.item?.content.externalLinksSubD);
  const id = useSelector(state => state.detail?.item?._id);
  const location = useLocation();
  const mainPoints = useSelector(state => state.detail?.item?.content?.mainPointsSubD);
  const modifiedAt = useSelector(state => state.detail?.item?.modifiedAt);
  const normSubD = useSelector(state => state.detail?.item?.content.normSubD);
  const participationWorking = useSelector(state => state.communication.participationWorking);
  const readingTime = useSelector(state => state.detail?.item?.content.readingTime);
  const releaseDate = useSelector(state => state.detail?.item?.releaseDate);
  const series = {
    isSeries: useSelector(state => state.detail?.item?.content.isSeries),
    episode: useSelector(state => state.detail?.item?.content.episode),
    previousBlog: useSelector(state => state.detail?.item?.content.previousBlog),
    nextBlog: useSelector(state => state.detail?.item?.content.nextBlog)
  };
  const sourcesReferencesSubD = useSelector(state => state.detail?.item?.content.sourcesReferencesSubD);
  const subtitle = useSelector(state => state.detail?.item?.content.subtitle);
  const summary = useSelector(state => state.detail?.item?.content.summary);
  const title = useSelector(state => state.detail?.item?.content.title);
  const type = useSelector(state => state.detail?.item?.type);
  const working = useSelector(state => state.detail.working);

  const fazit: IDetail['additionalParagraphs'] = [];
  if (summary) {
    fazit.push({ title: 'Fazit', content: resolveLinks(summary, location, appconfig || undefined) });
  }

  const seriesProps: IDetailContentProps['series'] = {
    episode: series.episode,
    previous: series.previousBlog?.content?.title
      ? {
          title: 'Teil ' + (series.previousBlog?.content?.episode || (series.episode || 2) - 1) + ': ' + series.previousBlog.content.title,
          link: getDetailRoute({ typeId: series.previousBlog.type || '', alias: series.previousBlog.seo?.alias }, appconfig || {}, location) || ''
        }
      : undefined,
    next: series.nextBlog?.content?.title
      ? {
          title: 'Teil ' + (series.nextBlog?.content?.episode || (series.episode || 1) + 1) + ': ' + series.nextBlog.content.title,
          link: getDetailRoute({ typeId: series.nextBlog.type || '', alias: series.nextBlog.seo?.alias }, appconfig || {}, location) || ''
        }
      : undefined
  };

  const references: IDetail['referenceItems'] = [[]];
  if (attachmentsSubD && attachmentsSubD.filter(attachment => attachment.attachmentFile).length) {
    references[0].push({
      title: 'Anhänge',
      items: attachmentsSubD
        .filter(attachment => attachment.attachmentFile)
        .map((item: any) => toReferenceListItem({ 
          title: 'Download', 
          content: item?.attachmentTitle, 
          url: api.query(api.params(api.v2.communication.downloadFiles, { id: id }), { ids: item?.attachmentFile }),
          download: true,
          anchor: `attachment:id:${item._id}`
        }))
    });
  }
  if (externalLinksSubD && externalLinksSubD.length) {
    references[0].push({
      title: 'Externe Links',
      items: externalLinksSubD.map((item: any) => toReferenceListItem({
        title: item?.linkUrl ? 'externer Link' : 'Quelle',
        content: item?.linkText,
        url: item?.linkUrl,
        anchor: `link:id:${item._id}`
      }))
    });
  }
  if (normSubD && normSubD.length) {
    references[0].push({
      title: 'Normative Verweise',
      items: normSubD.map((item: any) => toReferenceListItem({
        title: item?.normUrl ? 'externer Link' : 'Norm',
        content: item?.normTitle,
        url: item?.normUrl,
        anchor: `normative:id:${item._id}`
      }))
    });
  }
  if (sourcesReferencesSubD && sourcesReferencesSubD.length) {
    references[0].push({
      title: 'Quellen und Referenzen',
      items: sourcesReferencesSubD.map((item: any) => toReferenceListItem({
        title: item?.sourceReferenceUrl ? 'externer Link' : 'Quelle',
        content: item?.sourceReferenceText,
        url: item?.sourceReferenceUrl,
        anchor: `sourceref:id:${item._id}`
      }))
    });
  }

  if (!type || !id) return <></>;

  return (
    <DetailContentComponent
      {...props}
      contentInteractionBar={props.contentInteractionBarProps ? <ContentInteractionBar {...props.contentInteractionBarProps} /> : undefined}
      authors={getAuthor(authorSubD, creator)}
      commentCount={commentCount}
      type={TYPES_PROPS[type]?.Name}
      timestamp={releaseDate || modifiedAt || createdAt}
      readingTime={readingTime}
      title={title || ''}
      subtitle={removeHTMLTagsSpecial(subtitle || '')}
      keyvisual={TYPES_PROPS[type]?.FallbackImage}
      series={seriesProps}
      mainPoints={mainPoints?.map(mP => resolveLinks(mP.mainPoint, location, appconfig || undefined))}
      html={resolveLinks(content, location, appconfig || undefined)}
      referenceItems={references}
      anonymousPublish={anonymousPublish}
      additionalParagraphs={fazit}
      relatedItems={getRelatedFromData(id, data).map(dataset => {
        return {
          title: 'Verwandte Inhalte',
          items: transformForList(
            (dataset || []).map(item => {
              return {
                _source: item,
                _id: item?._id,
                _score: item?._score,
                badge: !!props.unreadNotifications?.find(notification => notification.content?.itemRef === item._id),
                booked: item.type === ThingTypes.News
                  ? (!!item?.content?.url ? props.memoryIds?.includes(item?.content?.url) : false)
                  : props.memoryIds?.includes(item._id),
                noShare: item.type === ThingTypes.OneToOne || ((item.type === ThingTypes.Group || item.type === ThingTypes.Project) && !item.content?.listed)
              };
            })
          )
        };
      })}
      working={working || participationWorking}
    ></DetailContentComponent>
  );
};

export const ConnectedWikiDetail: React.FC<IConntectedContentDetailProps> = props => {
  const anonymousPublish = useSelector(state => state.detail?.item?.content.anonymousPublish);
  const appconfig = useSelector(state => state.foundation.appconfig);
  const attachmentsSubD = useSelector(state => state.detail?.item?.content.attachmentsSubD);
  const authorSubD = useSelector(state => state.detail?.item?.content.authorInfoSubD);
  const commentCount = useSelector(state => state.detail.item?.content?.commentCount);
  const content = useSelector(state => state.detail?.item?.content.content);
  const createdAt = useSelector(state => state.detail?.item?.createdAt);
  const creator = useSelector(state => state.detail?.item?.content.creator);
  const data = useSelector(state => state.data);
  const definition = useSelector(state => state.detail?.item?.content.definition);
  const externalLinksSubD = useSelector(state => state.detail?.item?.content.externalLinksSubD);
  const id = useSelector(state => state.detail?.item?._id);
  const location = useLocation();
  const modifiedAt = useSelector(state => state.detail?.item?.modifiedAt);
  const normSubD = useSelector(state => state.detail?.item?.content.normSubD);
  const participationWorking = useSelector(state => state.communication.participationWorking);
  const procedureResearch = useSelector(state => state.detail?.item?.content.procedureResearch);
  const procedureDevelopment = useSelector(state => state.detail?.item?.content.procedureDevelopment);
  const procedureProduction = useSelector(state => state.detail?.item?.content.procedureProduction);
  const procedureCertification = useSelector(state => state.detail?.item?.content.procedureCertification);
  const procedureRefund = useSelector(state => state.detail?.item?.content.procedureRefund);
  const procedureMarket = useSelector(state => state.detail?.item?.content.procedureMarket);
  const procedureMarketSupervision = useSelector(state => state.detail?.item?.content.procedureMarketSupervision);
  const readingTime = useSelector(state => state.detail?.item?.content.readingTime);
  const releaseDate = useSelector(state => state.detail?.item?.releaseDate);
  const sourcesReferencesSubD = useSelector(state => state.detail?.item?.content.sourcesReferencesSubD);
  const title = useSelector(state => state.detail?.item?.content.title);
  const type = useSelector(state => state.detail?.item?.type);
  const working = useSelector(state => state.detail.working);

  const paragraphs: IDetail['additionalParagraphs'] = [];
  if (procedureResearch) {
    paragraphs.push({ title: 'Forschung', content: resolveLinks(procedureResearch, location, appconfig || undefined) });
  }
  if (procedureDevelopment) {
    paragraphs.push({ title: 'Entwicklung', content: resolveLinks(procedureDevelopment, location, appconfig || undefined) });
  }
  if (procedureProduction) {
    paragraphs.push({ title: 'Produktion', content: resolveLinks(procedureProduction, location, appconfig || undefined) });
  }
  if (procedureCertification) {
    paragraphs.push({ title: 'Zertifizierung', content: resolveLinks(procedureCertification, location, appconfig || undefined) });
  }
  if (procedureRefund) {
    paragraphs.push({ title: 'Erstattung', content: resolveLinks(procedureRefund, location, appconfig || undefined) });
  }
  if (procedureMarket) {
    paragraphs.push({ title: 'Markt', content: resolveLinks(procedureMarket, location, appconfig || undefined) });
  }
  if (procedureMarketSupervision) {
    paragraphs.push({ title: 'Marktüberwachung', content: resolveLinks(procedureMarketSupervision, location, appconfig || undefined) });
  }

  const references: IDetail['referenceItems'] = [[]];
  if (attachmentsSubD && attachmentsSubD.filter(attachment => attachment.attachmentFile).length) {
    references[0].push({
      title: 'Anhänge',
      items: attachmentsSubD
        .filter(attachment => attachment.attachmentFile)
        .map((item: any) => toReferenceListItem({ 
          title: 'Download', 
          content: item?.attachmentTitle, 
          url: api.query(api.params(api.v2.communication.downloadFiles, { id: id }), { ids: item?.attachmentFile }),
          download: true,
          anchor: `attachment:id:${item._id}`
        }))
    });
  }
  if (externalLinksSubD && externalLinksSubD.length) {
    references[0].push({
      title: 'Externe Links',
      items: externalLinksSubD.map((item: any) => toReferenceListItem({
        title: item?.linkUrl ? 'externer Link' : 'Quelle',
        content: item?.linkText,
        url: item?.linkUrl,
        anchor: `link:id:${item._id}`
      }))
    });
  }
  if (normSubD && normSubD.length) {
    references[0].push({
      title: 'Normative Verweise',
      items: normSubD.map((item: any) => toReferenceListItem({
        title: item?.normUrl ? 'externer Link' : 'Norm',
        content: item?.normTitle,
        url: item?.normUrl,
        anchor: `normative:id:${item._id}`
      }))
    });
  }
  if (sourcesReferencesSubD && sourcesReferencesSubD.length) {
    references[0].push({
      title: 'Quellen und Referenzen',
      items: sourcesReferencesSubD.map((item: any) => toReferenceListItem({
        title: item?.sourceReferenceUrl ? 'externer Link' : 'Quelle',
        content: item?.sourceReferenceText,
        url: item?.sourceReferenceUrl,
        anchor: `sourceref:id:${item._id}`
      }))
    });
  }

  if (!type || !id) return <></>;

  return (
    <DetailContentComponent
      {...props}
      contentInteractionBar={props.contentInteractionBarProps ? <ContentInteractionBar {...props.contentInteractionBarProps} /> : undefined}
      authors={getAuthor(authorSubD, creator)}
      commentCount={commentCount}
      type={TYPES_PROPS[type]?.Name}
      timestamp={releaseDate || modifiedAt || createdAt}
      readingTime={readingTime}
      title={title || ''}
      subtitle={removeHTMLTagsSpecial(definition || '')}
      keyvisual={TYPES_PROPS[type]?.FallbackImage}
      html={resolveLinks(content, location, appconfig || undefined)}
      additionalParagraphTitle={!!paragraphs.length ? 'Welche Bedeutung hat das Thema auf den einzelnen Innovationsstufen?' : undefined}
      additionalParagraphs={paragraphs}
      referenceItems={references}
      anonymousPublish={anonymousPublish}
      relatedItems={getRelatedFromData(id, data).map(dataset => {
        return {
          title: 'Verwandte Inhalte',
          items: transformForList(
            (dataset || []).map(item => {
              return {
                _source: item,
                _id: item?._id,
                _score: item?._score,
                badge: !!props.unreadNotifications?.find(notification => notification.content?.itemRef === item._id),
                booked: item.type === ThingTypes.News
                  ? (!!item?.content?.url ? props.memoryIds?.includes(item?.content?.url) : false)
                  : props.memoryIds?.includes(item._id),
                noShare: item.type === ThingTypes.OneToOne || ((item.type === ThingTypes.Group || item.type === ThingTypes.Project) && !item.content?.listed)
              };
            })
          )
        };
      })}
      working={working || participationWorking}
    ></DetailContentComponent>
  );
};

export const ConnectedBestPracticeDetail: React.FC<IConntectedContentDetailProps> = props => {
  const anonymousPublish = useSelector(state => state.detail?.item?.content.anonymousPublish);
  const attachmentsSubD = useSelector(state => state.detail?.item?.content.attachmentsSubD);
  const authorSubD = useSelector(state => state.detail?.item?.content.authorInfoSubD);
  const commentCount = useSelector(state => state.detail.item?.content?.commentCount);
  const content = useSelector(state => state.detail?.item?.content.content);
  const contentBestPracticeSolutions = useSelector(state => state.detail?.item?.content.contentBestPracticeSolutions);
  const contentBestPracticeLearnings = useSelector(state => state.detail?.item?.content.contentBestPracticeLearnings);
  const createdAt = useSelector(state => state.detail?.item?.createdAt);
  const creator = useSelector(state => state.detail?.item?.content.creator);
  const data = useSelector(state => state.data);
  const id = useSelector(state => state.detail?.item?._id);
  const modifiedAt = useSelector(state => state.detail?.item?.modifiedAt);
  const participationWorking = useSelector(state => state.communication.participationWorking);
  const readingTime = useSelector(state => state.detail?.item?.content.readingTime);
  const releaseDate = useSelector(state => state.detail?.item?.releaseDate);
  const title = useSelector(state => state.detail?.item?.content.title);
  const type = useSelector(state => state.detail?.item?.type);
  const working = useSelector(state => state.detail.working);

  const references: IDetail['referenceItems'] = [[]];
  if (attachmentsSubD && attachmentsSubD.filter(attachment => attachment.attachmentFile).length) {
    references.push([
      {
        title: 'Anhänge',
        items: attachmentsSubD
          .filter(attachment => attachment.attachmentFile)
          .map((item: any) => toReferenceListItem({ 
            title: 'Download', 
            content: item?.attachmentTitle, 
            url: api.query(api.params(api.v2.communication.downloadFiles, { id: id }), { ids: item?.attachmentFile }),
            download: true,
            anchor: `attachment:id:${item._id}`
          }))
      }
    ]);
  }

  if (!type || !id) return <></>;

  return (
    <DetailContentComponent
      {...props}
      contentInteractionBar={props.contentInteractionBarProps ? <ContentInteractionBar {...props.contentInteractionBarProps} /> : undefined}
      authors={getAuthor(authorSubD, creator)}
      commentCount={commentCount}
      type={TYPES_PROPS[type]?.Name}
      timestamp={releaseDate || modifiedAt || createdAt}
      readingTime={readingTime}
      title={title || ''}
      keyvisual={TYPES_PROPS[type]?.FallbackImage}
      html={`
        ${content ? '<h2>Herausforderung</h2>' + content : ''}
        ${contentBestPracticeSolutions ? '<h2>Lösungsansatz</h2>' + contentBestPracticeSolutions : ''}
        ${contentBestPracticeLearnings ? '<h2>Lerneffekte</h2>' + contentBestPracticeLearnings : ''}
      `}
      referenceItems={references}
      anonymousPublish={anonymousPublish}
      relatedItems={getRelatedFromData(id, data).map(dataset => {
        return {
          title: 'Verwandte Inhalte',
          items: transformForList(
            (dataset || []).map(item => {
              return {
                _source: item,
                _id: item?._id,
                _score: item?._score,
                badge: !!props.unreadNotifications?.find(notification => notification.content?.itemRef === item._id),
                booked: item.type === ThingTypes.News
                  ? (!!item?.content?.url ? props.memoryIds?.includes(item?.content?.url) : false)
                  : props.memoryIds?.includes(item._id),
                noShare: item.type === ThingTypes.OneToOne || ((item.type === ThingTypes.Group || item.type === ThingTypes.Project) && !item.content?.listed)
              };
            })
          )
        };
      })}
      working={working || participationWorking}
    ></DetailContentComponent>
  );
};

export const ConnectedGroupDetail: React.FC<IConntectedContentDetailProps> = props => {
  const anon = useSelector(state => state.foundation.anon);
  const attachmentsSubD = useSelector(state => state.detail?.item?.content.attachmentsSubD);
  const createdAt = useSelector(state => state.detail?.item?.createdAt);
  const communicationParticipants = useSelector(state => state.communication?.participants);
  const description = useSelector(state => state.detail?.item?.content.description);
  const keyvisualResolved = useSelector(state => state.detail?.item?.content.keyvisualResolved);
  const listed = useSelector(state => state.detail?.item?.content?.listed);
  const itemPublic = useSelector(state => state.detail?.item?.content?.public);
  const modifiedAt = useSelector(state => state.detail?.item?.modifiedAt);
  const participationWorking = useSelector(state => state.communication?.participationWorking);
  const releaseDate = useSelector(state => state.detail?.item?.releaseDate);
  const roles = useSelector(state => state.communication?.communication?.roles);
  const title = useSelector(state => state.detail?.item?.content.title);
  const id = useSelector(state => state.detail?.item?._id);
  const type = useSelector(state => state.detail?.item?.type);
  const working = useSelector(state => state.detail.working);

  const admins = communicationParticipants?.filter(participant => participant.roles.includes(roles?.find(role => role.type === 'administrator')?.roleId || ''));

  const references: IDetail['referenceItems'] = [];
  if (attachmentsSubD && attachmentsSubD.filter(attachment => attachment.attachmentFile).length) {
    const optInUrl = '/optin';
    references.push([
      {
        title: 'Anhänge',
        items: attachmentsSubD
          .filter(attachment => attachment.attachmentFile)
          .map((item: any) => toReferenceListItem({ 
            title: 'Download', 
            content: item?.attachmentTitle, 
            url: anon 
              ? optInUrl 
              : api.query(api.params(api.v2.communication.downloadFiles, { id: id }), { ids: item?.attachmentFile }),
            download: anon ? false : true 
          }))
      }
    ]);
  }
  if (communicationParticipants && !anon) {
    references.push([
      {
        title: 'Administratoren',
        items:
          admins
            ?.sort((a, b) => {
              if (!a.userResolved?.content?.lastName) return 1;
              if (!b.userResolved?.content?.lastName) return -1;
              if (a.userResolved.content.lastName > b.userResolved.content.lastName) return 1;
              if (a.userResolved.content.lastName < b.userResolved.content.lastName) return -1;
              return 0;
            })
            ?.map((item: any) => toProfileListItem({ ...item?.userResolved, type: ThingTypes.Profile }, item?.userResolved?._id, 0)) || []
      }
    ]);
  }

  if (!type) return <></>;

  return (
    <DetailContentComponent
      {...props}
      contentInteractionBar={
        props.contentInteractionBarProps ? (
          <ContentInteractionBar {...props.contentInteractionBarProps} shareClick={listed === false ? undefined : props.contentInteractionBarProps.shareClick} />
        ) : undefined
      }
      authors={admins?.map(a => {
        const res: IContentAuthorProps = {
          name: a.userResolved?.content?.fullName || 'Ehemaliger Benutzer',
          abbreviation: buildAbbreviation(a.userResolved?.content?.fullName || 'Ehemaliger Benutzer'),
          organization: a.userResolved?.content?.organisation,
          avatar: a.userResolved?.content?.avatar,
          id: a.userResolved?.content?.userId,
          generalUserStatus: a.userResolved?.content?.generalUserStatus
        };
        return res;
      })}
      type={TYPES_PROPS[type]?.Name}
      timestamp={releaseDate || modifiedAt || createdAt}
      keyvisual={keyvisualResolved ? { src: keyvisualResolved?.attachmentUrl, alt: 'Hintergrundbild Arbeitsgruppe' } : TYPES_PROPS[type]?.FallbackImage}
      title={title || ''}
      html={description || ''}
      referenceItems={references}
      working={working || participationWorking}
      isHidden={!listed}
      isPublic={itemPublic}
    ></DetailContentComponent>
  );
};

export const ConnectedProjectDetail: React.FC<IConntectedContentDetailProps> = props => {
  const anon = useSelector(state => state.foundation.anon);
  const attachmentsSubD = useSelector(state => state.detail?.item?.content.attachmentsSubD);
  const createdAt = useSelector(state => state.detail?.item?.createdAt);
  const communicationParticipants = useSelector(state => state.communication?.participants);
  const description = useSelector(state => state.detail?.item?.content.description);
  const id = useSelector(state => state.detail?.item?._id);
  const keyvisualResolved = useSelector(state => state.detail?.item?.content.keyvisualResolved);
  const listed = useSelector(state => state.detail?.item?.content?.listed);
  const itemPublic = useSelector(state => state.detail?.item?.content?.public);
  const modifiedAt = useSelector(state => state.detail?.item?.modifiedAt);
  const participationWorking = useSelector(state => state.communication.participationWorking);
  const releaseDate = useSelector(state => state.detail?.item?.releaseDate);
  const roles = useSelector(state => state.communication?.communication?.roles);
  const title = useSelector(state => state.detail?.item?.content.title);
  const type = useSelector(state => state.detail?.item?.type);
  const working = useSelector(state => state.detail.working);

  const admins = communicationParticipants?.filter(participant => participant.roles.includes(roles?.find(role => role.type === 'administrator')?.roleId || ''));

  const references: IDetail['referenceItems'] = [[]];
  if (attachmentsSubD && attachmentsSubD.filter(attachment => attachment.attachmentFile).length) {
    const optInUrl = '/optin';
    references.push([
      {
        title: 'Anhänge',
        items: attachmentsSubD
          .filter(attachment => attachment.attachmentFile)
          .map((item: any) => toReferenceListItem({ 
            title: 'Download', 
            content: item?.attachmentTitle, 
            url: anon 
              ? optInUrl 
              : api.query(api.params(api.v2.communication.downloadFiles, { id: id }), { ids: item?.attachmentFile }),
            download: anon ? false : true 
          }))
      }
    ]);
  }

  if (!type) return <></>;

  if (communicationParticipants && !anon) {
    references.push([
      {
        title: 'Administratoren',
        items:
          admins
            ?.sort((a, b) => {
              if (!a.userResolved?.content?.lastName) return 1;
              if (!b.userResolved?.content?.lastName) return -1;
              if (a.userResolved.content.lastName > b.userResolved.content.lastName) return 1;
              if (a.userResolved.content.lastName < b.userResolved.content.lastName) return -1;
              return 0;
            })
            ?.map((item: any) => toProfileListItem({ ...item?.userResolved, type: ThingTypes.Profile }, item?.userResolved?._id, 0)) || []
      }
    ]);
  }

  return (
    <DetailContentComponent
      {...props}
      contentInteractionBar={
        props.contentInteractionBarProps ? (
          <ContentInteractionBar {...props.contentInteractionBarProps} shareClick={listed === false ? undefined : props.contentInteractionBarProps.shareClick} />
        ) : undefined
      }
      authors={admins?.map(a => {
        const res: IContentAuthorProps = {
          name: a.userResolved?.content?.fullName || 'Ehemaliger Benutzer',
          abbreviation: buildAbbreviation(a.userResolved?.content?.fullName || 'Ehemaliger Benutzer'),
          organization: a.userResolved?.content?.organisation,
          avatar: a.userResolved?.content?.avatar,
          id: a.userResolved?.content?.userId,
        };
        return res;
      })}
      type={TYPES_PROPS[type]?.Name}
      timestamp={releaseDate || modifiedAt || createdAt}
      keyvisual={keyvisualResolved ? { src: keyvisualResolved?.attachmentUrl, alt: 'Hintergrundbild Projekt' } : TYPES_PROPS[type]?.FallbackImage}
      title={title || ''}
      html={description || ''}
      referenceItems={references}
      working={working || participationWorking}
      isHidden={!listed}
      isPublic={itemPublic}
    ></DetailContentComponent>
  );
};

export const ConnectedMeetingDetail: React.FC<IConntectedContentDetailProps> = props => {
  const [openExternalLinkDialog, setOpenExternalLinkDialog] = useState(false);
  const [openExternalLink, setOpenExternalLink] = useState('');
  const dispatch = useDispatch();
  const location = useLocation();
  const appconfig = useSelector(state => state.foundation.appconfig);
  const attachmentsSubD = useSelector(state => state.detail?.item?.content.attachmentsSubD);
  const authorSubD = useSelector(state => state.detail?.item?.content.authorInfoSubD);
  const cancelled = useSelector(state => state.detail?.item?.content.cancelled);
  const meetingUpdateMessage = useSelector(state => state.detail?.item?.content?.meetingUpdateMessage);
  const meetingCancelledMessage = useSelector(state => state.detail?.item?.content?.meetingCancelledMessage);
  const external = useSelector(state => state.detail?.item?.content?.external);
  const meetingLocation = useSelector(state => state.detail?.item?.content?.location);
  const externalLink = useSelector(state => state.detail?.item?.content?.externalLink);
  const commitmentsResolved = useSelector(state => state.detail?.item?.content?.commitmentsResolved);
  const communicationId = useSelector(state => state.communication.id);
  const content = useSelector(state => state.detail?.item?.content.content);
  const creator = useSelector(state => state.detail?.item?.content.creator);
  const detailId = useSelector(state => state.detail?.item?._id);
  const interval = useSelector(state => state.detail?.item?.content?.interval);
  const keyvisualResolved = useSelector(state => state.detail?.item?.content.keyvisualResolved);
  const listed = useSelector(state => state.detail?.item?.content?.parentThingResolved?.[0]?.content?.listed);
  const parentThing = useSelector(state => state.detail?.item?.content.parentThingResolved?.[0]);
  const participants = useSelector(state => state.communication.participants);
  const participation = useSelector(state => state.communication.participation);
  const title = useSelector(state => state.detail?.item?.content.title);
  const type = useSelector(state => state.detail?.item?.type);
  const userId = useSelector(state => state.foundation.profile?.userId);
  const working = useSelector(state => state.detail.working);
  const conferenceMessage = useSelector(state => state.detail?.adhocConferencing);

  /* extra content */
  let extraContent: ReactNode = undefined;
  const now = dayjs().unix();
  const meetingStartTimestamp = interval?.dateFrom || 0;
  const meetingEndTimestamp = interval?.dateTo || 0;
  const conferenceEndTimestamp = (conferenceMessage?.conferenceInfo?.ended || 0) / 1000;
  const conferenceAvailableBeforeMinutes = 60 * 30; // 30 mins
  const meetingAndConferenceHaveEnded = now > meetingEndTimestamp && now > conferenceEndTimestamp && (!conferenceMessage || conferenceEndTimestamp);
  const isBeforeMeeting = now < meetingStartTimestamp - conferenceAvailableBeforeMinutes;
  const meetingIsOngoing = now > meetingStartTimestamp - conferenceAvailableBeforeMinutes && ((conferenceMessage && !conferenceEndTimestamp) || now < meetingEndTimestamp);

  useEffect(() => {
    detailId && dispatch(fetchParticipantsRequest({ id: detailId }));
  }, [dispatch, detailId]);

  if (!type) return <></>;

  const references: IDetail['referenceItems'] = [[], []];

  if (attachmentsSubD && attachmentsSubD.filter(attachment => attachment.attachmentFile).length) {
    references[0].push({
      title: 'Anhänge',
      items: attachmentsSubD
        .filter(attachment => attachment.attachmentFile)
        .map((item: any) =>
          toReferenceListItem({
            title: 'Anhang',
            content: item?.attachmentTitle,
            url: api.query(api.params(api.v2.communication.downloadFiles, { id: detailId }), { ids: item?.attachmentFile }),
            download: true
          })
        )
    });
  }

  if (isBeforeMeeting || meetingIsOngoing) {
    if (participants && participants.length && participants.filter(p => p?.userResolved).length) {
      const ownParticipation = participants.find(p => p.user === userId && p.status === ParticipationRequestStatus.ACCEPTED);
      const participations = participants.filter(p => p.user !== userId && p.status === ParticipationRequestStatus.ACCEPTED);
      if (ownParticipation) {
        participations.unshift(ownParticipation);
      }
      references[1].push({
        title: 'Zusagen',
        items: transformForList(
          participations.map(item => {
            return {
              _source: {
                ...item.userResolved,
                type: ThingTypes.Profile,
                content: { ...item.userResolved.content, title: '' }
              },
              _id: item.userResolved?._id,
              _score: 0
            };
          })
        ),
        emptyCaption: 'Noch keine Zusagen vorhanden'
      });
    }
  } else {
    if (commitmentsResolved && commitmentsResolved.length && commitmentsResolved.filter(x => !!x).length) {
      const ownCommitment = commitmentsResolved.find(c => c.content.userId === userId);
      const commitments = commitmentsResolved.filter(c => c.content.userId !== userId);
      if (ownCommitment) commitments.unshift(ownCommitment);
      references[1].push({
        title: 'Teilnehmende',
        items: transformForList(
          commitments.map(item => {
            return { _source: { ...item, content: { ...item.content, title: '' } }, _id: item?._id, _score: 0 };
          })
        ),
        initialMaxItems: 3
      });
    }
  }

  const commitButtonOnClick = () => {
    if (detailId && communicationId && userId) {
      participation && participation.type !== 'passive' && dispatch(leaveCommunicationRequest({ id: detailId, resubscribe: true }));
      (!participation || participation.type === 'passive') && dispatch(requestParticipationRequest({ id: detailId, message: '', reloadParticipants: true }));
    }
  };

  const joinCreateConferenceOnClick = () => {
    if (conferenceMessage) {
      // conference already
      if (now < meetingEndTimestamp) {
        // can still join
        dispatch(joinConferenceRequest({ message: conferenceMessage }));
      }
    } else {
      // start conference
      detailId && dispatch(createConferenceMessageRequest({ id: 'conference', communicationId: detailId, title }));
    }
  };

  const showDiscussionOnClick = () => {
    // conference ended show discussion
    if (conferenceMessage) {
      props.onRequestActionCanvas?.(conferenceMessage);
    }
  };

  var afterAuthorContent: ReactNode | null = null;
  const downloadIcsOnClick = async () => {
    const apiUrl = api.params(api.v2.meetings.ics, { meetingId: detailId });
    // no csrf token needed, does not manipulate data
    await fetch(apiUrl).then(response => {
      response
        .text()
        .then(result => {
          const blob = new Blob([result], { type: 'text/calendar' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          document.body.appendChild(link);
          const dlName = encodeURIComponent('MTO-Meeting_' + title + '.ics');
          link.download = dlName;
          link.target = '_blank';
          link.href = url;
          link.click();
          document.body.removeChild(link);
        })
        .catch(() => {
          alert('Download failed, please contact Admin!');
        });
    });
  };

  if ((meetingLocation || externalLink) && external) {
    let data;
    if (externalLink) {
      data = resolveMessageContentLinks(externalLink || '');
    } else {
      data = resolveMessageContentLinks(meetingLocation || '');
    }
    afterAuthorContent = (
      <Bx mb={3}>
        {!!externalLink && (
          <>
            <Typo>Externes Online-Meeting:</Typo>
            <Typo>
              <MeetingMessageTextContent data={data} />
            </Typo>
            <br />
            <Typo>Hinweis:</Typo>
            <Typo>
              Dieses Online-Meeting wird nicht über einen Dienst auf Medtec Online angeboten. Es handelt sich um einen Dienst eines externen Anbieters. Der Link wird von der
              einladenden Person zur Verfügung gestellt. Nur diese ist verantwortlich für den Link, den dahinterstehenden Link, dessen Inhalt und etwaige Dienste dort.
            </Typo>
          </>
        )}
        {!!meetingLocation && <Typo>Ort: {<MeetingMessageTextContent data={data} />}</Typo>}
      </Bx>
    );
  }

  if (meetingStartTimestamp && meetingEndTimestamp && !meetingAndConferenceHaveEnded) {
    if (isBeforeMeeting) {
      if (participation) {
        var icon: IconProp | null = null;
        var caption: string | null = null;

        if (participation.type === ParticipationRequestType.PUBLIC) {
          // nutzer ist öffentlicher -> Muss erst der Gruppe beitreten
          const isGroup = parentThing?.type === ThingTypes.Group;
          const isProject = parentThing?.type === ThingTypes.Project;
          if (isGroup || isProject) {
            const parentCaption = isGroup ? 'der Arbeitsgruppe' : isProject ? 'dem Projekt' : undefined;
            const parentLink = getDetailRoute({ typeId: parentThing?.type || '', alias: parentThing?.seo?.alias }, appconfig || {}, location);

            if (parentCaption && parentLink && !cancelled) {
              afterAuthorContent = (
                <Bx mb={3}>
                  <Typo>
                    Um an diesem Meeting teilnehmen zu können, müssen Sie erst {parentCaption}
                    <Link to={parentLink || '/'} underline="hover">
                      <Typo color="error.light" component="span" textTransform="uppercase">
                        {' ' + parentThing?.content.title + ' '}
                      </Typo>
                    </Link>
                    beitreten.
                  </Typo>
                </Bx>
              );
            }
          }
        } else {
          if (!cancelled && !external) {
            afterAuthorContent = (
              <Bx mb={3}>
                <Typo>Der Beitritt zur Videokonferenz ist frühestens 30 Min. vor Beginn des Meetings möglich.</Typo>
              </Bx>
            );
          }
          if (participation.status === ParticipationRequestStatus.ACCEPTED) {
            // nutzer ist teilnehmer -> "Zusage zurückziehen"
            icon = faCalendarMinus;
            caption = 'Zusage zurückziehen';
          } else if (participation.status === ParticipationRequestStatus.PENDING) {
            // nutzer ist eingeladen -> "Einladung akzeptieren"
            // blauer kasten über dem meeting übernimmt "annehmen" / "ablehnen"
          } else if (participation.status === ParticipationRequestStatus.PASSIVE) {
            // nutzer ist teilnehmer der gruppe -> "Zusagen"
            icon = faCalendarCheck;
            caption = 'Zusagen';
          }
        }

        extraContent = (
          <>
            {icon && caption && (
              <Bx mr={1} display="flex" justifyContent="center" alignItems="center">
                <Hidden smDown>
                  <Btn onClick={commitButtonOnClick} color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={icon} />}>
                    {caption}
                  </Btn>
                </Hidden>
                <Hidden mdUp>
                  <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" onClick={commitButtonOnClick}>
                    <FontAwesomeIcon icon={icon} />
                  </IconBtn>
                </Hidden>
              </Bx>
            )}
            <Bx mr={1} display="flex" justifyContent="center" alignItems="center">
              <Hidden smDown>
                <Btn onClick={downloadIcsOnClick} color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faDownload} />}>
                  Termin .ics
                </Btn>
              </Hidden>
              <Hidden mdUp>
                <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" onClick={downloadIcsOnClick}>
                  <FontAwesomeIcon icon={faDownload} />
                </IconBtn>
              </Hidden>
            </Bx>
            {!!!external && (
              <Bx display="flex" justifyContent="center" alignItems="center">
                <Hidden smDown>
                  <Btn onClick={joinCreateConferenceOnClick} disabled color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faCameraHome} />}>
                    Videokonferenz beitreten
                  </Btn>
                </Hidden>
                <Hidden mdUp>
                  <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" disabled onClick={joinCreateConferenceOnClick}>
                    <FontAwesomeIcon icon={faCameraHome} />
                  </IconBtn>
                </Hidden>
              </Bx>
            )}
            {!!external && !!externalLink && (
              <Bx display="flex" justifyContent="center" alignItems="center">
                <Hidden smDown>
                  <Btn
                    startIcon={<FontAwesomeIcon icon={faExternalLink} />}
                    onClick={() => {
                      setOpenExternalLink(externalLink);
                      setOpenExternalLinkDialog(true);
                      return false;
                    }}
                    disabled
                    color="secondary"
                    size="large"
                    variant="contained"
                  >
                    Externe Videokonferenz öffnen
                  </Btn>
                </Hidden>
                <Hidden mdUp>
                  <IconBtn
                    color="secondary"
                    size="medium"
                    variant="contained"
                    shape="rounded"
                    disabled
                    onClick={() => {
                      setOpenExternalLink(externalLink);
                      setOpenExternalLinkDialog(true);
                      return false;
                    }}
                  >
                    <FontAwesomeIcon icon={faExternalLink} />
                  </IconBtn>
                </Hidden>
              </Bx>
            )}
          </>
        );
      } else if (!cancelled) {
        // nutzer ist kein teilnehmer
        const isGroup = parentThing?.type === ThingTypes.Group;
        const isProject = parentThing?.type === ThingTypes.Project;
        const parentCaption = isGroup ? 'der Arbeitsgruppe' : isProject ? 'dem Projekt' : undefined;
        const parentLink = getDetailRoute({ typeId: parentThing?.type || '', alias: parentThing?.seo?.alias }, appconfig || {}, location);

        afterAuthorContent = (
          <Bx mb={3}>
            <Typo>
              Um an diesem Meeting teilnehmen zu können, müssen Sie erst {parentCaption}
              <Link to={parentLink || '/'} underline="hover">
                <Typo color="error.light" component="span" textTransform="uppercase">
                  {' ' + parentThing?.content.title + ' '}
                </Typo>
              </Link>
              beitreten.
            </Typo>
          </Bx>
        );
      }
    } else if (meetingIsOngoing && participation) {
      extraContent = (
        <>
          <Bx mr={1} display="flex" justifyContent="center" alignItems="center">
            <Hidden smDown>
              <Btn onClick={downloadIcsOnClick} color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faDownload} />}>
                Termin .ics
              </Btn>
            </Hidden>
            <Hidden mdUp>
              <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" onClick={downloadIcsOnClick}>
                <FontAwesomeIcon icon={faDownload} />
              </IconBtn>
            </Hidden>
          </Bx>
          {!!!external && (
            <Bx mr={1} display="flex" justifyContent="center" alignItems="center">
              <Hidden smDown>
                <Btn onClick={joinCreateConferenceOnClick} color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faCameraHome} />}>
                  Videokonferenz beitreten
                </Btn>
              </Hidden>
              <Hidden mdUp>
                <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" onClick={joinCreateConferenceOnClick}>
                  <FontAwesomeIcon icon={faCameraHome} />
                </IconBtn>
              </Hidden>
            </Bx>
          )}
          {!!external && !!externalLink && (
            <Bx display="flex" justifyContent="center" alignItems="center">
              <Hidden smDown>
                <Btn
                  startIcon={<FontAwesomeIcon icon={faExternalLink} />}
                  onClick={() => {
                    setOpenExternalLink(externalLink);
                    setOpenExternalLinkDialog(true);
                    return false;
                  }}
                  color="secondary"
                  size="large"
                  variant="contained"
                >
                  Externe Videokonferenz öffnen
                </Btn>
              </Hidden>
              <Hidden mdUp>
                <IconBtn
                  color="secondary"
                  size="medium"
                  variant="contained"
                  shape="rounded"
                  onClick={() => {
                    setOpenExternalLink(externalLink);
                    setOpenExternalLinkDialog(true);
                    return false;
                  }}
                >
                  <FontAwesomeIcon icon={faExternalLink} />
                </IconBtn>
              </Hidden>
            </Bx>
          )}
        </>
      );
    } else if (meetingIsOngoing && participation && !!external) {
      extraContent = (
        <Bx mr={1} display="flex" justifyContent="center" alignItems="center">
          <Hidden smDown>
            <Btn onClick={downloadIcsOnClick} color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faDownload} />}>
              Termin .ics
            </Btn>
          </Hidden>
          <Hidden mdUp>
            <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" onClick={downloadIcsOnClick}>
              <FontAwesomeIcon icon={faDownload} />
            </IconBtn>
          </Hidden>
        </Bx>
      );
    }
  } else if (conferenceMessage && participation && !!!external) {
    extraContent = (
      <>
        <Hidden smDown>
          <Btn onClick={showDiscussionOnClick} color="secondary" size="large" variant="contained" startIcon={<FontAwesomeIcon icon={faComment} />}>
            Diskussion
          </Btn>
        </Hidden>
        <Hidden mdUp>
          <IconBtn color="secondary" size="medium" variant="contained" shape="rounded" onClick={showDiscussionOnClick}>
            <FontAwesomeIcon icon={faComment} />
          </IconBtn>
        </Hidden>
      </>
    );
  }

  return (
    <>
      <DetailContentComponent
        {...props}
        afterAuthorContent={afterAuthorContent}
        contentInteractionBar={
          props.contentInteractionBarProps && !cancelled && !!participation ? (
            <ContentInteractionBar
              {...props.contentInteractionBarProps}
              editClick={!meetingAndConferenceHaveEnded ? props.contentInteractionBarProps.editClick : undefined}
              shareClick={listed === false ? undefined : props.contentInteractionBarProps.shareClick}
              inviteClick={!meetingAndConferenceHaveEnded ? props.contentInteractionBarProps.inviteClick : undefined}
              extraContent={extraContent}
            />
          ) : undefined
        }
        appconfig={appconfig || undefined}
        authors={getAuthor(authorSubD, creator)}
        html={resolveLinks(content, location, appconfig || undefined)}
        interval={cancelled ? undefined : interval}
        keyvisual={keyvisualResolved ? { src: keyvisualResolved?.attachmentUrl, alt: 'Hintergrundbild Meeting' } : undefined}
        parentThing={parentThing}
        referenceItems={references}
        title={title || ''}
        subtitle={cancelled ? 'Abgesagt' : undefined}
        cancelled={cancelled || !!meetingAndConferenceHaveEnded}
        meetingUpdateMessage={meetingUpdateMessage}
        meetingCancelledMessage={meetingCancelledMessage}
        type={(external ? TYPES_PROPS[type]?.Alt?.Name : TYPES_PROPS[type]?.Name) || ''}
        working={working}
      ></DetailContentComponent>
      <OpenExternalLinkDialog
        open={openExternalLinkDialog}
        headerText={'Sie verlassen Medtec Online'}
        noteToUserText={
          'Bitte beachten Sie, dass dieser Link den Dienst eines externen Anbieters öffnet, für deren Inhalt wir nicht verantwortlich sind und auf den weder unsere Nutzungsbedingungen noch unsere Datenschutzbestimmungen Anwendung finden.'
        }
        labelText={'Fortsetzen'}
        link={openExternalLink}
        noMemorySetting={true}
        onCanceled={() => {
          setOpenExternalLinkDialog(false);
        }}
      />
    </>
  );
};

export const ConnectedCollectionDetail: React.FC<IConntectedContentDetailProps> = props => {
  const history = useHistory();
  const dispatch = useDispatch();

  const appconfig = useSelector(state => state.foundation.appconfig);
  const anon = useSelector(state => state.foundation.anon);
  const contactsResolved = useSelector(state => state.detail?.item?.content?.contactsResolved);
  const content = useSelector(state => state.detail?.item?.content?.content);
  const downloadSubD = useSelector(state => state.detail?.item?.content?.downloadSubD);
  const forumsResolved = useSelector(state => state.detail?.item?.content?.forumsResolved);
  const groupsResolved = useSelector(state => state.detail?.item?.content?.groupsResolved);
  const id = useSelector(state => state.detail?.item?._id);
  const location = useLocation();
  const magazinesResolved = useSelector(state => state.detail?.item?.content?.magazinesResolved);
  const participationWorking = useSelector(state => state.communication.participationWorking);
  const strategicDialog = useSelector(state => state.detail?.item?.content?.strategicDialog);
  const title = useSelector(state => state.detail?.item?.content?.title);
  const type = useSelector(state => state.detail?.item?.type);
  const working = useSelector(state => state.detail.working);
  const notifications = useSelector(state => state.notifications?.items);
  const memoryList = useSelector(state => state.foundation?.profile?.memoryList);

  const [unreadNotifications, setUnreadNotifications] = useState<Array<INotification>>([]);

  const dispatcher = {
    shareClick: (detailItem) => {
      dispatch(setFlowDataAction({
        alias: 'share',
        flowData: {
          location: history.location,
          itemData: { sharedItem: detailItem }
        }
      }));
      history.push(history.location.pathname + '/flow' + history.location.search);
    }
  };

  useEffect(() => {
    if (notifications && notifications.length) {
      setUnreadNotifications(notifications.filter(notification => !notification.content?.notificationRead))
    }
  }, [notifications]);

  const memoryIds = memoryList?.map(m => m.typeId === ThingTypes.News ? (m.externalId ?? '') : m.elementId);

  const references: IDetail['referenceItems'] = [[], []];
  if (groupsResolved && groupsResolved.length && groupsResolved.filter(x => !!x).length) {
    references[0].push({
      title: 'Communities',
      items: transformForList(
        (groupsResolved || []).map(item => {
          return {
            _source: item,
            _id: item?._id,
            _score: 0,
            badge: !!unreadNotifications.find(notification => notification.content?.itemRef === item._id),
            booked: item.type === ThingTypes.News
              ? (!!item?.content?.url ? memoryIds?.includes(item?.content?.url) : false)
              : memoryIds?.includes(item._id),
            noShare: item.type === ThingTypes.OneToOne || ((item.type === ThingTypes.Group || item.type === ThingTypes.Project) && !item.content?.listed),
          };
        })
      ),
      itemMemoClicked: (item, type) => dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type })),
      shareClick: dispatcher.shareClick
    });
  }
  if (forumsResolved && forumsResolved.length) {
    references[0].push({
      title: 'Beiträge aus dem Forum',
      replaceLinks: anon ? '/optin' : undefined,
      items: transformForList(
        (forumsResolved || []).map(item => {
          return {
            _source: item,
            _id: item?._id,
            _score: 0,
            badge: !!unreadNotifications.find(notification => notification.content?.itemRef === item._id),
            booked: item.type === ThingTypes.News
              ? (!!item?.content?.url ? memoryIds?.includes(item?.content?.url) : false)
              : memoryIds?.includes(item._id),
            noShare: item.type === ThingTypes.OneToOne || ((item.type === ThingTypes.Group || item.type === ThingTypes.Project) && !item.content?.listed),
          };
        })
      ),
      itemMemoClicked: (item, type) => dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type })),
      shareClick: dispatcher.shareClick
    });
  }
  if (magazinesResolved && magazinesResolved.length) {
    references[0].push({
      title: 'Beiträge aus dem Magazin',
      items: transformForList(
        (magazinesResolved || []).map(item => {
          return {
            _source: item,
            _id: item?._id,
            _score: 0,
            badge: !!unreadNotifications.find(notification => notification.content?.itemRef === item._id),
            booked: item.type === ThingTypes.News
              ? (!!item?.content?.url ? memoryIds?.includes(item?.content?.url) : false)
              : memoryIds?.includes(item._id),
            noShare: item.type === ThingTypes.OneToOne || ((item.type === ThingTypes.Group || item.type === ThingTypes.Project) && !item.content?.listed),
          };
        })
      ),
      itemMemoClicked: (item, type) => dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type })),
      shareClick: dispatcher.shareClick
    });
  }
  if (downloadSubD && downloadSubD.length) {
    references[1].push({
      title: 'Downloads',
      items: downloadSubD
        .filter(attachment => attachment.attachmentFile)
        .map((item: any) => toReferenceListItem({ 
          title: 'Download', 
          content: item?.attachmentTitle, 
          url: api.query(api.params(api.v2.communication.downloadFiles, { id: id }), { ids: item?.attachmentFile }),
          download: true 
        })),
      itemMemoClicked: (item, type) => dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type })),
      shareClick: dispatcher.shareClick
    });
  }
  if (contactsResolved && contactsResolved.length) {
    references[1].push({
      title: 'Ansprechpartner',
      items: transformForList(
        (contactsResolved || []).map(item => {
          return {
            _source: item,
            _id: item?._id,
            _score: 0,
            badge: !!unreadNotifications.find(notification => notification.content?.itemRef === item._id),
            booked: item.type === ThingTypes.News
              ? (!!item?.content?.url ? memoryIds?.includes(item?.content?.url) : false)
              : memoryIds?.includes(item._id),
            noShare: item.type === ThingTypes.OneToOne || ((item.type === ThingTypes.Group || item.type === ThingTypes.Project) && !item.content?.listed),
          };
        })
      ),
      itemMemoClicked: (item, type) => dispatch(userprofileMemorylistRequestAction({ memoryItem: item, type })),
      shareClick: dispatcher.shareClick
    });
  }

  if (!type) return <></>;

  return (
    <DetailContentComponent
      {...props}
      contentInteractionBar={props.contentInteractionBarProps ? <ContentInteractionBar {...props.contentInteractionBarProps} /> : undefined}
      type={GetThingTypeName(type, strategicDialog)}
      title={title || ''}
      keyvisual={strategicDialog ? TYPES_PROPS[type]?.FallbackImage2 : TYPES_PROPS[type]?.FallbackImage}
      html={resolveLinks(content, location, appconfig || undefined)}
      referenceItems={references}
      working={working || participationWorking}
    ></DetailContentComponent>
  );
};

export const ConnectedContentDetail: React.FC<IConntectedContentDetailProps> = props => {
  const content = useSelector(state => state.detail?.item?.content.content);
  const type = useSelector(state => state.detail?.item?.type);
  const title = useSelector(state => state.detail?.item?.content.title);

  if (!type) return <></>;

  return <DetailContentComponent {...props} type={TYPES_PROPS[type]?.Name} title={title || ''} html={content || ''}></DetailContentComponent>;
};

const useStyles = makeStyles(theme => ({
  externalLink: {
    display: 'inline',
    padding: 0,
    fontSize: 'inherit',
    justifyContent: 'left',
    border: 'none !important',
    borderRadius: 0,
    fontWeight: 400,
    textAlign: 'initial',
    textDecoration: 'underline',
    verticalAlign: 'baseline',
    '&:hover': {
      textDecoration: 'none',
      background: 'none'
    }
  }
}));

export interface IMeetingMessageTextContentProps {
  data: IResolvedMessageContent;
}

const MeetingMessageTextContent: React.FC<IMeetingMessageTextContentProps> = ({ data }) => {
  const [openExternalLinkDialog, setOpenExternalLinkDialog] = useState(false);
  const classes = useStyles();
  const [openExternalLink, setOpenExternalLink] = useState('');
  const resultArray = Array<React.ReactFragment>();
  for (let i = 0; i < data.content.length; i++) {
    const linkEndRegExp = /( |,|, |\.|\. )$/gim;
    if (!data.links.length || data.links.length === 0) {
      resultArray.push(data.content[i]);
    } else {
      if (data.content[i]) {
        resultArray.push(data.content[i]);
      }
    }
    let punctuationAfterLink = '';
    let linkReplace = '';
    if (data.links.length > i) {
      linkReplace = data.links[i];
      const match = linkEndRegExp.exec(linkReplace);
      if (match && match[0] && match[0] !== ' ') {
        punctuationAfterLink = match[0];
        linkReplace = linkReplace.replace(linkEndRegExp, '');
      }
      if (!linkReplace) {
        continue;
      }
      const extLink = '//' + linkReplace.replace(/(http|https):\/\//, '');
      resultArray.push(
        <>
          <Btn
            href={undefined}
            variant="text"
            className={clsx(classes.externalLink)}
            onClick={() => {
              setOpenExternalLink(extLink);
              setOpenExternalLinkDialog(true);
              return false;
            }}
          >
            {linkReplace}
            &nbsp;&nbsp;
            <FontAwesomeIcon icon={faExternalLink} />
          </Btn>
          {!!punctuationAfterLink && punctuationAfterLink}
          &nbsp;&nbsp;
        </>
      );
    }
  }

  return (
    <>
      {resultArray}
      <OpenExternalLinkDialog
        open={openExternalLinkDialog}
        headerText={'Sie verlassen Medtec Online'}
        noteToUserText={
          'Bitte beachten Sie, dass dieser Link den Dienst eines externen Anbieters öffnet, für deren Inhalt wir nicht verantwortlich sind und auf den weder unsere Nutzungsbedingungen noch unsere Datenschutzbestimmungen Anwendung finden.'
        }
        labelText={'Fortsetzen'}
        link={openExternalLink}
        noMemorySetting={true}
        onCanceled={() => {
          setOpenExternalLinkDialog(false);
        }}
      />
    </>
  );
};

import { createAction } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { IProfileMessagingPermissions } from '../../../model/profile';

export const setFlowDataAction = createAction<{ alias: string; flowData: any }, 'SET_FLOW_DATA'>('SET_FLOW_DATA');
export const setFlowFormDataAction = createAction<{ formField: string; formValue: any; formName?: string }, 'SET_FLOW_FORM_DATA'>('SET_FLOW_FORM_DATA');
export const setFlowAliasAction = createAction<{ alias: string; flowSteps: any[] }, 'SET_FLOW_ALIAS'>('SET_FLOW_ALIAS');
export const setFlowAnonymousAction = createAction<{ anonymous: boolean }, 'SET_FLOW_ANONYMOUS'>('SET_FLOW_ANONYMOUS');
export const setFlowOptionAction = createAction<{ option: string; remove?: boolean }, 'SET_FLOW_OPTION'>('SET_FLOW_OPTION');
export const setFlowStepAction = createAction<{ step: number }, 'SET_FLOW_STEP'>('SET_FLOW_STEP');
export const setFlowTypeAction = createAction<{ type: string }, 'SET_FLOW_TYPE'>('SET_FLOW_TYPE');
export const resetFlowAction = createAction<{}, 'RESET_FLOW'>('RESET_FLOW');

export const finishFlowRequestAction = createAction<{ history: ReturnType<typeof useHistory> }, 'FINISH_FLOW_REQUEST'>('FINISH_FLOW_REQUEST');
export const finishFlowErrorAction = createAction<{ message: string }, 'FINISH_FLOW_ERROR'>('FINISH_FLOW_ERROR');
export const finishFlowSuccessAction = createAction<{ finishUrl: string }, 'FINISH_FLOW_SUCCESS'>('FINISH_FLOW_SUCCESS');

export const fetchEmployerConfigsRequestAction = createAction<{}, 'FETCH_EMPLOYER_CONFIGS_REQUEST'>('FETCH_EMPLOYER_CONFIGS_REQUEST');
export const fetchEmployerConfigsSuccessAction = createAction<{ result: any }, 'FETCH_EMPLOYER_CONFIGS_SUCCESS'>('FETCH_EMPLOYER_CONFIGS_SUCCESS');
export const fetchEmployerConfigsErrorAction = createAction<{ message?: string }, 'FETCH_EMPLOYER_CONFIGS_ERROR'>('FETCH_EMPLOYER_CONFIGS_ERROR');

export const validateRegisterTokenAction = createAction<{ token: string }, 'VALIDATE_REGISTER_TOKEN'>('VALIDATE_REGISTER_TOKEN');

export const userProfileRequestValidAction = createAction<{ tokenInfo: any; messagingPermissions?: IProfileMessagingPermissions }, 'USER_PROFILE_REQUEST_VALID'>(
  'USER_PROFILE_REQUEST_VALID'
);
export const userProfileRequestNotValidAction = createAction<{ alias?: string }, 'USER_PROFILE_REQUEST_NOT_VALID'>('USER_PROFILE_REQUEST_NOT_VALID');

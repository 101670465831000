import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPageElement } from '../../../model/data/PageElement';
import { IProfile } from '../../../model/profile';
import { ISearchResult } from '../../../model/search/SearchResult';
import { useHistory } from 'react-router-dom';
import { fetchMessageByIdSuccess } from '../communication';
import { IDetailItem } from '../../../model/detail';
import { IMessageModel } from '../../../model/communication/Message';
import { ThingTypes } from '../../../model/ryve/Thing';
import { IParticipantResolvedModel } from '../../../model/communication/Participant';
export interface IDetailState {
  working: boolean;
  error: boolean;
  errorMessage?: string;
  errorCode?: number;
  item: IDetailItem | null;
  formData: any;
  children: { working: boolean; items: Array<any> };
  update: boolean;
  config: any;
  meetingParticipants: { meetingId?: string; working: boolean; items?: IParticipantResolvedModel[] };
  adhocConferencing: IMessageModel | null;
  updatePassSuccess: boolean;
  updatePassError: boolean;
}

const initialState: IDetailState = {
  working: false,
  error: false,
  errorMessage: undefined,
  errorCode: undefined,
  item: null,
  formData: null,
  children: { working: false, items: [] },
  update: false,
  config: null,
  meetingParticipants: { working: false },
  adhocConferencing: null,
  updatePassSuccess: false,
  updatePassError: false,
};

const detail = createSlice({
  name: 'detail',
  initialState,
  reducers: {
    fetchDetailRequest: (state, action: PayloadAction<{ type: string; alias: string }>) => {
      state.working = true;
      state.error = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
      state.item = null;
    },
    fetchDetailSuccess: (state, action: PayloadAction<any>) => {
      state.working = false;
      state.error = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
      state.item = action.payload;
    },
    fetchDetailFailed: (state, action: PayloadAction<{ errorMessage?: string; errorCode?: number }>) => {
      state.working = false;
      state.error = true;
      state.errorMessage = action.payload.errorMessage;
      state.errorCode = action.payload.errorCode;
      state.item = null;
    },
    fetchLPDetailRequest: (state, action: PayloadAction<{ typeId: string; alias: string }>) => {
      state.working = true;
      state.error = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
      state.item = null;
    },
    fetchRelatedEntriesRequest: (state, action: PayloadAction<{ pageElement: IPageElement }>) => {
      if (!state.item) return;
      state.item.content.related = {
        ...state.item.content.related,
        working: true,
      };
    },
    fetchRelatedEntriesSuccess: (state, action: PayloadAction<{ result: ISearchResult }>) => {
      if (!state.item) return;
      state.item.content.related = {
        working: false,
        total: action.payload?.result?.hits?.total,
        hits: [...(state.item?.content?.related?.hits || []), ...(action.payload?.result?.hits?.hits || [])],
      };
    },
    fetchRelatedEntriesFailed: (state, action: PayloadAction<{ message: string }>) => {
      if (!state.item) return;
      state.item.content.related = {
        ...state.item.content.related,
        working: false,
      };
    },
    fetchMeetingParticipantsRequest: state => {
      if (state.item?.type !== ThingTypes.Meeting) return;
      state.meetingParticipants.meetingId = state.item._id;
      state.meetingParticipants.working = true;
      state.meetingParticipants.items = [];
    },
    fetchMeetingParticipantsSuccess: (state, action: PayloadAction<{ participantsResolved: IParticipantResolvedModel[] }>) => {
      if (state.item?.type !== ThingTypes.Meeting) return;
      state.meetingParticipants.working = false;
      state.meetingParticipants.items = action.payload.participantsResolved;
    },
    fetchMeetingParticipantsFailed: (state, action: PayloadAction<{ error?: string }>) => {
      if (state.item?.type !== ThingTypes.Meeting) return;
      state.meetingParticipants.working = false;
      state.meetingParticipants.items = [];
    },
    updateVotingRequest: (state, action: PayloadAction<{ voting: Number }>) => {},
    updateVotingSuccess: (state, action: PayloadAction<any>) => {
      if (!state.item) return;
      state.item.content.voting = action.payload.result.content.voting;
      state.item.content.userVoting = action.payload.result.content.userVoting;
    },
    updateVotingFailed: (state, action: PayloadAction<any>) => {},
    fetchMeetingsRequest: (state, action: PayloadAction<{ parentId: string }>) => {
      state.children.working = true;
    },
    fetchMeetingsSuccess: (state, action: PayloadAction<any>) => {
      state.children.working = false;
      state.children.items = action.payload.result;
    },
    fetchMeetingsFailed: (state, action: PayloadAction<{}>) => {
      state.children.working = false;
      state.children.items = [];
    },
    updateCommitmentRequest: (state, action: PayloadAction<{ commit: boolean }>) => {},
    updateCommitmentSuccess: (state, action: PayloadAction<any>) => {
      if (!state.item) return;
      state.item.content.commitmentsResolved = action.payload.result.content.commitmentsResolved;
    },
    updateCommitmentFailed: (state, action: PayloadAction<any>) => {},
    updateDetailRequest: (
      state,
      action: PayloadAction<{
        history: ReturnType<typeof useHistory>;
        customMessage?: string;
      }>
    ) => {
      state.update = true;
    },
    updateDetailSuccess: (state, action: PayloadAction<any>) => {
      state.item = action.payload.result;
    },
    updateDetailFailed: (state, action: PayloadAction<any>) => {},
    setDetailUpdate: (state, action: PayloadAction<{ update: boolean }>) => {
      state.update = action.payload.update;
    },
    updateFormData: (state, action: PayloadAction<{ formData: any }>) => {
      state.formData = action.payload.formData;
    },
    updateFormDataAttribute: (state, action: PayloadAction<{ formField: string; formValue: any; formName?: string }>) => {
      const formField = action.payload.formField;
      state.formData = { ...state.formData, itemData: { ...(state.formData?.itemData || {}), [formField]: action.payload.formValue } };
    },
    resetFormData: (state, action: PayloadAction<any>) => {
      state.formData = null;
    },
    userprofileUpdate: (state, action: PayloadAction<IProfile>) => {
      state.working = true;
      state.error = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
    },
    userprofileUpdateAvatar: (state, action: PayloadAction<File>) => {
      state.working = true;
      state.error = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
    },
    userprofileUpdateKeyVisual: (state, action: PayloadAction<File>) => {
      state.working = true;
      state.error = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
    },
    userprofileRestore: state => {
      state.working = false;
      state.error = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
    },
    userprofileUpdateFailed: state => {
      state.working = false;
      state.error = true;
      state.errorMessage = undefined;
      state.errorCode = undefined;
    },
    userprofileRestoreSuccess: state => {
      state.working = false;
      state.error = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
    },
    userprofileUpdatePassword: (state, action: PayloadAction<{ userId: string; oldPassword: string; newPassword: string }>) => {
      state.working = true;
      state.error = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
    },
    userprofileUpdateAvatarFailed: (state, action: PayloadAction<IProfile>) => {
      state.item = action.payload as any;
      state.working = false;
      state.error = true;
      state.errorMessage = 'Update Avatar failed!';
      state.errorCode = 500;
    },
    userprofileUpdateKeyVisualFailed: (state, action: PayloadAction<IProfile>) => {
      state.item = action.payload as any;
      state.working = false;
      state.error = true;
      state.errorMessage = 'Update Keyvisual failed!';
      state.errorCode = 500;
    },
    userprofileUpdatePasswordSuccess: state => {
      state.working = false;
      state.error = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
      state.updatePassSuccess = true;
      state.updatePassError = false;
    },
    userprofileUpdatePasswordFailed: state => {
      state.working = false;
      state.error = true;
      state.errorMessage = 'Update Pass failed!';
      state.errorCode = 500;
      state.updatePassSuccess = false;
      state.updatePassError = true;
    },
    userprofileUpdatePasswordReset: state => {
      state.working = false;
      state.error = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
      state.updatePassSuccess = false;
      state.updatePassError = false;
    },
    userprofileTermination: state => {
      state.working = true;
      state.error = false;
      state.errorMessage = undefined;
      state.errorCode = undefined;
    },
    userprofileTerminationFailed: state => {
      state.working = false;
      state.error = true;
      state.errorMessage = 'Update Termination failed!';
      state.errorCode = 500;
    },
    updateConferencing: (state, action: PayloadAction<{ message: IMessageModel | null }>) => {
      state.adhocConferencing = action.payload.message;
    },
    cancelMeetingRequest: (state, action: PayloadAction<{ id: string; onSuccess?: () => void; customMessage?: string }>) => {
      state.working = true;
    },
    cancelMeetingSuccess: (state, action: PayloadAction<{ customMessage?: string }>) => {
      if (!state.item) return;
      state.item.content.cancelled = true;
      state.item.content.meetingCancelledMessage = action.payload?.customMessage;
      state.working = false;
    },
    cancelMeetingFailed: state => {
      state.working = false;
    },
    fetchEmployeesRequest: (state, action: PayloadAction<{ id: string }>) => {
      state.children.working = true;
    },
    fetchEmployeesSuccess: (state, action: PayloadAction<{ result: Array<any> }>) => {
      state.children.working = false;
      state.children.items = action.payload.result;
    },
    fetchEmployeesFailed: (state, action: PayloadAction<{ message: string }>) => {
      state.children.working = false;
      state.children.items = [];
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchMessageByIdSuccess, (state, action) => {
      if (action.payload.new && state.item && action.payload.message.parent === state.item._id && !action.payload.message.threadRoot) {
        if (state.item.content.commentCount !== undefined) {
          state.item.content.commentCount++;
        } else {
          state.item.content.commentCount = 1;
        }
      }
    });
  },
});

export const {
  fetchDetailRequest,
  fetchDetailSuccess,
  fetchDetailFailed,
  fetchLPDetailRequest,
  fetchRelatedEntriesRequest,
  fetchRelatedEntriesSuccess,
  fetchRelatedEntriesFailed,
  updateVotingRequest,
  updateVotingSuccess,
  updateVotingFailed,
  fetchMeetingsRequest,
  fetchMeetingsSuccess,
  fetchMeetingsFailed,
  fetchMeetingParticipantsFailed,
  fetchMeetingParticipantsRequest,
  fetchMeetingParticipantsSuccess,
  updateCommitmentRequest,
  updateCommitmentSuccess,
  updateCommitmentFailed,
  updateDetailRequest,
  updateDetailSuccess,
  updateDetailFailed,
  setDetailUpdate,
  updateFormData,
  updateFormDataAttribute,
  resetFormData,
  userprofileUpdate,
  userprofileUpdateAvatar,
  userprofileUpdateAvatarFailed,
  userprofileUpdateKeyVisual,
  userprofileUpdateKeyVisualFailed,
  userprofileUpdatePassword,
  userprofileUpdatePasswordFailed,
  userprofileUpdatePasswordSuccess,
  userprofileUpdatePasswordReset,
  userprofileTermination,
  userprofileTerminationFailed,
  userprofileRestore,
  userprofileRestoreSuccess,
  userprofileUpdateFailed,
  updateConferencing,
  cancelMeetingRequest,
  cancelMeetingSuccess,
  cancelMeetingFailed,
  fetchEmployeesRequest,
  fetchEmployeesSuccess,
  fetchEmployeesFailed
} = detail.actions;
export default detail.reducer;

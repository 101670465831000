import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OneToOneStatus } from '../../../model/communication/Communication';
import { IListItem } from '../../../model/list/ListItem';
import { IExpertForm } from '../../../components/user-profile/expertRegistrationComponent';
import { IGroupSelect } from '../../../model/profile/groupselect';

export interface IRelatedItem {
  _id: string;
  _score: number;
  categories: Array<{ categoryId: string }>;
  createdAt: string;
  modifiedAt: string;
  publishState: string;
  type: string;
  content: any;
}

export interface IUserActivities {
  created: Array<IRelatedItem>;
  member: Array<IRelatedItem>;
}

export interface IProfileRelatedItems {
  communication?: OneToOneStatus | null;
  autoSuggest?: any;
  relatedItems?: Array<any> | any;
  pendingTokens?: Array<any>;
  pendingTokensWorking?: boolean;
  expertRegisteredMessage?: string;
  groupList?: Array<IGroupSelect>;
}

const userProfile = createSlice({
  name: 'userProfile',
  initialState: {} as IProfileRelatedItems,
  reducers: {
    fetchPlayerAutoSuggest: (state, action: PayloadAction<string>) => {
      if (!!state.autoSuggest && state.autoSuggest.length === 0) {
        state.autoSuggest = null;
      }

      return state;
    },
    fetchPlayerAutoSuggestSuccess: (state, action: PayloadAction<any>) => {
      return { ...state, autoSuggest: action.payload };
    },
    fetchPlayerAutoSuggestFailed: state => {
      return { ...state, autoSuggest: undefined };
    },
    fetchRelatedItems: (state, action: PayloadAction<{ userId: string }>) => {
      return { ...state, relatedItems: undefined };
    },
    fetchRelatedItemsSuccess: (state, action: PayloadAction<{ created: Array<IListItem>; member: Array<IListItem> }>) => {
      return { ...state, relatedItems: action.payload };
    },
    fetchRelatedItemsFailed: state => {
      return { ...state, relatedItems: [] };
    },
    fetchActorProfile: (state, action: PayloadAction<{ alias: string }>) => {
      return { ...state, relatedItems: undefined };
    },
    fetchActorProfileSuccess: (state, action: PayloadAction<any>) => {
      return { ...state, relatedItems: action.payload };
    },
    fetchActorProfileFailed: state => {
      return { ...state, relatedItems: undefined };
    },
    userprofileCommunicationRequest: (state, action: PayloadAction<{ id: string }>) => {
      state.communication = undefined;
    },
    userprofileCommunicationSuccess: (state, action: PayloadAction<{ data: OneToOneStatus }>) => {
      state.communication = action.payload.data;
    },
    userprofileCommunicationFailed: (state, action: PayloadAction<{ message?: string }>) => {
      state.communication = null;
    },
    userprofileCommunicationCreateRequest: (state, action: PayloadAction<{ userId: string; message: string; detail?: string }>) => {},
    userprofileCommunicationCreateSuccess: (state, action: PayloadAction<{}>) => {},
    userprofileCommunicationCreateFailed: (state, action: PayloadAction<{ message?: string }>) => {},

    fetchUserprofileAdminPendingTokens: (state, action: PayloadAction<{}>) => {
      return { ...state, pendingTokensWorking: true };
    },
    fetchUserprofileAdminPendingTokensSuccess: (state, action: PayloadAction<{ pendingTokens: Array<IListItem> }>) => {
      return { ...state, pendingTokensWorking: false, pendingTokens: action.payload.pendingTokens };
    },
    fetchUserprofileAdminPendingTokensFailed: (state, action: PayloadAction<{}>) => {
      return { ...state, pendingTokensWorking: false, pendingTokens: [] };
    },

    registerAdmin: (state, action: PayloadAction<{ email: string }>) => {},
    registerExpertAdmin: (state, action: PayloadAction<{ request: IExpertForm }>) => {},
    registerExpertAdminResponse: (state, action: PayloadAction<{ response: string }>) => {
      return { ...state, expertRegisteredMessage: action.payload.response };
    },

    getAllGroupsRequest: (state, action: PayloadAction<{}>) => {
      return { ...state, groupList: [] };
    },
    getAllGroupsSuccess: (state, action: PayloadAction<{ groupList: Array<IGroupSelect> }>) => {
      return { ...state, groupList: action.payload.groupList };
    },
    getAllGroupsFailed: (state, action: PayloadAction<{ message?: string }>) => {
      return { ...state, groupList: [] };
    },

    clearRequestToken: (state, action: PayloadAction<{ tokenId: string }>) => {},
    resetAndResendNotifications: (state, action: PayloadAction<{ email: string }>) => {},
  },
});

export const {
  fetchPlayerAutoSuggest,
  fetchPlayerAutoSuggestSuccess,
  fetchPlayerAutoSuggestFailed,
  fetchRelatedItems,
  fetchRelatedItemsSuccess,
  fetchRelatedItemsFailed,
  fetchActorProfile,
  fetchActorProfileSuccess,
  fetchActorProfileFailed,
  userprofileCommunicationRequest,
  userprofileCommunicationSuccess,
  userprofileCommunicationFailed,
  userprofileCommunicationCreateRequest,
  userprofileCommunicationCreateSuccess,
  userprofileCommunicationCreateFailed,
  fetchUserprofileAdminPendingTokens,
  fetchUserprofileAdminPendingTokensSuccess,
  fetchUserprofileAdminPendingTokensFailed,
  registerAdmin,
  registerExpertAdmin,
  registerExpertAdminResponse,
  clearRequestToken,
  getAllGroupsRequest,
  getAllGroupsSuccess,
  getAllGroupsFailed,
  resetAndResendNotifications,
} = userProfile.actions;
export default userProfile.reducer;

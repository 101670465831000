import { createReducer } from '@reduxjs/toolkit';
import { ISearchResult } from '../../../model/search/SearchResult';
import {
  fetchFirstPageSearchEntriesRequestAction,
  fetchFirstPageSearchEntriesErrorAction,
  fetchFirstPageSearchEntriesSuccessAction,
  fetchNextPageSearchEntriesRequestAction,
  fetchNextPageSearchEntriesErrorAction,
  fetchNextPageSearchEntriesSuccessAction,
  clearSearchEntriesAction,
  fetchPlayersByParentIdRequestAction,
  fetchPlayersByParentIdErrorAction,
  fetchPlayersByParentIdSuccessAction,
  fetchPlayersByParentIdResetAction,
  setSearchWorkingAction,
} from '../../actions/search';

const DEFAULT_PAGE_SIZE = 20;

export interface ISearchState {
  first?: boolean;
  filterChanged?: boolean;
  working?: boolean;
  error?: string;
  result?: ISearchResult | null;
  querySkip: number;
  queryPageSize: number;
  moreDataAvailable?: boolean;
  playerResult: any | null;
  playerSearchWorking: boolean;
  playerError: string;
}

const initialState: ISearchState = {
  first: true,
  filterChanged: false,
  working: false,
  error: '',
  result: null,
  querySkip: 0,
  queryPageSize: DEFAULT_PAGE_SIZE,
  moreDataAvailable: true,
  playerResult: null,
  playerSearchWorking: false,
  playerError: '',
};

const search = createReducer(initialState, builder =>
  builder
    .addCase(fetchFirstPageSearchEntriesRequestAction, (state, action) => {
      return {
        ...state,
        filterChanged: false,
        working: true,
        querySkip: 0,
        queryPageSize: DEFAULT_PAGE_SIZE,
        moreDataAvailable: false,
      };
    })
    .addCase(fetchFirstPageSearchEntriesErrorAction, (state, action) => {
      return {
        ...state,
        filterChanged: false,
        working: false,
        result: null,
        error: action.payload.message,
        querySkip: 0,
        queryPageSize: DEFAULT_PAGE_SIZE,
        moreDataAvailable: false,
      };
    })
    .addCase(fetchFirstPageSearchEntriesSuccessAction, (state, action) => {
      const result = action.payload.result;

      const newEntries = Array.from(result?.hits?.hits || []);
      const moreDataAvailable = state.queryPageSize > 0 && newEntries.length > state.queryPageSize;
      if (moreDataAvailable) newEntries.splice(state.queryPageSize);

      return {
        ...state,
        first: false,
        filterChanged: false,
        working: false,
        result: {
          aggregations: result.aggregations,
          aggregations_types: result.aggregations_types,
          aggregations_fields: result.aggregations_fields,
          aggregations_destinations: result.aggregations_destinations,
          count: result.count,
          hits: {
            total: result.hits.total,
            hits: newEntries,
          },
          user_avatars: result.user_avatars,
          list_users: result.list_users,
        },
        querySkip: newEntries.length || 0,
        moreDataAvailable,
      };
    })
    .addCase(fetchNextPageSearchEntriesRequestAction, (state, action) => {
      return {
        ...state,
        filterChanged: false,
        working: true,
        moreDataAvailable: false,
      };
    })
    .addCase(fetchNextPageSearchEntriesErrorAction, (state, action) => {
      return {
        ...state,
        filterChanged: false,
        working: false,
        error: action.payload.message,
        querySkip: 0,
        queryPageSize: DEFAULT_PAGE_SIZE,
        moreDataAvailable: false,
      };
    })
    .addCase(fetchNextPageSearchEntriesSuccessAction, (state, action) => {
      const result = action.payload.result;
      const newEntries = Array.from(result?.hits?.hits || []);
      const moreDataAvailable = state.queryPageSize > 0 && newEntries.length > state.queryPageSize;
      if (moreDataAvailable) newEntries.splice(state.queryPageSize);

      return {
        ...state,
        first: false,
        working: false,
        result: {
          aggregations: result.aggregations,
          aggregations_types: result.aggregations_types,
          aggregations_fields: result.aggregations_fields,
          aggregations_destinations: result.aggregations_destinations,
          count: result.count,
          hits: {
            total: result.hits.total,
            hits: [...((state?.result as any)?.hits?.hits || []), ...newEntries],
          },
          user_avatars: result.user_avatars,
          list_users: result.list_users,
        },
        querySkip: state.querySkip + newEntries.length,
        moreDataAvailable,
      };
    })
    .addCase(clearSearchEntriesAction, (state, action) => {
      return { ...state, first: true, working: false, result: null };
    })
    .addCase(fetchPlayersByParentIdRequestAction, (state, action) => {
      return { ...state, playerResult: null, playerError: '', playerSearchWorking: true };
    })
    .addCase(fetchPlayersByParentIdErrorAction, (state, action) => {
      return { ...state, playerResult: null, playerError: action.payload.message, playerSearchWorking: false };
    })
    .addCase(fetchPlayersByParentIdSuccessAction, (state, action) => {
      return { ...state, playerResult: action.payload.result, playerError: '', playerSearchWorking: true };
    })
    .addCase(fetchPlayersByParentIdResetAction, (state, action) => {
      return { ...state, playerResult: null, playerError: '', playerSearchWorking: false };
    })
    .addCase(setSearchWorkingAction, (state, action) => {
      return { ...state, working: action.payload.working };
    })
);
export default search;

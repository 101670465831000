import { all } from 'redux-saga/effects';

import { categories } from './categories';
import { communication } from './communication';
import { data } from './data';
import { detail } from './detail';
import { watcher as filter } from './filter';
import { flow } from './flow';
import { watcher as mail } from './mail';
import { foundation } from './foundation';
import { watcher as search } from './search';
import { userProfile } from './userprofile';
import { notifications } from './notifications';
import { news } from './news';
import { lists } from './lists';

export default function* sagas() {
  yield all([categories(), communication(), data(), detail(), filter(), flow(), foundation(), lists(), mail(), notifications(), news(), search(), userProfile()]);
}

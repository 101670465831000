import { callGet, callPost, callPostPlain } from '@curry-group/data-addons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { put, select, takeLatest } from 'redux-saga/effects';
import { GetCookie, getDetailRoute, GetFormData, resolveTimestampDateOnly } from '../../../helper';
import { ThingTypes } from '../../../model/ryve/Thing';
import { userCredLoginRequestAction } from '../../actions/auth';

import {
  // checkUserExistsErrorAction,
  // checkUserExistsRequestAction,
  // checkUserExistsSuccessAction,
  fetchEmployerConfigsErrorAction,
  fetchEmployerConfigsRequestAction,
  fetchEmployerConfigsSuccessAction,
  finishFlowErrorAction,
  finishFlowRequestAction,
  finishFlowSuccessAction,
  userProfileRequestNotValidAction,
  userProfileRequestValidAction,
} from '../../actions/flow';
import { setUpdateTermsAction } from '../../actions/foundation';
import { api } from '../../api';
import { createMessageRequest } from '../../reducer/communication';

export function useFlow() {
  return useSelector(state => state.flow);
}

export function useFlowRegister(pathname: string, search?: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    const load = async () => {
      const params = new URLSearchParams(search);
      const token = params.get('token');
      const response = await fetch(api.query(api.auth.validateToken, { token }), { method: 'POST' });
      const validToken = await response.json();
      if (validToken && validToken.length > 0) {
        dispatch(userProfileRequestValidAction({ tokenInfo: validToken[0] }));
      } else {
        dispatch(userProfileRequestNotValidAction({}));
      }
    };
    if (pathname && pathname.startsWith('/register')) {
      load().catch(() => {
        dispatch(userProfileRequestNotValidAction({}));
      });
    }
  }, [dispatch, pathname, search]);
}

export function useFlowLostPass(pathname: string, search?: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (pathname && pathname.startsWith('/resetpassword')) {
      const params = new URLSearchParams(search);
      const token = params.get('token');
      dispatch(userProfileRequestValidAction({ tokenInfo: { _id: token } }));
    }
  }, [dispatch, pathname, search]);
}

export function useFlowOptout(pathname: string, search?: string) {
  const dispatch = useDispatch();
  useEffect(() => {
    const load = async () => {
      const params = new URLSearchParams(search);
      const token = params.get('token');
      const response = await fetch(api.query(api.auth.deactivateMails, { token }), {
        method: 'POST',
        body: JSON.stringify({
          all: true,
          chat: false,
          groupChat: false,
          groupMeeting: false,
          forum: false,
          magazine: false,
        }),
        headers: { 'content-type': 'application/json' },
      });
      const validToken = await response.json();
      if (validToken && validToken.length > 0) {
        dispatch(
          userProfileRequestValidAction({
            tokenInfo: validToken[0],
            messagingPermissions: {
              all: true,
              chat: false,
              groupChat: false,
              groupMeeting: false,
              forum: false,
              magazine: false,
            },
          })
        );
      } else {
        dispatch(userProfileRequestNotValidAction({ alias: 'optout' }));
      }
    };
    if (pathname && pathname.startsWith('/optout')) {
      load().catch(() => {
        dispatch(userProfileRequestNotValidAction({ alias: 'optout' }));
      });
    }
  }, [dispatch, pathname, search]);
}

export function* flow() {
  yield takeLatest(finishFlowRequestAction.type, finishFlowWorker);
  yield takeLatest(fetchEmployerConfigsRequestAction.type, fetchPlayerTypeConfigWorker);
}

function* finishFlowWorker(action: ReturnType<typeof finishFlowRequestAction>) {
  try {
    const flowState = yield select(state => state.flow);
    const detail = yield select(state => state.detail?.item);
    const config = yield select(state => state.foundation.appconfig);
    let finishUrl = '';
    let currentType = flowState.currentType;
    if (!currentType) {
      currentType = flowState.currentAlias;
    }
    const xsrfToken = GetCookie('XSRF-TOKEN');
    const addHeader = xsrfToken ? { headers: { 'X-XSRF-TOKEN': xsrfToken } } : undefined;
    switch (currentType) {
      case 'idea': {
        const newIdeaForm = GetFormData('NEW', flowState.flowData?.itemData, flowState.flowData?.categories, flowState.flowData?.attachments, flowState.flowAnonymous);
        const createdIdea = yield callPostPlain(api.flow.create.idea, newIdeaForm, addHeader);
        finishUrl = '/info/forum/idea/' + createdIdea.seo.alias;
        break;
      }
      case 'qna': {
        const newQnaForm = GetFormData('NEW', flowState.flowData?.itemData, flowState.flowData?.categories, flowState.flowData?.attachments, flowState.flowAnonymous);
        const createdQnA = yield callPostPlain(api.flow.create.qna, newQnaForm, addHeader);
        finishUrl = '/info/forum/qna/' + createdQnA.seo.alias;
        break;
      }
      case 'requisition': {
        const newRequisitionForm = GetFormData('NEW', flowState.flowData?.itemData, flowState.flowData?.categories, flowState.flowData?.attachments, flowState.flowAnonymous);
        const createdRequisition = yield callPostPlain(api.flow.create.requisition, newRequisitionForm, addHeader);
        finishUrl = '/info/forum/requisition/' + createdRequisition.seo.alias;
        break;
      }
      case 'workgroup': {
        const workgroup = { ...flowState.flowData?.itemData };
        workgroup.participants = flowState.flowData?.selectedUsers;
        workgroup.public = flowState.flowData?.groupType === 'open_listed';
        workgroup.listed = flowState.flowData?.groupType === 'open_listed' || flowState.flowData?.groupType === 'closed_listed';
        const avatar = flowState.flowData?.avatar || [new File([], 'AVATAR_PLACEHOLDER')];
        const keyvisual = flowState.flowData?.keyvisual || [new File([], 'KEYVISUAL_PLACEHOLDER')];
        const newWorkgroupForm = GetFormData(
          'NEW',
          workgroup,
          flowState.flowData?.categories,
          [...avatar, ...keyvisual, ...(flowState.flowData?.attachments || [])],
          flowState.flowAnonymous
        );
        const createdWorkgroup = yield callPostPlain(api.flow.create.workgroup, newWorkgroupForm, addHeader);
        finishUrl = '/coop/groups-projects/groups/' + createdWorkgroup.seo.alias;
        break;
      }
      case 'project': {
        const project = { ...flowState.flowData?.itemData };
        project.participants = flowState.flowData?.selectedUsers;
        project.public = flowState.flowData?.groupType === 'open_listed';
        project.listed = flowState.flowData?.groupType === 'open_listed' || flowState.flowData?.groupType === 'closed_listed';
        const avatar = flowState.flowData?.avatar || [new File([], 'AVATAR_PLACEHOLDER')];
        const keyvisual = flowState.flowData?.keyvisual || [new File([], 'KEYVISUAL_PLACEHOLDER')];
        const newProjectForm = GetFormData(
          'NEW',
          project,
          flowState.flowData?.categories,
          [...avatar, ...keyvisual, ...(flowState.flowData?.attachments || [])],
          flowState.flowAnonymous
        );
        const createdProject = yield callPostPlain(api.flow.create.project, newProjectForm, addHeader);
        finishUrl = '/coop/groups-projects/projects/' + createdProject.seo.alias;
        break;
      }
      case 'chat': {
        const chat = { ...flowState.flowData?.itemData };
        chat.partner = flowState.flowData?.selectedUsers && flowState.flowData?.selectedUsers.length === 1 ? flowState.flowData?.selectedUsers[0] : null;
        const newChatForm = GetFormData('NEW', chat, [], [], false);
        const createdChat = yield callPostPlain(api.v2.communication.onetoone, newChatForm, addHeader);
        finishUrl = '/coop/chat/chat/' + createdChat.seo.alias;
        break;
      }
      case 'register': {
        const userData = {
          profile: flowState.flowData?.itemData,
          password: flowState.flowData?.password,
          actorRequest: flowState.flowData?.actorRequest,
          actorRequestCategories: flowState.flowData?.employerType,
        };
        const token = flowState.flowData?.token;
        const createdUser = yield callPost(api.query(api.auth.registration, { token }), userData, addHeader);
        if (createdUser) {
          yield put(userCredLoginRequestAction({ username: userData?.profile?.email, password: flowState.flowData?.password, persist: false }));
        }
        finishUrl = '/start';
        break;
      }
      case 'optin': {
        const email = flowState.flowData?.itemData?.email;
        yield callPost(api.query(api.auth.register, { email }), undefined, addHeader);
        finishUrl = '/';
        break;
      }
      case 'lostpassword': {
        const email = flowState.flowData?.itemData?.email;
        yield callPost(api.auth.lostPassword, { username: email }, addHeader);
        finishUrl = '/';
        break;
      }
      case 'resetpassword': {
        yield callPost(api.query(api.auth.finishLostPassword, { token: flowState.flowData?.token }), { password: flowState.flowData?.password }, addHeader);
        finishUrl = '/';
        break;
      }
      case 'activityStream': {
        const sharedElement = {
          typeId: '',
          elementId: '',
        };
        if (flowState.flowData?.itemData?.sharedItem?.profileId) {
          sharedElement.typeId = ThingTypes.Profile;
          sharedElement.elementId = flowState.flowData.itemData.sharedItem.profileId;
        } else if (flowState.flowData?.itemData?.sharedItem?.type === ThingTypes.News) {
          sharedElement.typeId = ThingTypes.News;
          sharedElement.elementId = flowState.flowData?.itemData?.sharedItem?.__original?._id;
        } else {
          sharedElement.typeId = flowState.flowData?.itemData?.sharedItem?.type;
          sharedElement.elementId = flowState.flowData?.itemData?.sharedItem?._id;
        }
        const message = flowState.flowData?.itemData?.message || '';
        yield callPost(api.params(api.flow.create.activitystream, sharedElement), message, addHeader);
        finishUrl = flowState.flowData.location.pathname + flowState.flowData.location.search;
        break;
      }
      case 'sharetochat': {
        let attachByLink = flowState.flowData?.itemData?.sharedItem?.type === ThingTypes.News;
        let universalElement = attachByLink
          ? undefined
          : !!flowState.flowData?.itemData?.sharedItem?.profileId
          ? { typeId: ThingTypes.Profile, type: 'thing', elementId: flowState.flowData?.itemData?.sharedItem?.profileId }
          : { typeId: flowState.flowData?.itemData?.sharedItem?.type, type: 'thing', elementId: flowState.flowData?.itemData?.sharedItem?._id };
        yield put(
          createMessageRequest({
            id: '42',
            communicationId: flowState.flowData?.communication?._id,
            content: attachByLink
              ? flowState.flowData?.itemData?.sharedItem?.type === ThingTypes.News
                ? (flowState.flowData?.itemData?.message + '\n\n' || '') +
                  resolveTimestampDateOnly(flowState.flowData?.itemData?.sharedItem?.content?.pubDate) +
                  '\n' +
                  flowState.flowData?.itemData?.sharedItem?.content?.title +
                  '\n' +
                  flowState.flowData?.itemData?.sharedItem?.content?.url +
                  '\n' +
                  'Quelle: ' +
                  flowState.flowData?.itemData?.sharedItem?.content?.sourcePreferredLabel
                : (flowState.flowData?.itemData?.message || '') + ' ' + window.location?.origin + flowState.flowData?.location?.pathname
              : flowState.flowData?.itemData?.message || '',
            attachedThing: attachByLink ? undefined : universalElement,
          })
        );
        finishUrl = getDetailRoute(flowState.flowData?.communication, config) || '/';
        break;
      }
      case 'createmeeting': {
        const meeting = { ...flowState.flowData?.itemData };
        if (meeting?.externalType === 'link') {
          if (meeting?.location) {
            meeting.location = undefined;
          }
        } else if (meeting?.externalType === 'location') {
          if (meeting?.externalLink) {
            meeting.externalLink = undefined;
            meeting.externalLinkAcceptedInstructions = undefined;
          }
        }
        meeting.participants = flowState.flowData?.selectedUsers;
        meeting.parentThing = [
          {
            type: 'thing',
            typeId: flowState.flowData?.parentType,
            elementId: flowState.flowData?.parentId,
          },
        ];
        const newMeetingForm = GetFormData('NEW', meeting, flowState.flowData?.categories, flowState.flowData?.attachments || [], false);
        const newMeeting = yield callPostPlain(api.flow.create.meeting, newMeetingForm, addHeader);
        const newMeetingRoute =
          getDetailRoute(
            {
              typeId: newMeeting.type,
              alias: newMeeting.seo.alias,
              parentItem: {
                typeId: detail.type,
                alias: detail.seo.alias,
              },
            },
            config
          ) || '/';
        finishUrl = newMeetingRoute;
        break;
      }
      case 'flowtermsrules': {
        const profileData = {
          profile: flowState.flowData?.itemData,
          password: flowState.flowData?.password,
        };
        const profile = yield callPost(api.auth.termsAccepted, profileData, addHeader);
        if (profile) {
          yield put(setUpdateTermsAction());
          const redirectLinkAfterLogin = localStorage.getItem('mto_redirect_after_login');
          if (!!redirectLinkAfterLogin) {
            finishUrl = redirectLinkAfterLogin;
            localStorage.removeItem('mto_redirect_after_login');
            yield put(finishFlowSuccessAction({ finishUrl }));
            action.payload.history.push(redirectLinkAfterLogin);
          } else {
            finishUrl = '/start';
          }
        } else {
          finishUrl = '/termsrules';
        }
        break;
      }
      case 'optout': {
        yield callPost(api.query(api.auth.upcmpanon, { token: flowState?.flowData?.token }), flowState?.flowData?.itemData?.messagingPermissions || {});
        finishUrl = '/';
        break;
      }
    }
    if (finishUrl) {
      yield put(finishFlowSuccessAction({ finishUrl }));
    } else {
      yield put(finishFlowErrorAction({ message: 'NO_URL' }));
    }
  } catch (e: any) {
    yield put(finishFlowErrorAction(e.message));
  }
}

export function useEmployerConfigs() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchEmployerConfigsRequestAction({}));
  }, [dispatch]);
}

function* fetchPlayerTypeConfigWorker(action: ReturnType<typeof fetchEmployerConfigsRequestAction>) {
  try {
    const employerConfigs = yield select(state => state.flow?.employerConfigs);
    if (!employerConfigs) {
      const result = yield callGet(api.flow.getactortypeconfigs);
      yield put(fetchEmployerConfigsSuccessAction({ result }));
    }
  } catch (e: any) {
    yield put(fetchEmployerConfigsErrorAction({ message: e.message }));
  }
}
